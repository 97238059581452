import { DefaultStringValue, EActionState, IFormValue, IListValue } from 'models';
import { dateService } from 'services';

export interface IZipCodeCity {
	city: string;
	county: string;
	isPrimary: boolean;
	state: string;
	zipCode: string;
}

export interface ISelfGenFormState {
	regionInfo: {
		businessUnit: string;
		serviceRegion: string;
		products: string[];
		serviced: boolean;
		validZip: boolean;
		cities: IZipCodeCity[];
	};
	wizard: {
		step: 1 | 2 | 3 | 4 | 5;
		previousEnabled: boolean;
		nextEnabled: boolean;
	};
	activity: {
		step1: {
			nextEnabled: boolean;
			next: EActionState;
		};
		step2: {
			nextEnabled: boolean;
			next: EActionState;
		};
		step3: {
			nextEnabled: boolean;
			next: EActionState;
		};
		step4: {
			submitEnabled: boolean;
			submit: EActionState;
		};
		loadRegionEnabled: boolean;
		loadRegion: EActionState;
	};
	formData: {
		firstName: IFormValue<string>;
		lastName: IFormValue<string>;
		businessName: IFormValue<string>;
		mainPhone: IFormValue<string>;
		alternatePhone: IFormValue<string>;
		addressLine1: IFormValue<string>;
		addressLine2: IFormValue<string>;
		city: IFormValue<string>;
		state: IFormValue<string>;
		zipCode: IFormValue<string>;
		emailAddress: IFormValue<string>;
		pre1978: IFormValue<string>;
		buildingType: IFormValue<string>;
		businessUnit: IFormValue<string>;
		rentOwn: IFormValue<string>;
		date: IFormValue<string>;
		time: IFormValue<string>;
		products: Array<{ product: string; checked: boolean }>;
		areas: Array<{ area: IListValue; checked: boolean }>;
		step1: {
			error: boolean;
		};
		step2: {
			error: boolean;
		};
		step3: {
			error: boolean;
		};
		dirty: boolean;
		error: boolean;
		promotionCode: string;
		referringAccountNumber: string;
	};
}

export const DefaultSelfGenFormState: ISelfGenFormState = {
	regionInfo: {
		businessUnit: '',
		serviceRegion: '',
		products: [],
		serviced: false,
		validZip: false,
		cities: []
	},
	wizard: {
		step: 1,
		nextEnabled: true,
		previousEnabled: true
	},
	activity: {
		step1: {
			nextEnabled: false,
			next: EActionState.PENDING
		},
		step2: {
			nextEnabled: false,
			next: EActionState.PENDING
		},
		step3: {
			nextEnabled: false,
			next: EActionState.PENDING
		},
		step4: {
			submitEnabled: false,
			submit: EActionState.PENDING
		},
		loadRegionEnabled: false,
		loadRegion: EActionState.PENDING
	},
	formData: {
		firstName: DefaultStringValue,
		lastName: DefaultStringValue,
		businessName: DefaultStringValue,
		mainPhone: DefaultStringValue,
		alternatePhone: DefaultStringValue,
		addressLine1: DefaultStringValue,
		addressLine2: DefaultStringValue,
		city: DefaultStringValue,
		state: DefaultStringValue,
		zipCode: DefaultStringValue,
		emailAddress: DefaultStringValue,
		pre1978: DefaultStringValue,
		buildingType: DefaultStringValue,
		businessUnit: DefaultStringValue,
		rentOwn: DefaultStringValue,
		date: {
			...DefaultStringValue,
			value: dateService.getToday(),
			newValue: dateService.getToday()
		},
		time: DefaultStringValue,
		products: [],
		areas: [],
		step1: {
			error: false
		},
		step2: {
			error: false
		},
		step3: {
			error: false
		},
		dirty: false,
		error: false,
		promotionCode: '',
		referringAccountNumber: ''
	}
};
