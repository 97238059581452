import { Alert } from '@mui/material';
import { IAppointment } from 'models';
import { appointmentService } from 'services';

export const PendingAcceptBanner = (props: { appointment: IAppointment }) => {
	const { appointment } = props;
	const isAccepted = appointmentService.isAccepted(appointment.mobileStatus);
	if (!isAccepted) {
		return <Alert severity='info'>Pending Accept</Alert>;
	}
	return null;
};
