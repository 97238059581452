import { DefaultSelfGenFormState, EActionState, IListValue, ISelfGenFormState } from 'models';

export interface IState {
	init: EActionState;
	company: string;
	buildingTypes: IListValue[];
	timeWindows: string[];
	rooms: IListValue[];
	pre1978Options: string[];
	rentOwnOptions: string[];
	form: ISelfGenFormState;
}

export const initialState: IState = {
	init: EActionState.PENDING,
	company: '',
	buildingTypes: [],
	timeWindows: [],
	rooms: [],
	pre1978Options: ['Yes', 'No', 'Unknown'],
	rentOwnOptions: ['Rent', 'Own'],
	form: DefaultSelfGenFormState
};
