import { AxiosResponse } from 'axios';
import { post } from '../axiosprovider';

const PostReferralProspect = async (request: IReferralProspectRequest): Promise<AxiosResponse> => {
	return await post('/api/referralprospect', request);
};

export const referralProspectController = {
	PostReferralProspect
};

export interface IReferralProspectRequest {
	company: string;
	emailAddress: string;
	firstName: string;
	lastName: string;
	location: string;
	notes: string;
	phone: string;
	referringAccountId: string;
	referringOptyNum: string;
	salesRepId: string;
}
