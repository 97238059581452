import { IException } from 'models';
import { Action } from 'redux';
import { actionCreator } from 'store/messaging';
import { v4 } from 'uuid';
import { EMessageType } from './const';

export const messageFactory = {
	apiErrorMessage(error: IException) {
		return actionCreator.CREATE_MESSAGE({
			id: v4(),
			message: error.message,
			title: 'Server Exception Occurred',
			type: EMessageType.ERROR,
			data: error.data
		});
	},
	errorMessage(error: IException) {
		return actionCreator.CREATE_MESSAGE({
			id: v4(),
			message: error.message,
			title: 'Exception Occured',
			type: EMessageType.ERROR,
			data: error.data
		});
	},
	message(type: EMessageType, title: string, message: string, largeText?: boolean) {
		return actionCreator.CREATE_MESSAGE({
			id: v4(),
			message,
			title,
			type,
			data: null,
			largeText: largeText
		});
	},
	dialog(title: string, message: string, okAction: Action, cancelAction: Action | null, allowCancel: boolean) {
		return actionCreator.CREATE_DIALOG({
			id: v4(),
			message,
			title,
			okAction,
			cancelAction,
			allowCancel
		});
	},
	progress(title: string) {
		return actionCreator.CREATE_PROGRESS({
			id: v4(),
			title,
			value: 0
		});
	},
	dismissProgress() {
		return actionCreator.DISMISS_PROGRESS();
	}
};
