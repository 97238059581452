export interface IPACSValue {
	key: string;
	value: string;
}

export interface IPACSSchedule {
	dayOfWeek: 0 | 1 | 2 | 3 | 4 | 5 | 6;
	startTime: string;
	endTime: string;
}

export interface IPACSProvider {
	[PACSKeys.ArrivalNoticeEnabled]: boolean;
	[PACSKeys.ArrivalNoticeMaxCount]: number;
	[PACSKeys.ArrivalNoticeQuantities]: number[];
	[PACSKeys.ArrivalNoticeQuantityEnabled]: boolean;
	[PACSKeys.ArrivalNoticeTimeEnabled]: boolean;
	[PACSKeys.ArrivalNoticeTimeEndBuffer]: number;
	[PACSKeys.ArrivalNoticeTimes]: number;
	[PACSKeys.ArrivalNoticeTimeStartBuffer]: number;
	[PACSKeys.CanAcceptFutureAppointment]: boolean;
	[PACSKeys.EnableSelfGenSalesAppointments]: boolean;
	[PACSKeys.ExcludeResolutionCodeForSold]: boolean;
	[PACSKeys.ReferralPromoCode]: string;
	[PACSKeys.ReferralProspectEnabled]: boolean;
	[PACSKeys.ReferralSelfGenEnabled]: boolean;
	[PACSKeys.RequireLeadCompletion]: boolean;
	[PACSKeys.ResolutionBusinessUnitExclusionList]: string[];
	[PACSKeys.ResolutionCodeExclusionSchedule]: IPACSSchedule[];
	[PACSKeys.ResolutionEnabled]: boolean;
	[PACSKeys.ResolutionMaskingExclusionSchedule]: IPACSSchedule[];
	[PACSKeys.ResolutionMaskingRange]: number;
	[PACSKeys.ResolutionSourceExclusionList]: string[];
	[PACSKeys.ResolutionStatusExclusionList]: string[];
	[PACSKeys.SelfGenMaxDaysOut]: number;
	[PACSKeys.ShowCustomerPhoneNumbers]: boolean;
}

export enum PACSKeys {
	ArrivalNoticeEnabled = 'ArrivalNoticeEnabled',
	ArrivalNoticeMaxCount = 'ArrivalNoticeMaxCount',
	ArrivalNoticeQuantities = 'ArrivalNoticeQuantities',
	ArrivalNoticeQuantityEnabled = 'ArrivalNoticeQuantityEnabled',
	ArrivalNoticeTimeEnabled = 'ArrivalNoticeTimeEnabled',
	ArrivalNoticeTimeEndBuffer = 'ArrivalNoticeTimeEndBuffer',
	ArrivalNoticeTimes = 'ArrivalNoticeTimes',
	ArrivalNoticeTimeStartBuffer = 'ArrivalNoticeTimeStartBuffer',
	CanAcceptFutureAppointment = 'CanAcceptFutureAppointment',
	EnableSelfGenSalesAppointments = 'EnableSelfGenSalesAppointments',
	ExcludeResolutionCodeForSold = 'ExcludeResolutionCodeForSold',
	ReferralPromoCode = 'ReferralPromoCode',
	ReferralProspectEnabled = 'ReferralProspectEnabled',
	ReferralSelfGenEnabled = 'ReferralSelfGenEnabled',
	RequireLeadCompletion = 'RequireLeadCompletion',
	ResolutionBusinessUnitExclusionList = 'ResolutionBusinessUnitExclusionList',
	ResolutionCodeExclusionSchedule = 'ResolutionCodeExclusionSchedule',
	ResolutionEnabled = 'ResolutionEnabled',
	ResolutionMaskingExclusionSchedule = 'ResolutionMaskingExclusionSchedule',
	ResolutionMaskingRange = 'ResolutionMaskingRange',
	ResolutionSourceExclusionList = 'ResolutionSourceExclusionList',
	ResolutionStatusExclusionList = 'ResolutionStatusExclusionList',
	SelfGenMaxDaysOut = 'SelfGenMaxDaysOut',
	ShowCustomerPhoneNumbers = 'ShowCustomerPhoneNumbers'
}

export const DefaultPACSProvider: IPACSProvider = {
	[PACSKeys.ArrivalNoticeEnabled]: true,
	[PACSKeys.ArrivalNoticeMaxCount]: 2,
	[PACSKeys.ArrivalNoticeQuantities]: [15, 30, 45, 60],
	[PACSKeys.ArrivalNoticeQuantityEnabled]: true,
	[PACSKeys.ArrivalNoticeTimeEnabled]: true,
	[PACSKeys.ArrivalNoticeTimeEndBuffer]: 0,
	[PACSKeys.ArrivalNoticeTimes]: 15,
	[PACSKeys.ArrivalNoticeTimeStartBuffer]: 0,
	[PACSKeys.CanAcceptFutureAppointment]: false,
	[PACSKeys.EnableSelfGenSalesAppointments]: true,
	[PACSKeys.ExcludeResolutionCodeForSold]: true,
	[PACSKeys.ReferralPromoCode]: 'REF350',
	[PACSKeys.ReferralProspectEnabled]: false,
	[PACSKeys.ReferralSelfGenEnabled]: false,
	[PACSKeys.RequireLeadCompletion]: false,
	[PACSKeys.ResolutionBusinessUnitExclusionList]: [],
	[PACSKeys.ResolutionCodeExclusionSchedule]: [],
	[PACSKeys.ResolutionEnabled]: false,
	[PACSKeys.ResolutionMaskingExclusionSchedule]: [],
	[PACSKeys.ResolutionMaskingRange]: 0,
	[PACSKeys.ResolutionSourceExclusionList]: [],
	[PACSKeys.ResolutionStatusExclusionList]: [],
	[PACSKeys.SelfGenMaxDaysOut]: 30,
	[PACSKeys.ShowCustomerPhoneNumbers]: true
};
