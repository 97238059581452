import { ERole } from 'auth';
import { LoaderBackdrop, Page } from 'components';
import { lazy, Suspense } from 'react';
import { Outlet, Route, Navigate } from 'react-router';

const SearchImport = lazy(() => import('./search/main'));

const Search = <Page title='Customer Search' component={SearchImport} allowedRoles={[ERole.ROLE_OSE]} />;

export const CustomersRoutes = (
	<Route path='customers' element={<Outlet />}>
		<Route path='search' element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Search}</Suspense>} />
		<Route index element={<Navigate to='/customers/search' />} />
	</Route>
);
