import { AuthenticationType, IAzureMapOptions } from 'react-azure-maps';

export interface IMapOptions {
	key: string;
	latitude: number;
	longitude: number;
}
const getMapOptions = (options: IMapOptions): IAzureMapOptions => ({
	authOptions: {
		authType: AuthenticationType.subscriptionKey,
		subscriptionKey: options.key
	},
	autoResize: true,
	showFeedbackLink: false,
	showTileBoundaries: false,
	showLogo: false,
	showBuildingModels: true,
	style: 'road',
	center: [options.longitude, options.latitude],
	zoom: 12
});

export const mapService = {
	getMapOptions
};
