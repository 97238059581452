import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EActionState, IAppointment } from 'models';
import { resetState } from 'store/common/slice';
import { initialState } from './state';
import { resolutionService } from 'services';

const slice = createSlice({
	name: '@@resolution',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		INIT_START: (state, action: PayloadAction<{ activityId: string }>) => {
			state.init = EActionState.RUNNING;
			state.open = true;
		},
		INIT_COMPLETED: (
			state,
			action: PayloadAction<{
				appointment: IAppointment;
			}>
		) => {
			state.init = EActionState.COMPLETED;
			state.appointment = action.payload.appointment;
			resolutionService.setEnabledState(state);
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
		},
		INIT_FLUSH: state => {
			resetState(state, initialState);
		},
		SET_CODE: (state, action: PayloadAction<string>) => {
			state.code.newValue = action.payload;
			resolutionService.setEnabledState(state);
		},
		RESOLVE_START: state => {
			state.resolve = EActionState.RUNNING;
			state.message = '';
		},
		RESOLVE_COMPLETED: (
			state,
			action: PayloadAction<{
				message: string;
			}>
		) => {
			state.resolve = EActionState.COMPLETED;
			state.message = action.payload.message;
		},
		RESOLVE_FAILED: state => {
			state.resolve = EActionState.PENDING;
			state.message = '';
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
