import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { isNullOrWhiteSpace } from 'coreutil';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store/application';
import { COMMANDS, IMessagePayload } from './versioncheckworker';

const worker: Worker = new Worker(new URL('./versioncheckworker.ts', import.meta.url));

export const VersionCheckManager = () => {
	const buildVersion = useSelector(selectors.getBuildVersion);
	const versionCheckInterval = useSelector(selectors.getVersionCheckInterval);
	const [serverVersion, setServerVersion] = useState<string | null>(null);
	const [forceReload, setForceReload] = useState(false);

	useEffect(() => {
		const message: IMessagePayload = {
			type: COMMANDS.START,
			versionCheckInterval,
			buildVersion
		};
		worker.postMessage(message);

		worker.onmessage = (e: { data: any }) => {
			const { data } = e;
			if (data.type === COMMANDS.MESSAGE && data.success) {
				const serverVersion = data.data;
				const forceReload = !isNullOrWhiteSpace(buildVersion) && buildVersion !== serverVersion;
				if (forceReload) {
					worker.postMessage({
						type: COMMANDS.STOP
					});
				}
				setServerVersion(serverVersion);
				setForceReload(forceReload);
			}
		};

		return () => {
			worker.postMessage({
				type: COMMANDS.STOP
			});
		};
	}, [buildVersion, versionCheckInterval]);

	const handleClick = () => {
		window.location.reload();
	};

	return (
		<Dialog open={forceReload}>
			<DialogTitle>Application update required. Click OK to update</DialogTitle>
			<DialogContent>
				<Typography variant='subtitle2' component='p'>
					Current Version: <Typography color='error.main'>{buildVersion}</Typography>
				</Typography>
				<Typography variant='subtitle2' component='p'>
					Server Version: <Typography color='success.main'>{serverVersion}</Typography>
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button color='primary' onClick={handleClick}>
					OK
				</Button>
			</DialogActions>
		</Dialog>
	);
};
