import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.appointments.details.init,
	getLoadAction: (state: IGlobalState) => state.appointments.details.load,
	getRefreshAction: (state: IGlobalState) => state.appointments.details.refresh,
	getRowId: (state: IGlobalState) => state.appointments.details.rowId,
	appointment: {
		getAppointment: (state: IGlobalState) => state.appointments.details.appointment.data,
		getLoadAction: (state: IGlobalState) => state.appointments.details.appointment.load,
		getRefreshAction: (state: IGlobalState) => state.appointments.details.appointment.refresh
	},
	quotes: {
		getQuotes: (state: IGlobalState) => state.appointments.details.quotes.data,
		getLoadAction: (state: IGlobalState) => state.appointments.details.quotes.load,
		getRefreshAction: (state: IGlobalState) => state.appointments.details.quotes.refresh
	},
	contract: {
		getContractExists: (state: IGlobalState) => !!state.appointments.details.contract.data,
		getContract: (state: IGlobalState) => state.appointments.details.contract.data,
		getLoadAction: (state: IGlobalState) => state.appointments.details.contract.load,
		getRefreshAction: (state: IGlobalState) => state.appointments.details.contract.refresh
	},
	notes: {
		getNotes: (state: IGlobalState) => state.appointments.details.notes.data,
		getLoadAction: (state: IGlobalState) => state.appointments.details.notes.load,
		getRefreshAction: (state: IGlobalState) => state.appointments.details.notes.refresh,
		dialog: {
			getOpen: (state: IGlobalState) => state.appointments.details.notes.dialog.open,
			getSaveAction: (state: IGlobalState) => state.appointments.details.notes.dialog.save,
			getSaveEnabled: (state: IGlobalState) => state.appointments.details.notes.dialog.saveEnabled,
			getNote: (state: IGlobalState) => state.appointments.details.notes.dialog.note
		}
	},
	changeOrders: {
		getChangeOrders: (state: IGlobalState) => state.appointments.details.changeOrders.data,
		getLoadAction: (state: IGlobalState) => state.appointments.details.changeOrders.load,
		getRefreshAction: (state: IGlobalState) => state.appointments.details.changeOrders.refresh
	},
	getStates: (state: IGlobalState) => state.appointments.details.states
};
