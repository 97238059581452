import { isValidPhoneNumber as libValid, parsePhoneNumber } from 'libphonenumber-js';

const isValidPhoneNumber = (phoneNumber: string): boolean => {
	try {
		return libValid(phoneNumber, 'US');
	} catch (error) {
		return false;
	}
};

const formatPhoneNumberLib = (phoneNumber: string): string => {
	try {
		return parsePhoneNumber(phoneNumber, 'US').formatNational();
	} catch (error) {
		return phoneNumber;
	}
};

export const phoneUtil = {
	isValidPhoneNumber,
	formatPhoneNumberLib
};
