import { mdiRefresh } from '@mdi/js';
import Icon from '@mdi/react';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Fade, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { EActionState } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { TransitionGroup } from 'react-transition-group';
import { actionCreator, selectors } from 'store/home';
import { AppointmentContainer } from './appointmentcontainer';
import { FutureLockBanner } from './futurelockbanner';

export const FutureAppointments = () => {
	const loadingAction = useSelector(selectors.getLoadAction);
	const dispatch = useDispatch();
	const rowIds = useSelector(selectors.getFutureAppointmentIds);
	return (
		<Stack>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					my: 2
				}}>
				<Typography variant='subtitle1' sx={{ ml: 1, flex: 1 }}>
					Future Appointments
				</Typography>
				<LoadingButton
					loading={loadingAction === EActionState.RUNNING}
					variant='outlined'
					onClick={() => dispatch(actionCreator.LOAD_START())}
					sx={{ ml: 2 }}
					startIcon={<Icon size={1} path={mdiRefresh} />}>
					Refresh
				</LoadingButton>
			</Box>
			<FutureLockBanner />
			<Grid container spacing={2}>
				{rowIds.length === 0 && loadingAction === EActionState.COMPLETED && (
					<Alert severity='info' sx={{ ml: 2, mt: 1 }}>
						You currently have no appointments for the future
					</Alert>
				)}
				<TransitionGroup component={null}>
					{rowIds.map(rid => (
						<Fade key={rid} mountOnEnter unmountOnExit>
							<Grid
								xs={12}
								sm={12}
								md={6}
								lg={4}
								minWidth={256}
								sx={{
									display: 'flex'
								}}>
								<AppointmentContainer rowId={rid} showDate={true} />
							</Grid>
						</Fade>
					))}
				</TransitionGroup>
			</Grid>
		</Stack>
	);
};
