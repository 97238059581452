import { ERole, useAuth } from 'auth';
import _ from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { actionCreator } from 'store/application';
import { UnauthorizedView } from './unauthorizedview';

export const Page = (props: { title: string; component: any; allowedRoles?: ERole[] }) => {
	const mountedRef = useRef(false);
	const dispatch = useDispatch();
	const { roles } = useAuth();

	useEffect(() => {
		if (!mountedRef.current) {
			document.title = `Precision Sales | ${props.title}`;
			dispatch(actionCreator.SET_PAGE_TITLE(props.title));
			mountedRef.current = true;
		}
	}, [dispatch, props.title]);
	if (props.allowedRoles) {
		const intersection = _.intersection(props.allowedRoles, roles);
		if (intersection.length === 0) {
			return <UnauthorizedView />;
		}
	}
	return <props.component />;
};
