import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IRegionInfoResponse } from 'api/selfgen/controller';
import _ from 'lodash';
import { EActionState, IListValue } from 'models';
import { selfGenService } from 'services';
import { resetState } from 'store/common/slice';
import { initialState } from './state';

const slice = createSlice({
	name: '@@selfgen',
	initialState,
	reducers: {
		INIT_START: state => {
			state.init = EActionState.RUNNING;
		},
		INIT_COMPLETED: (
			state,
			action: PayloadAction<{
				company: string;
				buildingTypes: IListValue[];
				timeWindows: string[];
				rooms: IListValue[];
			}>
		) => {
			state.init = EActionState.COMPLETED;
			state.company = action.payload.company;
			state.buildingTypes = action.payload.buildingTypes;
			state.timeWindows = action.payload.timeWindows;
			state.rooms = action.payload.rooms;

			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);

			selfGenService.setNextEnabled(state.form);
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
		},
		INIT_FLUSH: state => {
			resetState(state, initialState);
		},
		SET_ZIP_CODE: (state, action: PayloadAction<string>) => {
			state.form.formData.zipCode.newValue = action.payload;
			state.form.regionInfo = initialState.form.regionInfo;
			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);

			state.form.activity.loadRegionEnabled = selfGenService.zipValid(state.form.formData.zipCode.newValue);
		},
		SET_CITY: (state, action: PayloadAction<string>) => {
			state.form.formData.city.newValue = action.payload;
			selfGenService.setStateFromCity(state.form);

			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		SET_DATE: (state, action: PayloadAction<string>) => {
			state.form.formData.date.newValue = action.payload;
			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		SET_PRODUCT: (state, action: PayloadAction<{ product: string; checked: boolean }>) => {
			const p_i = _.findIndex(state.form.formData.products, p => p.product === action.payload.product);
			if (p_i !== -1) {
				state.form.formData.products[p_i].checked = action.payload.checked;
			}
			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		SET_AREA: (state, action: PayloadAction<{ area: IListValue; checked: boolean }>) => {
			const a_i = _.findIndex(state.form.formData.areas, a => a.area.id === action.payload.area.id);
			if (a_i !== -1) {
				state.form.formData.areas[a_i].checked = action.payload.checked;
			}
			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		SET_BUILDING_TYPE: (state, action: PayloadAction<string>) => {
			state.form.formData.buildingType.newValue = action.payload;
			selfGenService.setBusinessUnitFromBuildingType(state.form, state.buildingTypes);

			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		SET_PROPERTY: (state, action: PayloadAction<{ prop: string; value: string | string[] }>) => {
			state.form.formData[action.payload.prop].newValue = action.payload.value;
			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		LOAD_ZIP_START: state => {
			state.form.activity.loadRegion = EActionState.RUNNING;
			resetState(state.form.regionInfo, initialState.form.regionInfo);
		},
		LOAD_ZIP_COMPLETED: (state, action: PayloadAction<IRegionInfoResponse>) => {
			state.form.activity.loadRegion = EActionState.COMPLETED;
			state.form.regionInfo.cities = action.payload.cities;
			state.form.regionInfo.products = action.payload.products;
			state.form.regionInfo.serviceRegion = action.payload.serviceRegion;
			state.form.regionInfo.serviced = action.payload.serviced;
			state.form.regionInfo.validZip = action.payload.validZip;

			selfGenService.setDefaultCity(state.form);
			selfGenService.setProducts(state.form);
			selfGenService.setAreas(state.form, state.rooms);

			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		LOAD_ZIP_FAILED: state => {
			state.form.activity.loadRegion = EActionState.FAILED;

			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		LOAD_ZIP_FLUSH: state => {
			resetState(state.form.regionInfo, initialState.form.regionInfo);
			resetState(state.form.activity.step1, initialState.form.activity.step1);
			resetState(state.form.activity.step2, initialState.form.activity.step2);
			resetState(state.form.activity.step3, initialState.form.activity.step3);
			resetState(state.form.activity.step4, initialState.form.activity.step4);
			resetState(state.form.formData.city, initialState.form.formData.city);
			resetState(state.form.formData.state, initialState.form.formData.state);

			state.form.activity.loadRegionEnabled = selfGenService.zipValid(state.form.formData.zipCode.newValue);

			selfGenService.setProducts(state.form);
			selfGenService.setAreas(state.form, state.rooms);

			selfGenService.setErrorState(state.form);
			selfGenService.setEnabledState(state.form);
		},
		//STEP 1
		STEP_1_NEXT_START: state => {
			state.form.activity.step1.next = EActionState.RUNNING;
		},
		STEP_1_NEXT_COMPLETED: state => {
			state.form.activity.step1.next = EActionState.COMPLETED;
			state.form.wizard.step = 2;
		},
		STEP_1_NEXT_FAILED: state => {
			state.form.activity.step1.next = EActionState.PENDING;
		},
		//STEP 2
		STEP_2_NEXT_START: state => {
			state.form.activity.step2.next = EActionState.RUNNING;
		},
		STEP_2_NEXT_COMPLETED: state => {
			state.form.activity.step2.next = EActionState.COMPLETED;
			state.form.wizard.step = 3;
		},
		STEP_2_NEXT_FAILED: state => {
			state.form.activity.step2.next = EActionState.PENDING;
		},
		STEP_2_PREVIOUS: state => {
			state.form.wizard.step = 1;
		},
		//STEP 3
		STEP_3_NEXT_START: state => {
			state.form.activity.step3.next = EActionState.RUNNING;
		},
		STEP_3_NEXT_COMPLETED: state => {
			state.form.activity.step3.next = EActionState.COMPLETED;
			state.form.wizard.step = 4;
		},
		STEP_3_NEXT_FAILED: state => {
			state.form.activity.step3.next = EActionState.PENDING;
		},
		STEP_3_PREVIOUS: state => {
			state.form.wizard.step = 2;
		},
		//STEP 4
		STEP_4_SUBMIT_START: state => {
			state.form.activity.step4.submit = EActionState.RUNNING;
		},
		STEP_4_SUBMIT_COMPLETED: state => {
			state.form.activity.step4.submit = EActionState.COMPLETED;
			state.form.wizard.step = 5;
		},
		STEP_4_SUBMIT_FAILED: state => {
			state.form.activity.step4.submit = EActionState.PENDING;
		},
		STEP_4_PREVIOUS: state => {
			state.form.wizard.step = 3;
		},
		//STEP 5
		STEP_5_PREVIOUS: state => {
			state.form.wizard.step = 4;
		},
		SET_REFERRAL: (state, action: PayloadAction<{ account: string; promo: string }>) => {
			state.form.formData.referringAccountNumber = action.payload.account;
			state.form.formData.promotionCode = action.payload.promo;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
