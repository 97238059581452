import { Backdrop, Box, LinearProgress } from '@mui/material';
import desktopLogo from 'assets/images/logo_960.png';
import mobileLogo from 'assets/images/logo_300.png';
import { useScreenType } from 'hooks';

export const SplashScreen = (): JSX.Element => {
	const screenType = useScreenType();
	return (
		<Backdrop
			sx={{
				backgroundColor: theme => theme.palette.common.white,
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				flexDirection: 'column'
			}}
			open>
			<Box>
				{screenType.isDesktop && <img src={desktopLogo} alt='Precision Sales Logo' />}
				{screenType.isPhone && <img src={mobileLogo} alt='Precisional Sales Logo' />}
			</Box>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					color: theme => theme.palette.common.white
				}}>
				<LinearProgress
					sx={{
						margin: 4,
						width: {
							md: 400,
							xs: 250
						}
					}}
					color='primary'
				/>
			</Box>
		</Backdrop>
	);
};
