import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.customers.search.init,
	getLoadAction: (state: IGlobalState) => state.customers.search.load,
	getSearchFilter: (state: IGlobalState) => state.customers.search.searchFilter,
	getSortField: (state: IGlobalState) => state.customers.search.sortField,
	getSortDirection: (state: IGlobalState) => state.customers.search.sortDirection,
	getSortedResults: (state: IGlobalState) => state.customers.search.sortedResults,
	filterModal: {
		getOpen: (state: IGlobalState) => state.customers.search.filterModal.open,
		getSearchFilter: (state: IGlobalState) => state.customers.search.filterModal.searchFilter,
		getDateRange: (state: IGlobalState) => state.customers.search.filterModal.dateRange,
		getSortField: (state: IGlobalState) => state.customers.search.filterModal.sortField,
		getSortDirection: (state: IGlobalState) => state.customers.search.filterModal.sortDirection
	}
};
