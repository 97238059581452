export interface ICustomerSearchResult {
	customerAddress: string;
	customerName: string;
	addressLine1: string;
	addressLine2: string;
	alternatePhoneNumber: string;
	businessName: string;
	businessUnit: string;
	city: string;
	company: string;
	contractNumber: string;
	emailAddress: string;
	firstName: string;
	lastActivityId: string | null;
	lastActivityStartTimeUTC: string | null;
	lastName: string;
	nextActivityId: string | null;
	nextActivityStartTimeUTC: string | null;
	name: string;
	primaryPhoneNumber: string;
	serviceRegion: string;
	state: string;
	zipCode: string;
}

export enum ECustomerSearchResultSortField {
	CUSTOMER_ADDRESS = 'customerAddress',
	CUSTOMER_NAME = 'customerName',
	LAST_ACTIVITY = 'lastActivityStartTimeUTC',
	NEXT_ACTIVITY = 'nextActivityStartTimeUTC'
}

export enum ECustomerSearchDateRange {
	TODAY = 'Today',
	ANY = 'Any',
	YESTERDAY = 'Yesterday',
	LAST_7 = 'Last 7 Days',
	LAST_30 = 'Last 30 Days'
}
