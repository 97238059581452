import { AccountInfo, Configuration, PublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import jwtDecode from 'jwt-decode';
import { ISiebelSalesRep } from 'models/siebelsalesrep';
import { IIdToken } from 'models/token';
import { ERole } from './constants';

const USERNAME_LOCAL_STORAGE_KEY = 'PS_MSAL_USERNAME';

export const msalConfig: Configuration = {
	auth: {
		clientId: '92b7d904-cfba-4e41-b7ca-71073e98de9a',
		authority: 'https://login.microsoftonline.com/organizations',
		redirectUri: '/',
		postLogoutRedirectUri: '/',
		navigateToLoginRequestUrl: false
	},
	cache: {
		cacheLocation: 'localStorage', // This configures where your cache will be stored
		storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
	},
	system: {
		tokenRenewalOffsetSeconds: 1000
	}
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const redirectRequest: RedirectRequest = {
	scopes: ['openid', 'profile', 'offline_access'],
	prompt: 'select_account',
	state: `${window.location.pathname}${window.location.search}`
};

export const silentRequest = {
	scopes: ['openid', 'profile', 'api://92b7d904-cfba-4e41-b7ca-71073e98de9a/access_as_user', 'User.Read', 'offline_access']
};

function refreshPage() {
	window.location.reload();
}

export const loginAccount = async (): Promise<void> => {
	await msalInstance.loginRedirect(redirectRequest);
};

export const logoutAccount = async (): Promise<void> => {
	const account = getCurrentAccount();
	if (account) {
		setCurrentUsername(null);
		await msalInstance.logoutRedirect();
	}
};

export const setCurrentAccount = (userName: string) => {
	setCurrentUsername(userName);
	refreshPage();
};

export const getAccount = (): AccountInfo | null => {
	let account: AccountInfo | null = null;
	const accounts = msalInstance.getAllAccounts();

	if (accounts.length > 0) {
		account = getCurrentAccount();
		if (!account) {
			// return first account that we have
			account = accounts[0];
			setCurrentUsername(account.username);
		}
	}

	return account;
};

export const getCurrentAccount = (): AccountInfo | null => {
	const accounts = msalInstance.getAllAccounts();
	const username = getCurrentUsername();
	return username ? msalInstance.getAccountByUsername(username) : accounts[0];
};

export const getAllAccounts = (): AccountInfo[] => {
	return msalInstance.getAllAccounts();
};

export const getTokens = (account: AccountInfo): Promise<void | { idToken: string; accessToken: string }> => {
	const request: RedirectRequest = {
		...silentRequest,
		account: account,
		redirectUri: window.location.origin
	};
	const response = msalInstance
		.acquireTokenSilent(request)
		.then(async response => {
			return {
				idToken: response.idToken,
				accessToken: response.accessToken
			};
		})
		.catch(async error => {
			console.error('acquireTokenSilent:Error', error);
			await msalInstance.acquireTokenRedirect(request).catch(error => {
				console.error('acquireTokenRedirect:Error', error);
				throw error;
			});
		});
	return response;
};

export const getCurrentUsername = (): string | null => {
	return localStorage.getItem(USERNAME_LOCAL_STORAGE_KEY);
};

const setCurrentUsername = (userName: string | null): void => {
	if (userName) {
		localStorage.setItem(USERNAME_LOCAL_STORAGE_KEY, userName);
	} else {
		localStorage.removeItem(USERNAME_LOCAL_STORAGE_KEY);
	}
};

export const getAccessToken = async (): Promise<string | null> => {
	const account = getCurrentAccount();
	if (account !== null) {
		const tokens = await getTokens(account);
		if (tokens) return tokens.accessToken;
	}

	return null;
};

export const getInitials = (rep: ISiebelSalesRep | null): string => {
	if (!rep) {
		return '';
	}

	const firstName = rep?.firstName ?? '';
	const lastName = rep?.lastName ?? '';
	const firstInitial = firstName.length > 0 ? firstName[0] : '';
	const lastInitial = lastName.length > 0 ? lastName[0] : '';

	return `${firstInitial}${lastInitial}`;
};

const parseToken = (idToken: string): IIdToken => {
	return jwtDecode<IIdToken>(idToken);
};

const getRolesFromAccount = (account: AccountInfo): ERole[] => {
	if (!account?.idTokenClaims) return [];
	return account.idTokenClaims['roles']?.map(s => s as ERole) ?? [];
};

export const authService = {
	getRolesFromAccount,
	parseToken
};
