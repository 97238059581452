import _ from 'lodash';
import { DefaultAppointmentState, EMobileStatus, IAppointment, IAppointmentCombinedState } from 'models';
import { dateService, pacsService } from 'services';

const isFutureLocked = (state: IAppointmentCombinedState): boolean => {
	if (!dateService.isFutureDate(state.leadStartTimeUtc)) return false;
	if (pacsService.CanAcceptFutureAppointment) return false;
	return true;
};

const isLeadCompletionRequired = (state: IAppointmentCombinedState): boolean => {
	if (!pacsService.RequireLeadCompletion) return false;
	return state.unresolvedCount > 0;
};

const setAcceptEnabled = (state: IAppointmentCombinedState) => {
	let accept = true;
	if (isFutureLocked(state)) {
		accept = false;
	}
	if (isLeadCompletionRequired(state)) {
		accept = false;
	}
	if (state.mobileStatus !== EMobileStatus.PENDING) {
		accept = false;
	}
	state.acceptEnabled = accept;
};

const setResolveEnabled = (state: IAppointmentCombinedState) => {
	if (!state.locked) return false;
	if (state.isMasked) return false;
	state.resolveEnabled = pacsService.ResolutionEnabled;
};

const setArrivalNoticeEnabled = (state: IAppointmentCombinedState) => {
	if (state.isMasked) return false;
	if (!isAccepted(state.mobileStatus)) return false;
	state.arrivalNoticeEnabled = pacsService.ArrivalNoticeQuantityEnabled || pacsService.ArrivalNoticeTimeEnabled;
};

const buildStateModel = (source: IAppointment): IAppointmentCombinedState => {
	const state: IAppointmentCombinedState = {
		...source,
		...DefaultAppointmentState
	};
	setAcceptEnabled(state);
	setResolveEnabled(state);
	setArrivalNoticeEnabled(state);
	return state;
};

const buildStateModels = (source: IAppointment[]): IAppointmentCombinedState[] => {
	return source.map(a => buildStateModel(a));
};

const isAccepted = (source: EMobileStatus): boolean => {
	switch (source) {
		case EMobileStatus.NA:
		case EMobileStatus.PENDING: {
			return false;
		}
	}
	return true;
};

const getFutureLock = (source: IAppointmentCombinedState[]): boolean => {
	return _.some(source, a => isFutureLocked(a));
};

const getRequireLeadCompletion = (source: IAppointmentCombinedState[]): boolean => {
	return _.some(source, a => isLeadCompletionRequired(a));
};

const getRowIds = (source: IAppointmentCombinedState[]): { today: string[]; future: string[] } => {
	const today = _.map(
		_.orderBy(
			_.filter(source, s => !dateService.isFutureDate(s.leadStartTimeUtc)),
			['leadStartTimeUtc', 'rowId'],
			['asc', 'asc']
		),
		a => a.rowId
	);
	const future = _.map(
		_.orderBy(
			_.filter(source, s => dateService.isFutureDate(s.leadStartTimeUtc)),
			['leadStartTimeUtc', 'rowId'],
			['asc', 'asc']
		),
		a => a.rowId
	);
	return { today, future };
};

export const appointmentService = {
	getRowIds,
	buildStateModels,
	buildStateModel,
	isAccepted,
	getFutureLock,
	getRequireLeadCompletion
};
