import { EActionState, EArrivalNoticeMethod, IAppointment, IArrivalNoticeQuantity, IArrivalNoticeTime } from 'models';

export interface IState {
	open: boolean;
	init: EActionState;
	appointment: IAppointment | null;
	method: EArrivalNoticeMethod | null;
	quantityEnabled: boolean;
	timeEnabled: boolean;
	quantities: IArrivalNoticeQuantity[];
	selectedQuantity: IArrivalNoticeQuantity | null;
	times: IArrivalNoticeTime[];
	selectedTime: IArrivalNoticeTime | null;
	send: EActionState;
	sendEnabled: boolean;
	resultMessage: string;
}

export const initialState: IState = {
	open: false,
	init: EActionState.PENDING,
	appointment: null,
	method: null,
	quantityEnabled: false,
	timeEnabled: false,
	quantities: [],
	selectedQuantity: null,
	times: [],
	selectedTime: null,
	send: EActionState.PENDING,
	sendEnabled: false,
	resultMessage: ''
};
