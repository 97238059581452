const parseDate = (dateString: string) => {
	if (!dateString) return null;
	return new Date(dateString);
};

const formatDate = (date?: Date) => {
	if (!date) return 'No Date';
	return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
};

export const dateUtil = {
	parseDate,
	formatDate
};
