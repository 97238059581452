import { ERole, useAuth } from 'auth';
import _ from 'lodash';
import * as React from 'react';
import { ReactElement } from 'react';

export const PermWrapper = (props: { allowedRoles: ERole[]; children?: React.ReactElement }): ReactElement | null => {
	const { roles } = useAuth();
	const intersection = _.intersection(props.allowedRoles, roles);
	if (intersection.length === 0) return null;
	return props.children || null;
};
