import { mdiBellAlertOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { Alert, Button, Collapse } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { push } from 'redux-first-history';
import { selectors } from 'store/notifications';

const StyledAlert = styled(Alert)<{ component?: React.ElementType }>(() => {
	return {
		'&': {
			borderRadius: 12,
			minHeight: 68,
			alignItems: 'center'
		},
		'& .MuiAlert-action': {
			alignItems: 'center',
			paddingTop: 0
		},
		'& .MuiAlert-icon': {
			alignItems: 'center',
			marginRight: 16
		}
	};
});

export const NotificationBanner = () => {
	const dispatch = useDispatch();
	const count = useSelector(selectors.getUnreadCount);
	const handleClick = () => {
		dispatch(push('/notifications'));
	};
	return (
		<Collapse in={count > 0}>
			<StyledAlert
				severity='info'
				sx={{ mb: count > 0 ? 1 : 0 }}
				icon={<Icon path={mdiBellAlertOutline} size={1.2} />}
				action={
					<Button color='inherit' size='small' onClick={handleClick}>
						VIEW
					</Button>
				}>
				{`You Have ${count} unread notifications`}
			</StyledAlert>
		</Collapse>
	);
};
