import { Backdrop, CircularProgress, Typography } from '@mui/material';
import { useScreenType } from 'hooks';

const DRAWER_WIDTH = 280;

export const LoaderBackdrop = (props: { text?: string; disableDrawerInset?: boolean }) => {
	const { text, disableDrawerInset } = props;
	const screenType = useScreenType();
	const spinnerSize = text ? 32 : 48;
	return (
		<Backdrop
			open
			transitionDuration={500}
			sx={{
				color: theme => theme.palette.common.white,
				pl: screenType.isDesktop && !disableDrawerInset ? `${DRAWER_WIDTH}px` : 'inherit'
			}}>
			<CircularProgress
				sx={{
					margin: 0.5
				}}
				size={spinnerSize}
				color={'inherit'}
			/>
			{text && (
				<Typography
					sx={{
						margin: 0.5,
						ml: 1
					}}
					variant='body1'
					color={'inherit'}>
					{text ? text : 'Loading...'}
				</Typography>
			)}
		</Backdrop>
	);
};
