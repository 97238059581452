import { EActionState } from './actionstate';
import { DefaultStringValue, IFormValue } from './forms';

export interface IReferralProspectFormState {
	open: boolean;
	submit: {
		enabled: boolean;
		actionState: EActionState;
	};

	formData: {
		accountId: string;
		emailAddress: IFormValue<string>;
		firstName: IFormValue<string>;
		lastName: IFormValue<string>;
		location: IFormValue<string>;
		notes: IFormValue<string>;
		opportunityId: string;
		phone: IFormValue<string>;
	};
}

export const DefaultReferralProspectFormState: IReferralProspectFormState = {
	open: false,
	submit: {
		enabled: false,
		actionState: EActionState.PENDING
	},
	formData: {
		accountId: '',
		emailAddress: DefaultStringValue,
		firstName: DefaultStringValue,
		lastName: DefaultStringValue,
		location: DefaultStringValue,
		opportunityId: '',
		notes: DefaultStringValue,
		phone: DefaultStringValue
	}
};
