import { ERole } from 'auth';
import { LoaderBackdrop, Page } from 'components';
import { lazy, Suspense } from 'react';
import { Outlet, Route, Navigate } from 'react-router';
import { Provider } from './provider';

const SearchImport = lazy(() => import('./search/main'));
const DetailsImport = lazy(() => import('./details/main'));
const NotesImport = lazy(() => import('./notes/main'));
const QuotesImport = lazy(() => import('./quotes/main'));
const ContractImport = lazy(() => import('./contract/main'));

const Search = <Page title='Appointment Search' component={SearchImport} allowedRoles={[ERole.ROLE_OSE]} />;
const Details = <Page title='Appointment Details' component={DetailsImport} allowedRoles={[ERole.ROLE_OSE]} />;
const Notes = <Page title='Appointment Notes' component={NotesImport} allowedRoles={[ERole.ROLE_OSE]} />;
const Quotes = <Page title='Quotes' component={QuotesImport} allowedRoles={[ERole.ROLE_OSE]} />;
const Contract = <Page title='Contract' component={ContractImport} allowedRoles={[ERole.ROLE_OSE]} />;

export const AppointmentRoutes = (
	<Route path='appointments' element={<Outlet />}>
		<Route path='search' element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Search}</Suspense>} />
		<Route path='details/:rowId' element={<Provider />}>
			<Route path='notes' element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Notes}</Suspense>} />
			<Route path='quotes' element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Quotes}</Suspense>} />
			<Route path='contract' element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Contract}</Suspense>} />
			<Route index element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Details}</Suspense>} />
		</Route>
		<Route path='details' element={<Navigate to='/appointments/search' />} />
		<Route index element={<Navigate to='/appointments/search' />} />
	</Route>
);
