import { PayloadAction } from '@reduxjs/toolkit';
import { messageFactory } from 'store/messaging';
import { IApiError, IApiProblem } from 'api';
import { all, put } from 'redux-saga/effects';

export function* handleSagaError(error: any, actions: PayloadAction<any>[]) {
	console.error('handleSagaError', [error, actions]);
	if (isApiError(error)) {
		if (isApiProblem(error.data)) {
			yield all([
				put(
					messageFactory.apiErrorMessage({
						statusText: error.statusText,
						message: error.data.title,
						stack: error.data.detail,
						statusCode: error.data.status,
						data: ''
					})
				),
				...actions.map(a => put(a))
			]);
			return;
		}
		yield all([
			put(
				messageFactory.errorMessage({
					statusText: error.statusText,
					message: error.message,
					stack: error.stack,
					statusCode: error.statusCode,
					data: ''
				})
			),
			...actions.map(a => put(a))
		]);
		return;
	}

	if (isTypeError(error)) {
		yield all([
			put(
				messageFactory.errorMessage({
					statusText: error.name,
					message: error.message,
					stack: error.stack ?? '',
					statusCode: 0,
					data: ''
				})
			),
			...actions.map(a => put(a))
		]);
		return;
	}
	yield all([
		put(
			messageFactory.errorMessage({
				statusText: '',
				message: error,
				stack: '',
				statusCode: 0,
				data: ''
			})
		),
		...actions.map(a => put(a))
	]);
}

const isApiError = (error: any): error is IApiError => {
	return error?.data !== undefined;
};

const isApiProblem = (data: any): data is IApiProblem => {
	return data?.title !== undefined && data?.detail !== undefined;
};

const isTypeError = (data: any): data is TypeError => {
	return data instanceof TypeError;
};
