import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EActionState, EArrivalNoticeMethod, IAppointment, IArrivalNoticeQuantity, IArrivalNoticeTime } from 'models';
import { resetState } from 'store/common/slice';
import { initialState } from './state';
import { pacsService } from 'services';

const slice = createSlice({
	name: '@@arrival',
	initialState,
	reducers: {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		INIT_START: (state, action: PayloadAction<{ activityId: string }>) => {
			state.init = EActionState.RUNNING;
			state.open = true;
		},
		INIT_COMPLETED: (
			state,
			action: PayloadAction<{
				appointment: IAppointment;
				quantities: IArrivalNoticeQuantity[];
				times: IArrivalNoticeTime[];
			}>
		) => {
			state.init = EActionState.COMPLETED;
			state.appointment = action.payload.appointment;
			state.quantityEnabled = pacsService.ArrivalNoticeQuantityEnabled;
			state.timeEnabled = pacsService.ArrivalNoticeTimeEnabled;
			state.method = state.quantityEnabled ? EArrivalNoticeMethod.QUANTITY : EArrivalNoticeMethod.TIME;
			state.quantities = action.payload.quantities;
			state.times = action.payload.times;
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
		},
		INIT_FLUSH: state => {
			resetState(state, initialState);
		},
		SET_METHOD: (state, action: PayloadAction<EArrivalNoticeMethod | null>) => {
			state.method = action.payload;
			state.selectedQuantity = null;
			state.selectedTime = null;
			state.sendEnabled = false;
			state.resultMessage = '';
		},
		SET_QUANTITY: (state, action: PayloadAction<IArrivalNoticeQuantity | null>) => {
			state.selectedQuantity = action.payload;
			state.sendEnabled = state.selectedQuantity != null;
			state.resultMessage = '';
		},
		SET_TIME: (state, action: PayloadAction<IArrivalNoticeTime | null>) => {
			state.selectedTime = action.payload;
			state.sendEnabled = state.selectedTime != null;
			state.resultMessage = '';
		},
		SEND_QUANTITY_START: state => {
			state.send = EActionState.RUNNING;
			state.resultMessage = '';
		},
		SEND_QUANTITY_COMPLETED: (
			state,
			action: PayloadAction<{
				message: string;
			}>
		) => {
			state.send = EActionState.COMPLETED;
			state.resultMessage = action.payload.message;
		},
		SEND_QUANTITY_FAILED: state => {
			state.send = EActionState.PENDING;
		},
		SEND_TIME_START: state => {
			state.send = EActionState.RUNNING;
			state.resultMessage = '';
		},
		SEND_TIME_COMPLETED: (
			state,
			action: PayloadAction<{
				message: string;
			}>
		) => {
			state.send = EActionState.COMPLETED;
			state.resultMessage = action.payload.message;
		},
		SEND_TIME_FAILED: state => {
			state.send = EActionState.PENDING;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
