import { api } from 'api/api';
import { IArrivalNoticeQuantityRequest, IArrivalNoticeResponse, IArrivalNoticeTimeRequest } from 'api/appointments/controller';
import { IAppointment, IArrivalNoticeQuantity, IArrivalNoticeTime } from 'models';
import { put, select, takeLatest } from 'redux-saga/effects';
import { arrivalNoticeService, messagingService } from 'services';
import { handleSagaError } from 'store/common';
import { selectors } from './selectors';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.SEND_QUANTITY_START, onSendQuantityStart);
	yield takeLatest(actionCreator.SEND_TIME_START, onSendTimeStart);
}

function* onInitStart(action: ReturnType<typeof actionCreator.INIT_START>): any {
	try {
		const activityId: string = action.payload.activityId;
		const appointment: IAppointment = yield api.appointments.getByActivityId(activityId);
		const quantities: IArrivalNoticeQuantity[] = arrivalNoticeService.buildQuantities();
		const times: IArrivalNoticeTime[] = arrivalNoticeService.buildTimes(appointment.startTime, appointment.endTime);

		yield put(
			actionCreator.INIT_COMPLETED({
				appointment,
				quantities,
				times
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onSendQuantityStart(): any {
	try {
		const appointment: IAppointment = yield select(selectors.getAppointment);
		const quantity: IArrivalNoticeQuantity = yield select(selectors.getSelectedQuantity);
		const request: IArrivalNoticeQuantityRequest = {
			activityId: appointment.activityId,
			minutes: quantity.minutes
		};
		const response: IArrivalNoticeResponse = yield api.appointments.postArrivalNoticeQuantity(request);
		if (response.success) {
			messagingService.popSnackbar('Arrival Notice Sent', 'success');
			yield put(actionCreator.INIT_FLUSH());
		} else {
			yield put(
				actionCreator.SEND_QUANTITY_COMPLETED({
					message: response.message
				})
			);
		}
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.SEND_QUANTITY_FAILED()]);
	}
}

function* onSendTimeStart(): any {
	try {
		const appointment: IAppointment = yield select(selectors.getAppointment);
		const time: IArrivalNoticeTime = yield select(selectors.getSelectedTime);
		const request: IArrivalNoticeTimeRequest = {
			activityId: appointment.activityId,
			time: time.value
		};
		const response: IArrivalNoticeResponse = yield api.appointments.postArrivalNoticeTime(request);
		if (response.success) {
			messagingService.popSnackbar('Arrival Notice Sent', 'success');
			yield put(actionCreator.INIT_FLUSH());
		} else {
			yield put(
				actionCreator.SEND_TIME_COMPLETED({
					message: response.message
				})
			);
		}
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.SEND_TIME_FAILED()]);
	}
}
