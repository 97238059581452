import { AxiosResponse } from 'axios';
import { IKnobs, IMap, IUrls } from 'models';
import { get } from '../axiosprovider';
import { IAppInsightsClientConfiguration } from 'models';

const getStartupConfig = async (): Promise<AxiosResponse<IStartupResponse>> => {
	return await get<IStartupResponse>('/api/startup');
};

export const startupController = {
	getStartupConfig
};

export interface IStartupResponse {
	appInsightsConnectionString: string;
	appInsightsClient: IAppInsightsClientConfiguration;
	buildVersion: string;
	versionCheckInterval: number;
	knobs: IKnobs;
	map: IMap;
	urls: IUrls;
}
