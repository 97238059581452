import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import { actionCreator } from 'store/application';
import { selectors } from 'store/auth';
import { useDispatch } from 'react-redux';
import { getInitials, useAuth } from 'auth';
import { Link as RouterLink } from 'react-router-dom';
import { alpha } from '@mui/material/styles';
import { useSelector } from 'react-redux';

export const AccountFooter = () => {
	const { currentAccount } = useAuth();
	const salesRep = useSelector(selectors.getSalesRep);
	const dispatch = useDispatch();
	const onNavigate = () => {
		dispatch(actionCreator.SET_DRAWER_OPEN(false));
	};
	return (
		<ListItemButton
			component={RouterLink}
			to='/account'
			onClick={onNavigate}
			sx={{
				display: 'flex',
				p: 1,
				pl: 1,
				pt: 2,
				pb: 2,
				borderRadius: 0,
				flex: 0
			}}>
			<ListItemAvatar
				sx={{
					minWidth: 32
				}}>
				<Avatar
					sx={{
						height: 36,
						width: 36,
						fontSize: 14,
						ml: 1
					}}
					alt='Profile Avatar'
					src={`/api/avatar/${currentAccount?.username}`}>
					{getInitials(salesRep)}
				</Avatar>
			</ListItemAvatar>
			<ListItemText
				sx={{ my: 0, px: 1 }}
				primary={currentAccount?.name ?? `${salesRep?.firstName} ${salesRep?.lastName} (${salesRep?.repId})`}
				secondary={currentAccount?.username}
				primaryTypographyProps={{
					fontSize: 14,
					fontWeight: 'medium',
					letterSpacing: 0,
					noWrap: true,
					color: theme => alpha(theme.palette.common.white, 0.8)
				}}
				secondaryTypographyProps={{
					fontSize: 12,
					fontWeight: 'medium',
					letterSpacing: 0,
					noWrap: true,
					color: theme => alpha(theme.palette.common.white, 0.8)
				}}
			/>
			{/* <Avatar
				sx={{
					margin: 0.5,
					fontSize: 14
				}}
				alt='Profile Avatar'
				src={`/api/avatar/${currentAccount?.username}`}>
				{getInitials()}
			</Avatar>
			<Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'flex-start', color: 'black', px: 1 }}>
				<Typography
					variant='body1'
					color='inherit'
					sx={{
						fontSize: '14px',
						textTransform: 'initial'
					}}>
					{currentAccount?.name}
				</Typography>
				<Typography
					variant='caption'
					color='inherit'
					sx={{
						fontSize: '12px',
						textTransform: 'initial'
					}}>
					{currentAccount?.username}
				</Typography>
			</Box> */}
		</ListItemButton>
	);
};
