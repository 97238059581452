import { IArea } from './area';

export enum EOrderStatus {
	CancelPending = 'Cancel Pending',
	Cancelled = 'Cancelled',
	Completed = 'Completed',
	Deleted = 'Deleted',
	Open = 'Open'
}

export interface IOrder {
	areas: IArea[];
	d365Id: string;
	id: string;
	installationDate: string;
	name: string;
	productLine: string;
	status: EOrderStatus;
	totalCurrentPriceWithFixed: number;
}
