import { Provider } from './provider';
import { View } from './view';

const Main = (): JSX.Element => {
	return (
		<Provider>
			<View />
		</Provider>
	);
};

export default Main;
