import { IState } from 'store/resolution';
import { isNullOrWhiteSpace } from 'coreutil';

const setEnabledState = (state: IState) => {
	state.code.error = isNullOrWhiteSpace(state.code.newValue);
	state.resolveEnabled = !state.code.error || !state.appointment.isCodeRequired;
};

export const resolutionService = {
	setEnabledState
};
