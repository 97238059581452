import { api } from 'api/api';
import { IStartupResponse } from 'api/startup/controller';
import { put, takeLatest } from 'redux-saga/effects';
import { actionCreator } from './slice';
import { IPACSValue } from 'models';
import { handleSagaError } from 'store/common';
import { pacsService } from 'services';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
}

function* onInitStart(): any {
	try {
		const startupResponse: IStartupResponse = yield api.startup.getStartupConfig();
		const pacsResponse: IPACSValue[] = yield api.pacs.getValues();

		pacsService.build(pacsResponse);

		yield put(
			actionCreator.INIT_COMPLETED({
				connectionString: startupResponse.appInsightsConnectionString,
				buildVersion: startupResponse.buildVersion,
				appInsightsClientConfiguration: startupResponse.appInsightsClient,
				versionCheckInterval: startupResponse.versionCheckInterval,
				knobs: startupResponse.knobs,
				map: startupResponse.map,
				urls: startupResponse.urls
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}
