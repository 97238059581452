import { enqueueSnackbar, VariantType } from 'notistack';

const popSnackbar = (message: string, variant: VariantType) => {
	enqueueSnackbar(message, {
		variant: variant
	});
};

export const messagingService = {
	popSnackbar
};
