import { AxiosResponse } from 'axios';
import { EMobileStatus, IAppointment, IAppointmentSearchResult } from 'models';
import { get, post } from '../axiosprovider';

const getByDate = async (date: string): Promise<AxiosResponse<IAppointment[]>> => {
	return await get<IAppointment[]>(`/api/appt/date/${date}`);
};

const getForHome = async (date: string): Promise<AxiosResponse<IAppointment[]>> => {
	return await get<IAppointment[]>(`/api/appt/home/${date}`);
};

const getByRowId = async (rowId: string): Promise<AxiosResponse<IAppointment>> => {
	return await get<IAppointment>(`/api/appt/rowId/${rowId}`);
};

const getByActivityId = async (activityId: string): Promise<AxiosResponse<IAppointment>> => {
	return await get<IAppointment>(`/api/appt/activityId/${activityId}`);
};

const updateMobileStatus = async (request: IUpdateMobileStatusRequest): Promise<AxiosResponse<IAppointment>> => {
	return await post<IAppointment>('/api/appt/mobileStatus', request);
};

const postArrivalNoticeQuantity = async (request: IArrivalNoticeQuantityRequest): Promise<AxiosResponse<IArrivalNoticeResponse>> => {
	return await post<IArrivalNoticeResponse>('/api/appt/arrival/quantity', request);
};

const postArrivalNoticeTime = async (request: IArrivalNoticeTimeRequest): Promise<AxiosResponse<IArrivalNoticeResponse>> => {
	return await post<IArrivalNoticeResponse>('/api/appt/arrival/time', request);
};

const postResolve = async (request: IResolveAppointmentRequest): Promise<AxiosResponse<IResolveAppointmentResponse>> => {
	return await post<IResolveAppointmentResponse>('/api/appt/resolve', request);
};

const postSearch = async (request: IAppointmentSearchRequest): Promise<AxiosResponse<IAppointmentSearchResult[]>> => {
	return await post<IAppointmentSearchResult[]>('/api/appt/search', request);
};

export const appointmentsController = {
	getForHome,
	getByDate,
	getByRowId,
	getByActivityId,
	updateMobileStatus,
	postArrivalNoticeQuantity,
	postArrivalNoticeTime,
	postResolve,
	postSearch
};

export interface IAppointmentSearchRequest {
	searchTerm: string;
	startDate: string;
	endDate: string;
}

export interface IResolveAppointmentRequest {
	activityId: string;
	code: string;
}

export interface IResolveAppointmentResponse {
	success: boolean;
	message: string;
}

export interface IArrivalNoticeQuantityRequest {
	activityId: string;
	minutes: number;
}

export interface IArrivalNoticeTimeRequest {
	activityId: string;
	time: string;
}

export interface IArrivalNoticeResponse {
	success: boolean;
	message: string;
}

export interface IUpdateMobileStatusRequest {
	rowId: string;
	mobileStatus: EMobileStatus;
}
