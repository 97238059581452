import { Box, Typography } from '@mui/material';
import { isNullOrWhiteSpace } from 'coreutil';
import _ from 'lodash';
import { Masked } from 'components';

export const DataLine = (props: { left: string; right: string; masked?: boolean; color?: string }) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'center', minHeight: theme => theme.spacing(3) }}>
			<Typography variant='body2' noWrap sx={{ flexShrink: 0, flexGrow: 1, flexBasis: 'auto', pr: 0.5, textAlign: 'left' }}>
				{props.left}
			</Typography>
			<Typography variant='body2' noWrap sx={{ textAlign: 'right', color: props.color }} component={props.masked ? 'div' : null}>
				{props.masked && <Masked>{props.right}</Masked>}
				{!props.masked && props.right}
			</Typography>
		</Box>
	);
};

export const DataLineArray = (props: { left: string; right: string[] }) => {
	return (
		<Box sx={{ display: 'flex', alignItems: 'flex-start', minHeight: theme => theme.spacing(3) }}>
			<Typography variant='body2' noWrap sx={{ flexShrink: 0, flexGrow: 1, flexBasis: 'auto', pr: 0.5, textAlign: 'left' }}>
				{props.left}
			</Typography>
			<Box>
				{_.filter(props.right, r => !isNullOrWhiteSpace(r)).map(r => (
					<Typography key={r} variant='body2' noWrap sx={{ textAlign: 'right' }}>
						{r}
					</Typography>
				))}
			</Box>
		</Box>
	);
};
