import { isNullOrWhiteSpace } from 'coreutil';
import {
	addDays,
	addMonths,
	addWeeks,
	eachMinuteOfInterval,
	endOfWeek,
	format,
	getDate,
	getDay,
	getWeek,
	isFuture,
	isToday,
	parse,
	parseISO,
	startOfMonth,
	startOfToday,
	startOfWeek,
	subDays,
	subMinutes,
	subMonths,
	subWeeks
} from 'date-fns';
import { ESearchDateRange } from 'models';

const FORMAT_STRING = 'yyyy-MM-dd';
const FORMAT_PRETTY_STRING = 'M/d/yyyy';

const parseStringToDate = (rootDate: string): Date => parse_internal(rootDate);
const parseDateToString = (rootDate: Date): string => format_internal(rootDate);

const parse_internal = (rootDate: string): Date => {
	return parse(rootDate, FORMAT_STRING, new Date());
};

const format_internal = (rootDate: Date): string => {
	return format(rootDate, FORMAT_STRING);
};

const getLastDays = (count: number): { start: string; end: string } => {
	return {
		end: format_internal(startOfToday()),
		start: format_internal(subDays(startOfToday(), count))
	};
};

const getInitialMonthlyDate = (): string => {
	return format_internal(startOfMonth(new Date()));
};

const getInitialWeeklyDate = (): string => {
	return format_internal(startOfWeek(new Date()));
};

const getPreviousMonth = (rootDate: string): string => {
	return format_internal(startOfMonth(subMonths(parse_internal(rootDate), 1)));
};

const getPreviousWeek = (rootDate: string): string => {
	return format_internal(startOfWeek(subWeeks(parse_internal(rootDate), 1)));
};

const getNextMonth = (rootDate: string): string => {
	return format_internal(startOfMonth(addMonths(parse_internal(rootDate), 1)));
};

const getNextWeek = (rootDate: string): string => {
	return format_internal(startOfWeek(addWeeks(parse_internal(rootDate), 1)));
};

const getMonthlyTitle = (rootDate: string): string => {
	return format(parse_internal(rootDate), 'MMMM yyyy');
};

const getWeeklyTitle = (rootDate: string): string => {
	const start = startOfWeek(parse_internal(rootDate));
	const end = endOfWeek(parse_internal(rootDate));
	const week = getWeek(start);
	return `Week ${week}: ${format(start, 'M/d/yy')} - ${format(end, 'M/d/yy')}`;
};

const getToday = (): string => {
	return format_internal(startOfToday());
};

const getDayNum = (rootDateString: string): number => {
	return getDate(parse_internal(rootDateString));
};

const getDayOfWeek = (rootDateString: string): 0 | 1 | 2 | 3 | 4 | 5 | 6 => {
	return getDay(parse_internal(rootDateString));
};

const formatPretty = (rootDateString: string): string => {
	if (isNullOrWhiteSpace(rootDateString)) return '';
	const parsed = parse_internal(rootDateString);
	return format(parsed, FORMAT_PRETTY_STRING);
};

const getUTCNow = (): string => {
	return new Date().toISOString();
};

const getSyncDateUTC = (days: number): string => {
	return subDays(new Date(), days).toISOString();
};

const applyBuffer = (date: string, minutes: number): string => {
	return subMinutes(parseISO(date), minutes).toISOString();
};

const formatISO = (date: string): string => {
	return format(parseISO(date), 'M/d/yyyy h:mm:ss a');
};

const formatDurationMinutes = (minutes: number): string => {
	const time = {
		day: Math.floor(minutes / 60 / 24),
		hour: Math.floor(minutes / 60) % 24,
		minute: Math.floor(minutes) % 60
	};
	return Object.entries(time)
		.filter(val => val[1] !== 0)
		.map(([key, val]) => `${val} ${key}${val !== 1 ? 's' : ''}`)
		.join(' ');
};

const buildIntervals = (interval: number, startTime: string, endTime: string): Date[] => {
	const start = parse(startTime, 'HH:mm:ss', startOfToday());
	const end = parse(endTime, 'HH:mm:ss', startOfToday());
	return eachMinuteOfInterval(
		{
			start,
			end
		},
		{
			step: interval
		}
	);
};

const formatTime = (date: Date): string => {
	return format(date, 'h:mm aa');
};

const formatTimeSpan = (date: Date): string => {
	return format(date, 'HH:mm:ss');
};

const isFutureDate = (isoDate: string): boolean => {
	const parsed = parseISO(isoDate);
	return isFuture(parsed) && !isToday(parsed);
};

const buildSearchDateRange = (startOffset: number, endOffset: number): Interval => {
	return {
		start: addDays(startOfToday(), startOffset),
		end: addDays(startOfToday(), endOffset)
	};
};

const switchSearchDateRange = (dateRange: ESearchDateRange): { start: string; end: string } => {
	const interval = ((): Interval => {
		switch (dateRange) {
			case ESearchDateRange.TODAY:
				return buildSearchDateRange(0, 1);
			case ESearchDateRange.YESTERDAY:
				return buildSearchDateRange(-1, 0);
			case ESearchDateRange.LAST_7_DAYS:
				return buildSearchDateRange(-6, 1);
			case ESearchDateRange.LAST_30_DAYS:
				return buildSearchDateRange(-29, 1);
		}
	})();
	return {
		start: format_internal(interval.start as Date),
		end: format_internal(interval.end as Date)
	};
};

export const dateService = {
	switchSearchDateRange,
	getDayNum,
	getInitialMonthlyDate,
	getInitialWeeklyDate,
	getMonthlyTitle,
	getNextMonth,
	getNextWeek,
	getPreviousMonth,
	getPreviousWeek,
	getWeeklyTitle,
	getDayOfWeek,
	formatPretty,
	getToday,
	parseStringToDate,
	parseDateToString,
	getLastDays,
	getUTCNow,
	getSyncDateUTC,
	applyBuffer,
	formatISO,
	formatDurationMinutes,
	buildIntervals,
	formatTime,
	formatTimeSpan,
	isFutureDate
};
