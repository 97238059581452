import { IArrivalNoticeQuantity, IArrivalNoticeTime } from 'models';
import { dateService, pacsService } from 'services';

const buildQuantities = (): IArrivalNoticeQuantity[] => {
	return pacsService.ArrivalNoticeQuantities.map(q => ({
		minutes: q,
		display: dateService.formatDurationMinutes(q)
	}));
};

const buildTimes = (startTime: string, endTime: string): IArrivalNoticeTime[] => {
	return dateService.buildIntervals(pacsService.ArrivalNoticeTimes, startTime, endTime).map(t => ({
		value: dateService.formatTimeSpan(t),
		display: dateService.formatTime(t)
	}));
};

export const arrivalNoticeService = {
	buildTimes,
	buildQuantities
};
