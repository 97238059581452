import { api } from 'api/api';
import { IRegionInfoResponse, ISelfGenRequest } from 'api/selfgen/controller';
import { IFormValue, IListValue, ISelfGenFormState, ISiebelSalesRep } from 'models';
import { put, select, takeLatest } from 'redux-saga/effects';
import { selfGenService } from 'services';
import { selectors as authSelectors } from 'store/auth';
import { handleSagaError } from 'store/common';
import { selectors } from './selectors';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.LOAD_ZIP_START, onLoadZipStart);

	yield takeLatest(actionCreator.STEP_1_NEXT_START, onStep1NextStart);
	yield takeLatest(actionCreator.STEP_2_NEXT_START, onStep2NextStart);
	yield takeLatest(actionCreator.STEP_3_NEXT_START, onStep3NextStart);
	yield takeLatest(actionCreator.STEP_4_SUBMIT_START, onStep4SubmitStart);
}

function* onInitStart(): any {
	try {
		const company: string = yield select(authSelectors.salesRep.getCompany);
		const buildingTypes: IListValue[] = yield api.lov.getByValueTypeCompositeKey('BuildingType', company);
		const timeWindows: string[] = yield api.lov.getValueByValueType('TimeWindows');
		const rooms: IListValue[] = yield api.lov.getByValueType('Rooms');
		yield put(
			actionCreator.INIT_COMPLETED({
				company,
				buildingTypes,
				timeWindows,
				rooms
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onLoadZipStart(): any {
	try {
		const zipCode: IFormValue<string> = yield select(selectors.form.formData.getZipCode);
		const apiResponse: IRegionInfoResponse = yield api.selfGen.getRegionInfo(zipCode.newValue);
		yield put(actionCreator.LOAD_ZIP_COMPLETED(apiResponse));
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_ZIP_FAILED()]);
	}
}

function* onStep1NextStart(): any {
	try {
		yield put(actionCreator.STEP_1_NEXT_COMPLETED());
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.STEP_1_NEXT_FAILED()]);
	}
}

function* onStep2NextStart(): any {
	try {
		yield put(actionCreator.STEP_2_NEXT_COMPLETED());
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.STEP_2_NEXT_FAILED()]);
	}
}

function* onStep3NextStart(): any {
	try {
		yield put(actionCreator.STEP_3_NEXT_COMPLETED());
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.STEP_3_NEXT_FAILED()]);
	}
}

function* onStep4SubmitStart(): any {
	try {
		const rep: ISiebelSalesRep | null = yield select(authSelectors.getSalesRep);
		if (!rep) throw 'Profile Not Found';
		const state: ISelfGenFormState = yield select(selectors.form.get);
		const company: string = yield select(selectors.getCompany);
		const request: ISelfGenRequest = selfGenService.createRequest(state, company, rep);
		yield api.selfGen.postSelfGen(request);
		yield put(actionCreator.STEP_4_SUBMIT_COMPLETED());
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.STEP_4_SUBMIT_FAILED()]);
	}
}
