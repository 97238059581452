const timeSpanRegEx =
	/(?:(?<ne>-))?(?:(?:(?<dd>0*[0-9]+)[.])?(?:(?<HH>0*[2][0-3]|0*[1][0-9]|0*[0-9])[:]))?(?<mm>(?<=:)0*[0-5]?[0-9]|0*[5-9]?[0-9](?=[:]))(?:[:](?<ss>0*[0-5]?[0-9](?:[.][0-9]{0,7})?))?/;

export class TimeSpan {
	constructor(input: string) {
		const match = input.match(timeSpanRegEx);
		this.days = parseInt(match[2] ?? '0');
		this.hours = parseInt(match[3] ?? '0');
		this.minutes = parseInt(match[4] ?? '0');
		this.seconds = parseInt(match[5] ?? '0');
	}
	days: number;
	hours: number;
	minutes: number;
	seconds: number;

	asSeconds = () => {
		return this.seconds + this.minutes * 60 + this.hours * 60 * 60 + this.days * 24 * 60 * 60;
	};
}
