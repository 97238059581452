import _ from 'lodash';
import { IPACSProvider, IPACSSchedule, IPACSValue, PACSKeys, TimeSpan } from 'models';
import { addSeconds, parse, startOfToday, isWithinInterval, getDay } from 'date-fns';

export class PACSService implements IPACSProvider {
	constructor() {}

	build = (source: IPACSValue[]) => {
		this[PACSKeys.ArrivalNoticeEnabled] = this.getBool(source, PACSKeys.ArrivalNoticeEnabled);
		this[PACSKeys.ArrivalNoticeMaxCount] = this.getInt(source, PACSKeys.ArrivalNoticeMaxCount);
		this[PACSKeys.ArrivalNoticeQuantities] = this.getIntArray(source, PACSKeys.ArrivalNoticeQuantities);
		this[PACSKeys.ArrivalNoticeQuantityEnabled] = this.getBool(source, PACSKeys.ArrivalNoticeQuantityEnabled);
		this[PACSKeys.ArrivalNoticeTimeEnabled] = this.getBool(source, PACSKeys.ArrivalNoticeTimeEnabled);
		this[PACSKeys.ArrivalNoticeTimeEndBuffer] = this.getInt(source, PACSKeys.ArrivalNoticeTimeEndBuffer);
		this[PACSKeys.ArrivalNoticeTimes] = this.getInt(source, PACSKeys.ArrivalNoticeTimes);
		this[PACSKeys.ArrivalNoticeTimeStartBuffer] = this.getInt(source, PACSKeys.ArrivalNoticeTimeStartBuffer);
		this[PACSKeys.CanAcceptFutureAppointment] = this.getBool(source, PACSKeys.CanAcceptFutureAppointment);
		this[PACSKeys.EnableSelfGenSalesAppointments] = this.getBool(source, PACSKeys.EnableSelfGenSalesAppointments);
		this[PACSKeys.ExcludeResolutionCodeForSold] = this.getBool(source, PACSKeys.ExcludeResolutionCodeForSold);
		this[PACSKeys.ReferralPromoCode] = this.getString(source, PACSKeys.ReferralPromoCode);
		this[PACSKeys.ReferralProspectEnabled] = this.getBool(source, PACSKeys.ReferralProspectEnabled);
		this[PACSKeys.ReferralSelfGenEnabled] = this.getBool(source, PACSKeys.ReferralSelfGenEnabled);
		this[PACSKeys.RequireLeadCompletion] = this.getBool(source, PACSKeys.RequireLeadCompletion);
		this[PACSKeys.ResolutionBusinessUnitExclusionList] = this.getStringArray(source, PACSKeys.ResolutionBusinessUnitExclusionList);
		this[PACSKeys.ResolutionCodeExclusionSchedule] = this.getSchedule(source, PACSKeys.ResolutionCodeExclusionSchedule);
		this[PACSKeys.ResolutionEnabled] = this.getBool(source, PACSKeys.ResolutionEnabled);
		this[PACSKeys.ResolutionMaskingExclusionSchedule] = this.getSchedule(source, PACSKeys.ResolutionMaskingExclusionSchedule);
		this[PACSKeys.ResolutionMaskingRange] = this.getInt(source, PACSKeys.ResolutionMaskingRange);
		this[PACSKeys.ResolutionSourceExclusionList] = this.getStringArray(source, PACSKeys.ResolutionSourceExclusionList);
		this[PACSKeys.ResolutionStatusExclusionList] = this.getStringArray(source, PACSKeys.ResolutionStatusExclusionList);
		this[PACSKeys.SelfGenMaxDaysOut] = this.getInt(source, PACSKeys.SelfGenMaxDaysOut);
		this[PACSKeys.ShowCustomerPhoneNumbers] = this.getBool(source, PACSKeys.ShowCustomerPhoneNumbers);
	};

	[PACSKeys.ArrivalNoticeEnabled]: boolean;
	[PACSKeys.ArrivalNoticeMaxCount]: number;
	[PACSKeys.ArrivalNoticeQuantities]: number[];
	[PACSKeys.ArrivalNoticeQuantityEnabled]: boolean;
	[PACSKeys.ArrivalNoticeTimeEnabled]: boolean;
	[PACSKeys.ArrivalNoticeTimeEndBuffer]: number;
	[PACSKeys.ArrivalNoticeTimes]: number;
	[PACSKeys.ArrivalNoticeTimeStartBuffer]: number;
	[PACSKeys.CanAcceptFutureAppointment]: boolean;
	[PACSKeys.EnableSelfGenSalesAppointments]: boolean;
	[PACSKeys.ExcludeResolutionCodeForSold]: boolean;
	[PACSKeys.ReferralPromoCode]: string;
	[PACSKeys.ReferralProspectEnabled]: boolean;
	[PACSKeys.ReferralSelfGenEnabled]: boolean;
	[PACSKeys.RequireLeadCompletion]: boolean;
	[PACSKeys.ResolutionBusinessUnitExclusionList]: string[];
	[PACSKeys.ResolutionCodeExclusionSchedule]: IPACSSchedule[];
	[PACSKeys.ResolutionEnabled]: boolean;
	[PACSKeys.ResolutionMaskingExclusionSchedule]: IPACSSchedule[];
	[PACSKeys.ResolutionMaskingRange]: number;
	[PACSKeys.ResolutionSourceExclusionList]: string[];
	[PACSKeys.ResolutionStatusExclusionList]: string[];
	[PACSKeys.SelfGenMaxDaysOut]: number;
	[PACSKeys.ShowCustomerPhoneNumbers]: boolean;

	getIntArray = (source: IPACSValue[], key: string): number[] => {
		const value = _.find(source, _ => _.key === key);
		return JSON.parse(value?.value ?? '[]');
	};

	getStringArray = (source: IPACSValue[], key: string): string[] => {
		const value = _.find(source, _ => _.key === key);
		return JSON.parse(value?.value ?? '[]');
	};

	getSchedule = (source: IPACSValue[], key: string): IPACSSchedule[] => {
		const value = _.find(source, _ => _.key === key);
		return JSON.parse(value?.value ?? '[]');
	};

	getInt = (source: IPACSValue[], key: string): number => {
		const value = _.find(source, _ => _.key === key);
		return parseInt(value?.value ?? '0');
	};

	getBool = (source: IPACSValue[], key: string): boolean => {
		const value = _.find(source, _ => _.key === key);
		return value?.value == 'true';
	};

	getString = (source: IPACSValue[], key: string): string => {
		const value = _.find(source, _ => _.key === key);
		return value?.value;
	};

	checkMaskingSchedule = (date: string, time: string): boolean => {
		return _.some(this[PACSKeys.ResolutionMaskingExclusionSchedule], s => {
			if (getDay(parse(date, 'yyyy-MM-dd', startOfToday())) !== s.dayOfWeek) {
				return false;
			}

			return isWithinInterval(parse(time, 'HH:mm:ss', startOfToday()), {
				start: addSeconds(startOfToday(), new TimeSpan(s.startTime).asSeconds()),
				end: addSeconds(startOfToday(), new TimeSpan(s.endTime).asSeconds())
			});
		});
	};

	checkCodeExclusionSchedule = (date: string, time: string): boolean => {
		return _.some(this[PACSKeys.ResolutionCodeExclusionSchedule], s => {
			if (getDay(parse(date, 'yyyy-MM-dd', startOfToday())) !== s.dayOfWeek) {
				return false;
			}

			return isWithinInterval(parse(time, 'HH:mm:ss', startOfToday()), {
				start: addSeconds(startOfToday(), new TimeSpan(s.startTime).asSeconds()),
				end: addSeconds(startOfToday(), new TimeSpan(s.endTime).asSeconds())
			});
		});
	};
}

export const pacsService = new PACSService();
