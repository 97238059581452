import { AxiosResponse } from 'axios';
import { IQuoteDisposition } from 'models';
import { get } from '../axiosprovider';

const getOpportunityQuote = async (rowId: string): Promise<AxiosResponse<IGetOpportunityQuoteResponse>> => {
	return await get<IGetOpportunityQuoteResponse>(`/api/quotes/${rowId}`);
};

export const quotesController = {
	getOpportunityQuote
};

export interface IGetOpportunityQuoteResponse {
	quoteDisposition: IQuoteDisposition;
}
