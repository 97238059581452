export interface IAnnouncement {
	expirationDate: string;
	effectiveDate: string;
	id: number;
	message: string;
	priority: string;
	read: boolean;
	readDateTime: string | null;
	type: string;
	selected: boolean;
	repId: string;
	modifiedDate: string;
}

export interface IAnnouncementUpdateRequest {
	id: number;
	read: boolean;
}

export enum ENotificationViewType {
	ALL = 'ALL',
	READ = 'READ',
	UNREAD = 'UNREAD'
}
