import { EActionState } from 'models';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/notifications';
import { selectors as appSelectors } from 'store/application';

export const NotificationProvider = () => {
	const dispatch = useDispatch();
	const action = useSelector(selectors.getInitAction);
	const intervalKnob = useSelector(appSelectors.knobs.getNotificationSyncInterval);

	useEffect(() => {
		if (action === EActionState.PENDING) {
			dispatch(actionCreator.INIT_START());
		}
	}, [dispatch, action]);

	useEffect(() => {
		if (action === EActionState.COMPLETED) {
			const intervalId = setInterval(() => {
				dispatch(actionCreator.LOAD_START());
			}, intervalKnob * 1000);

			return () => clearInterval(intervalId);
		}
	});

	return null;
};
