import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EActionState, ECustomerSearchResultSortField, ESortDirection, ICustomerSearchResult } from 'models';
import { customerService } from 'services';
import { resetState } from 'store/common/slice';
import { initialState } from './state';

const slice = createSlice({
	name: '@@customers/search',
	initialState,
	reducers: {
		INIT_START: state => {
			state.init = EActionState.RUNNING;
		},
		INIT_COMPLETED: state => {
			state.init = EActionState.COMPLETED;
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
		},
		INIT_FLUSH: state => {
			resetState(state, initialState);
		},
		LOAD_START: state => {
			state.load = EActionState.RUNNING;
		},
		LOAD_COMPLETED: (
			state,
			action: PayloadAction<{
				results: ICustomerSearchResult[];
			}>
		) => {
			state.load = EActionState.COMPLETED;
			state.results = action.payload.results;
			customerService.applySort(state);
		},
		LOAD_FAILED: state => {
			state.load = EActionState.FAILED;
		},
		SET_SORT: (state, action: PayloadAction<ECustomerSearchResultSortField>) => {
			if (state.sortField === action.payload) {
				state.sortDirection = state.sortDirection === ESortDirection.ASC ? ESortDirection.DESC : ESortDirection.ASC;
			} else {
				state.sortField = action.payload;
				state.sortDirection = ESortDirection.ASC;
			}
			customerService.applySort(state);
		},
		SET_FILTER_SEARCH_FILTER: (state, action: PayloadAction<string>) => {
			state.filterModal.searchFilter = action.payload;
		},
		SET_FILTER_MODAL_OPEN: (state, action: PayloadAction<boolean>) => {
			state.filterModal.open = action.payload;
			state.filterModal.searchFilter = state.searchFilter;
			state.filterModal.dateRange = state.dateRange;
			state.filterModal.sortField = state.sortField;
			state.filterModal.sortDirection = state.sortDirection;
		},
		SET_FILTERS: state => {
			state.filterModal.open = false;
			state.searchFilter = state.filterModal.searchFilter;
			state.dateRange = state.filterModal.dateRange;
			state.sortField = state.filterModal.sortField;
			state.sortDirection = state.filterModal.sortDirection;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
