import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

export interface IScreenType {
	isDesktop: boolean;
	isPhone: boolean;
}

export const useScreenType = (): IScreenType => {
	const theme = useTheme();
	const isMdDown = useMediaQuery(theme.breakpoints.down('md'));

	return {
		isDesktop: !isMdDown,
		isPhone: isMdDown
	};
};
