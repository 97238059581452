import { Dialog, DialogContent, DialogContentText, LinearProgress } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectors } from 'store/messaging';

export const ProgressManager = () => {
	const progress = useSelector(selectors.getProgress);
	if (progress === null || progress === undefined) return null;

	const handleClose = (event: any, reason: string) => {
		if (reason === 'backdropClick') {
			return;
		}
	};

	return (
		<Dialog fullWidth maxWidth={'md'} key={progress.id} onClose={handleClose} disableEscapeKeyDown open={true}>
			<DialogContent>
				<DialogContentText
					sx={{
						display: 'flex',
						alignItems: 'center'
					}}>
					{progress.title}
					{progress.value !== 0 && `${(progress.value * 100).toFixed(0)}%`}
				</DialogContentText>
				{progress.value === 0 && <LinearProgress variant='indeterminate' />}
				{progress.value > 0 && <LinearProgress variant='determinate' value={progress.value * 100.0} />}
			</DialogContent>
		</Dialog>
	);
};
