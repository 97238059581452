import { IGlobalState } from 'store';

const getDrawerOpen = (state: IGlobalState) => state.application.drawer.open;
const getInitAction = (state: IGlobalState) => state.application.init;
const getConnectionString = (state: IGlobalState) => state.application.connectionString;
const getAppInsightsClientConfiguration = (state: IGlobalState) => state.application.appInsightsClientConfiguration;
const getBuildVersion = (state: IGlobalState) => state.application.buildVersion;
const getVersionCheckInterval = (state: IGlobalState) => state.application.versionCheckInterval;
const getPageTitle = (state: IGlobalState) => state.application.pageTitle;
const map = {
	getKey: (state: IGlobalState) => state.application.map.key
};
const urls = {
	getPRPT: (state: IGlobalState) => state.application.urls.prpt,
	getPCOF: (state: IGlobalState) => state.application.urls.pcof,
	getHelp: (state: IGlobalState) => state.application.urls.help,
	getBridge: (state: IGlobalState) => state.application.urls.bridge
};

const knobs = {
	getNotificationSyncInterval: (state: IGlobalState) => state.application.knobs.notificationSyncInterval,
	getNotificationSyncBuffer: (state: IGlobalState) => state.application.knobs.notificationSyncBuffer,
	getAppointmentSyncInterval: (state: IGlobalState) => state.application.knobs.appointmentSyncInterval,
	getAppointmentSyncBuffer: (state: IGlobalState) => state.application.knobs.appointmentSyncBuffer,
	getAppointmentSyncTimer: (state: IGlobalState) => state.application.knobs.appointmentSyncTimer
};

export const selectors = {
	getDrawerOpen,
	getInitAction,
	getConnectionString,
	getAppInsightsClientConfiguration,
	getPageTitle,
	getBuildVersion,
	getVersionCheckInterval,
	knobs,
	map,
	urls
};
