import { AppInsightsContext, ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights, IApplicationInsights } from '@microsoft/applicationinsights-web';
import history from 'history/browser';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectors } from 'store/application';
import { useAuth } from 'auth';
import { IAppInsightsClientConfiguration } from 'models';

export let appInsights: IApplicationInsights | null = null;

export const reactPlugin = new ReactPlugin();

const createAppInsights = (connectionString: string, config: IAppInsightsClientConfiguration): IApplicationInsights => {
	return new ApplicationInsights({
		config: {
			connectionString: connectionString,
			extensions: [reactPlugin],
			extensionConfig: {
				[reactPlugin.identifier]: { history: history }
			},
			disableAjaxTracking: config.disableAjaxTracking,
			disableExceptionTracking: config.disableExceptionTracking,
			disableFetchTracking: config.disableFetchTracking,
			disableTelemetry: config.disableTelemetry,
			enableAutoRouteTracking: config.enableAutoRouteTracking
		}
	}).loadAppInsights();
};

export const ApplicationInsightsProvider = (props: { children: any }) => {
	const iKey = useSelector(selectors.getConnectionString);
	const config = useSelector(selectors.getAppInsightsClientConfiguration);
	const auth = useAuth();

	useEffect(() => {
		if (iKey) {
			appInsights = createAppInsights(iKey, config);
			appInsights.setAuthenticatedUserContext(auth.currentAccount?.homeAccountId ?? '', auth.currentAccount?.username ?? '');
		}
	}, [iKey]);

	return <AppInsightsContext.Provider value={reactPlugin}>{props.children}</AppInsightsContext.Provider>;
};
