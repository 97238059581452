export const isNullOrWhiteSpace = (input: string | null | undefined): boolean => {
	return !input || !input.trim();
};

export const isJsonStringArray = (input: string): boolean => {
	if (typeof input !== 'string') return false;
	try {
		const result = JSON.parse(input);
		const type = Object.prototype.toString.call(result);
		return type === '[object Array]';
	} catch (err) {
		return false;
	}
};

export const isLettersAndNumbersOnly = (input: string): boolean => {
	if (isNullOrWhiteSpace(input)) return true;
	return Boolean(input.match(/^[A-Za-z0-9 .']+$/));
};
