import { api } from 'api/api';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { selectors as authSelectors } from 'store/auth';
import { handleSagaError } from 'store/common';
import { selectors } from './selectors';
import { actionCreator } from './slice';
import { ISiebelSalesRep } from 'models';
import { IReferralProspectFormState } from 'models/referralprospect';
import { IReferralProspectRequest } from 'api/referralprospect/controller';
import { referralProspectService } from 'services/referralprospectservice';
import { messagingService } from 'services';
import { pacsService } from 'services';
import { push } from 'redux-first-history';
import { actionCreator as selfGenActionCreator } from 'store/selfgen';

export function* saga(): any {
	yield takeLatest(actionCreator.SUBMIT_REFERRAL_START, onSubmitReferralStart);
	yield takeLatest(actionCreator.CREATE_SELF_GEN_REFERRAL, onCreateSelfGenReferral);
}

function* onSubmitReferralStart(): any {
	try {
		const rep: ISiebelSalesRep | null = yield select(authSelectors.getSalesRep);
		if (!rep) throw 'Profile Not Found';
		const state: IReferralProspectFormState = yield select(selectors.form.get);
		const company: string = yield select(authSelectors.salesRep.getCompany);
		const request: IReferralProspectRequest = referralProspectService.createRequest(state, company, rep);
		yield api.referralProspect.PostReferralProspect(request);
		messagingService.popSnackbar('Referral Prospect Submitted', 'success');
		yield all([put(actionCreator.SET_FORM_CLOSE()), put(actionCreator.SUBMIT_REFERRAL_COMPLETED())]);
	} catch (error) {
		yield handleSagaError(error, [actionCreator.SUBMIT_REFERRAL_FAILED()]);
	}
}

function* onCreateSelfGenReferral(): any {
	const account = yield select(selectors.form.formData.getAccountId);
	yield all([put(selfGenActionCreator.SET_REFERRAL({ account: account, promo: pacsService.ReferralPromoCode })), put(push('/selfgen'))]);
}
