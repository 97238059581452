import { IDialog, IMessage, IProgress } from './models';

export interface IState {
	messages: IMessage[];
	dialogs: IDialog[];
	progress: IProgress | null;
}
export const initialState: IState = {
	messages: [],
	dialogs: [],
	progress: null
};
