import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { useScreenType } from 'hooks';
import { forwardRef } from 'react';

export const DialogTransition = forwardRef(function Transition(
	props: TransitionProps & {
		children: React.ReactElement<any, any>;
	},
	ref: React.Ref<unknown>
) {
	const screenType = useScreenType();
	return <Slide direction={screenType.isPhone ? 'left' : 'up'} ref={ref} {...props} />;
});
