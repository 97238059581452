import _ from 'lodash';
import { IGlobalState } from 'store';
import { IMessage, IDialog, IProgress } from './models';

const getNextMessage = (state: IGlobalState): IMessage | undefined => _.first(state.messaging.messages);
const getNextDialog = (state: IGlobalState): IDialog | undefined => _.first(state.messaging.dialogs);
const getProgress = (state: IGlobalState): IProgress | null => state.messaging.progress;

export const selectors = {
	getNextMessage,
	getNextDialog,
	getProgress
};
