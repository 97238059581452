import { ERole } from 'auth';
import { LoaderBackdrop, Page } from 'components';
import { lazy, Suspense } from 'react';
import { Outlet, Route, Navigate } from 'react-router';

const CreateImport = lazy(() => import('./create/main'));

const Create = <Page title='Create Self Gen' component={CreateImport} allowedRoles={[ERole.ROLE_OSE]} />;

export const SelfGenRoutes = (
	<Route path='selfgen' element={<Outlet />}>
		<Route path='create' element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Create}</Suspense>} />
		<Route index element={<Navigate to='/selfgen/create' />} />
	</Route>
);
