import { EActionState, IAppointmentCombinedState } from 'models';

export interface IState {
	init: EActionState;
	load: EActionState;
	todayAppointmentIds: string[];
	futureAppointmentIds: string[];
	appointments: IAppointmentCombinedState[];
	showFutureLock: boolean;
	showRequireLeadCompletion: boolean;
}

export const initialState: IState = {
	init: EActionState.PENDING,
	load: EActionState.PENDING,
	todayAppointmentIds: [],
	futureAppointmentIds: [],
	appointments: [],
	showFutureLock: false,
	showRequireLeadCompletion: false
};
