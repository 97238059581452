import { EAuthError } from 'auth';
import { api } from 'api/api';
import { isNullOrWhiteSpace } from 'coreutil';
import { ISiebelSalesRep } from 'models';
import { push } from 'redux-first-history';
import { put, takeLatest } from 'redux-saga/effects';
import { handleSagaError } from 'store/common';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeLatest(actionCreator.AUTH_START, onAuthStart);
}

function* onAuthStart(action: ReturnType<typeof actionCreator.AUTH_START>): any {
	try {
		const profile: ISiebelSalesRep = yield api.profile.getProfile();
		yield put(
			actionCreator.AUTH_COMPLETED({
				salesRep: profile
			})
		);
		if (!isNullOrWhiteSpace(action.payload.redirectUrl)) {
			yield put(push(action.payload.redirectUrl));
		}
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.AUTH_FAILED({ error: EAuthError.SAGA_FAILURE })]);
	}
}
