import { IReferralProspectRequest } from 'api/referralprospect/controller';
import { emailUtil, isLettersAndNumbersOnly, isNullOrWhiteSpace, phoneUtil } from 'coreutil';
import { IFormValue, ISiebelSalesRep } from 'models';
import { IReferralProspectFormState } from 'models/referralprospect';

const createRequest = (state: IReferralProspectFormState, company: string, profile: ISiebelSalesRep): IReferralProspectRequest => {
	return {
		company: company,
		emailAddress: state.formData.emailAddress.newValue,
		firstName: state.formData.firstName.newValue,
		lastName: state.formData.lastName.newValue,
		location: state.formData.location.newValue,
		notes: state.formData.notes.newValue,
		phone: state.formData.phone.newValue,
		referringAccountId: state.formData.accountId,
		referringOptyNum: state.formData.opportunityId,
		salesRepId: profile.repId
	};
};

const resetErrorState = (state: IFormValue<any>) => {
	state.error = false;
	state.message = '';
};

const setEnabledState = (state: IReferralProspectFormState) => {
	state.submit.enabled =
		!state.formData.firstName.error &&
		!state.formData.lastName.error &&
		!state.formData.emailAddress.error &&
		!state.formData.phone.error &&
		!state.formData.location.error &&
		(!isNullOrWhiteSpace(state.formData.emailAddress.newValue) || !isNullOrWhiteSpace(state.formData.phone.newValue));
};

const setErrorState = (state: IReferralProspectFormState) => {
	resetErrorState(state.formData.firstName);
	resetErrorState(state.formData.lastName);
	resetErrorState(state.formData.phone);
	resetErrorState(state.formData.emailAddress);
	resetErrorState(state.formData.location);

	setRequiredStringErrorState(state.formData.firstName, true);
	setRequiredStringErrorState(state.formData.lastName, true);
	setPhoneNumberErrorState(state.formData.phone);
	setEmailAddressErrorState(state.formData.emailAddress);
	setRequiredStringErrorState(state.formData.location, true);
};

const setPhoneNumberErrorState = (state: IFormValue<string>) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		return;
	}
	if (state.newValue.length > 10) {
		state.error = true;
		state.message = 'Invalid Phone Number';
		return;
	}
	if (!phoneUtil.isValidPhoneNumber(state.newValue)) {
		state.error = true;
		state.message = 'Invalid Phone Number';
		return;
	}
};

const setEmailAddressErrorState = (state: IFormValue<string>) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		return;
	}
	if (!emailUtil.isValidEmailAddress(state.newValue)) {
		state.error = true;
		state.message = 'Invalid Email Address';
		return;
	}
};

const setRequiredStringErrorState = (state: IFormValue<string>, alphanumeric: boolean) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		state.error = true;
		state.message = 'Required';
		return;
	}
	if (alphanumeric && !isLettersAndNumbersOnly(state.newValue)) {
		state.error = true;
		state.message = 'Alphanumeric Only';
		return;
	}
};

export const referralProspectService = {
	createRequest,
	setEnabledState,
	setErrorState
};
