import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.appointments.search.init,
	getLoadAction: (state: IGlobalState) => state.appointments.search.load,
	getSearchTerm: (state: IGlobalState) => state.appointments.search.searchTerm,
	getDateRange: (state: IGlobalState) => state.appointments.search.dateRange,
	getResults: (state: IGlobalState) => state.appointments.search.results,
	filters: {
		getOpen: (state: IGlobalState) => state.appointments.search.filters.open,
		getSearchTerm: (state: IGlobalState) => state.appointments.search.filters.searchTerm,
		getDateRange: (state: IGlobalState) => state.appointments.search.filters.dateRange
	}
};
