import { IGlobalState } from 'store';

const getIsAuthenticated = (state: IGlobalState) => state.auth.isAuthenticated;
const getAuthError = (state: IGlobalState) => state.auth.error;
const getSalesRep = (state: IGlobalState) => state.auth.salesRep;
const getIdToken = (state: IGlobalState) => state.auth.idToken;
const getRoles = (state: IGlobalState) => state.auth.roles;

const salesRep = {
	getCompany: (state: IGlobalState) => state.auth.salesRep?.company,
	getRepId: (state: IGlobalState) => state.auth.salesRep?.repId
};

export const selectors = {
	getIsAuthenticated,
	getAuthError,
	getSalesRep,
	getRoles,
	getIdToken,
	salesRep
};
