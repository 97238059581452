import { mdiCheck } from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, ToggleButton, ToggleButtonProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { AnimatedProps, animated } from '@react-spring/web';
import { useScreenType } from 'hooks';

const Container = styled(animated.div, { shouldForwardProp: prop => prop !== 'fullWidth' })<{ fullWidth?: boolean }>(({ theme, fullWidth }) => {
	const screenType = useScreenType();
	return {
		maxWidth: fullWidth ? '99%' : '48%',
		width: fullWidth ? '99%' : 200,
		minWidth: fullWidth ? (screenType.isPhone ? 150 : 180) : screenType.isPhone ? 150 : 180,
		flexGrow: 1,
		'& .MuiToggleButton-root': {
			width: '100%',
			flexGrow: 1,
			'&:not(.Mui-selected)': {
				margin: '1px'
			},
			'&.Mui-selected': {
				borderColor: theme.palette.primary.main,
				borderWidth: '2px',
				'&>.MuiBox-root': {
					paddingLeft: '1.25px'
				}
			}
		}
	};
});

export interface IItemSelectButtonProps extends ToggleButtonProps {
	animatedProps?: AnimatedProps<{ [x: string]: any }>;
}

export const ItemSelectButton = (props: IItemSelectButtonProps) => {
	const { fullWidth, selected, children } = props;
	const { animatedProps, ...toggleButtonProps } = props;
	return (
		<Container fullWidth={fullWidth} {...animatedProps}>
			<ToggleButton fullWidth {...toggleButtonProps}>
				<Box sx={{ position: 'relative', width: '100%' }}>
					{selected ? (
						<Box>
							<Box sx={{ position: 'absolute', left: 0, top: 0, bottom: 0 }}>
								<Icon path={mdiCheck} size={1} />
							</Box>
							<Typography variant='subtitle2' noWrap sx={{ marginLeft: '24px' }}>
								{children}
							</Typography>
						</Box>
					) : (
						<Typography variant='subtitle2' noWrap>
							{children}
						</Typography>
					)}
				</Box>
			</ToggleButton>
		</Container>
	);
};
