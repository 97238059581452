import { Box, Stack } from '@mui/material';
import { FutureAppointments } from './futureappointments';
import { NotificationBanner } from './notificationbanner';
import { RequireLeadCompletionBanner } from './requireleadcompletionbanner';
import { TodaysAppointments } from './todaysappointments';

export const View = () => {
	return (
		<Stack spacing={0} sx={{ p: 2 }}>
			<NotificationBanner />
			<Box>
				<Stack>
					<RequireLeadCompletionBanner />
					<TodaysAppointments />
					<FutureAppointments />
				</Stack>
			</Box>
		</Stack>
	);
};
