import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	AppBar,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	Stack,
	Tab,
	Tabs,
	Toolbar,
	Typography
} from '@mui/material';
import { DialogTransition, ItemSelectButton } from 'components';
import { isNullOrWhiteSpace } from 'coreutil';
import { useScreenType } from 'hooks';
import { EActionState, EArrivalNoticeMethod, IArrivalNoticeQuantity, IArrivalNoticeTime } from 'models';
import { useDispatch, useSelector } from 'react-redux';
import { animationService } from 'services';
import { actionCreator, selectors } from 'store/arrivalnotice';
import { theme } from 'theme';

export const ArrivalNoticeDialog = () => {
	const dispatch = useDispatch();
	const loading = useSelector(selectors.getInitAction);
	const open = useSelector(selectors.getOpen);
	const screenType = useScreenType();
	const handleCancel = () => {
		dispatch(actionCreator.INIT_FLUSH());
	};

	return (
		<Dialog
			open={open}
			fullScreen={screenType.isPhone}
			onClose={handleCancel}
			fullWidth={true}
			maxWidth={'sm'}
			TransitionComponent={DialogTransition}
			transitionDuration={{
				appear: theme.transitions.duration.enteringScreen,
				enter: theme.transitions.duration.enteringScreen,
				exit: 0
			}}>
			{screenType.isPhone && <TitleBar title='Arrival Notice' onClose={handleCancel} />}
			{screenType.isDesktop && <DialogTitle>Arrival Notice</DialogTitle>}
			<DialogContent sx={{ p: 0 }}>
				{loading === EActionState.RUNNING && <LinearProgress variant='indeterminate' />}
				{loading === EActionState.COMPLETED && <ArrivalNoticeContent />}
			</DialogContent>
			<DialogActions>
				<SendButton />
				<Button color='neutral' variant='outlined' onClick={handleCancel} sx={{ flex: screenType.isPhone ? '1 1 0px' : 'inherit' }}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const SendButton = () => {
	const dispatch = useDispatch();
	const sendAction = useSelector(selectors.getSendAction);
	const sendEnabled = useSelector(selectors.getSendEnabled);
	const method = useSelector(selectors.getMethod);
	const screenType = useScreenType();
	const handleClick = () => {
		switch (method) {
			case EArrivalNoticeMethod.QUANTITY: {
				dispatch(actionCreator.SEND_QUANTITY_START());
				break;
			}
			case EArrivalNoticeMethod.TIME: {
				dispatch(actionCreator.SEND_TIME_START());
				break;
			}
		}
	};
	return (
		<LoadingButton
			color='primary'
			variant='contained'
			onClick={handleClick}
			disabled={!sendEnabled}
			loading={sendAction === EActionState.RUNNING}
			sx={{ flex: screenType.isPhone ? '1 1 0px' : 'inherit' }}>
			Send
		</LoadingButton>
	);
};

const TitleBar = (props: { title: string; onClose: () => void }) => {
	const { title, onClose } = props;
	return (
		<AppBar position='static' color='inherit'>
			<Toolbar>
				<IconButton color='inherit' onClick={onClose}>
					<ArrowBackIcon />
				</IconButton>
				<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
					{title}
				</Typography>
			</Toolbar>
			<MethodTabs />
		</AppBar>
	);
};

const ArrivalNoticeContent = () => {
	const screenType = useScreenType();
	return (
		<>
			{screenType.isDesktop && <MethodTabs />}
			<MethodSelector />
			<ResultMessage />
		</>
	);
};

const ResultMessage = () => {
	const message = useSelector(selectors.getResultMessage);
	if (isNullOrWhiteSpace(message)) return null;
	return <Alert severity='warning'>{message}</Alert>;
};

const MethodSelector = () => {
	const method = useSelector(selectors.getMethod);
	switch (method) {
		case EArrivalNoticeMethod.QUANTITY:
			return <QuantitySelection />;
		case EArrivalNoticeMethod.TIME:
			return <TimeSelection />;
		default:
			return null;
	}
};

const QuantitySelection = () => {
	const dispatch = useDispatch();
	const quantities = useSelector(selectors.getQuantities);
	const selectedQuantity = useSelector(selectors.getSelectedQuantity);
	const screenType = useScreenType();
	const handleChange = (_: any, value: IArrivalNoticeQuantity) => {
		if (value) {
			dispatch(actionCreator.SET_QUANTITY(value));
		}
	};
	return (
		<Stack sx={{ overflowX: 'hidden' }}>
			<Stack spacing={1} direction='row' sx={{ m: 2, mt: 3, flex: 1, overflowX: 'hidden' }} useFlexGap flexWrap='wrap'>
				<Box sx={{ width: '100%', textAlign: 'center', mb: 1 }}>
					<Typography variant='body1' component='span'>
						I will arrive in:
					</Typography>
					{selectedQuantity && (
						<Typography variant='body1' component='span' sx={{ fontWeight: 'bold' }}>
							{' '}
							{selectedQuantity.display}
						</Typography>
					)}
				</Box>
				{animationService.getStaggeredSelectButtonTrail(quantities.length).map(({ height, ...style }, index) => {
					const q = quantities[index];
					return (
						<ItemSelectButton
							key={q.minutes}
							animatedProps={{ style }}
							selected={selectedQuantity === q}
							value={q}
							onChange={handleChange}
							color='primary'
							fullWidth={screenType.isPhone}>
							{q.display}
						</ItemSelectButton>
					);
				})}
			</Stack>
		</Stack>
	);
};

const TimeSelection = () => {
	const dispatch = useDispatch();
	const times = useSelector(selectors.getTimes);
	const selectedTime = useSelector(selectors.getSelectedTime);
	const screenType = useScreenType();
	const handleChange = (_: any, value: IArrivalNoticeTime) => {
		if (value) {
			dispatch(actionCreator.SET_TIME(value));
		}
	};
	return (
		<Stack sx={{ overflowX: 'hidden' }}>
			<Stack spacing={1} direction='row' sx={{ m: 2, mt: 3, flex: 1, overflowX: 'hidden' }} useFlexGap flexWrap='wrap'>
				<Box sx={{ width: '100%', textAlign: 'center', mb: 1 }}>
					<Typography variant='body1' component='span'>
						I will arrive at:
					</Typography>
					{selectedTime && (
						<Typography variant='body1' component='span' sx={{ fontWeight: 'bold' }}>
							{' '}
							{selectedTime.display}
						</Typography>
					)}
				</Box>
				{animationService.getStaggeredSelectButtonTrail(times.length).map(({ height, ...style }, index) => {
					const i = times[index];
					return (
						<ItemSelectButton
							key={i.value}
							animatedProps={{ style }}
							selected={selectedTime === i}
							value={i}
							onChange={handleChange}
							color='primary'
							fullWidth={screenType.isPhone}>
							{i.display}
						</ItemSelectButton>
					);
				})}
			</Stack>
		</Stack>
	);
};

const MethodTabs = () => {
	const dispatch = useDispatch();
	const method = useSelector(selectors.getMethod);
	const quantityEnabled = useSelector(selectors.getQuantityEnabled);
	const timeEnabled = useSelector(selectors.getTimeEnabled);
	const handleChange = (_event, method: EArrivalNoticeMethod) => {
		dispatch(actionCreator.SET_METHOD(method));
	};
	return (
		<Tabs value={method} onChange={handleChange} variant='fullWidth'>
			{quantityEnabled && <Tab label='Duration' value={EArrivalNoticeMethod.QUANTITY} />}
			{timeEnabled && <Tab label='Exact Time' value={EArrivalNoticeMethod.TIME} />}
		</Tabs>
	);
};
