import { EOrderStatus } from 'models';
import { EProjectStatus } from 'models/project';

export const getStatusColor = (status: EProjectStatus | EOrderStatus) => {
	switch (status) {
		case EProjectStatus.CancelPending || EOrderStatus.CancelPending:
		case EProjectStatus.Cancelled || EOrderStatus.Cancelled:
		case EProjectStatus.Deleted || EOrderStatus.Deleted:
			return '#CC0000';
		case EProjectStatus.Open || EOrderStatus.Open:
			return 'green';
		default:
			return 'black';
	}
};
