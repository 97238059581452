import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/messaging';

export const DialogManager = () => {
	const dispatch = useDispatch();
	const dialog = useSelector(selectors.getNextDialog);
	if (dialog === null || dialog === undefined) return null;
	const handleOk = () => {
		dispatch(dialog.okAction);
		dispatch(actionCreator.DISMISS_DIALOG(dialog.id));
	};
	const handleCancel = () => {
		if (dialog.cancelAction !== null) {
			dispatch(dialog.cancelAction);
		}

		dispatch(actionCreator.DISMISS_DIALOG(dialog.id));
	};

	const handleClose = (event: any, reason: string) => {
		if (reason === 'backdropClick') {
			return;
		}
	};

	return (
		<Dialog fullWidth maxWidth={'md'} onClose={handleClose} disableEscapeKeyDown open={true}>
			<DialogTitle>{dialog.title}</DialogTitle>
			<DialogContent>
				<DialogContentText
					sx={{
						display: 'flex',
						alignItems: 'center'
					}}>
					{dialog.message}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleOk} color='primary' variant='contained'>
					Ok
				</Button>
				{dialog.allowCancel && (
					<Button onClick={handleCancel} color='primary' variant='text'>
						Cancel
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};
