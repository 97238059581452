import { api } from 'api/api';
import { EMobileStatus, IAppointment } from 'models';
import { all, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { appointmentService, dateService } from 'services';
import { handleSagaError } from 'store/common';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.LOAD_START, onLoadStart);
	yield takeEvery(actionCreator.ACCEPT_START, onAcceptStart);
}

function* onInitStart(): any {
	try {
		yield all([put(actionCreator.INIT_COMPLETED()), put(actionCreator.LOAD_START())]);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onLoadStart(): any {
	try {
		const today: string = dateService.getToday();
		const appointments: IAppointment[] = yield api.appointments.getForHome(today);
		const appointmentStates = appointmentService.buildStateModels(appointments);
		yield put(
			actionCreator.LOAD_COMPLETED({
				appointments: appointmentStates
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_FAILED()]);
	}
}

function* onAcceptStart(action: ReturnType<typeof actionCreator.ACCEPT_START>): any {
	try {
		const appointment: IAppointment = yield api.appointments.updateMobileStatus({
			rowId: action.payload.rowId,
			mobileStatus: EMobileStatus.ACCEPTED
		});
		const appointmentState = appointmentService.buildStateModel(appointment);
		yield put(
			actionCreator.ACCEPT_COMPLETED({
				rowId: action.payload.rowId,
				appointment: appointmentState
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [
			actionCreator.ACCEPT_FAILED({
				rowId: action.payload.rowId
			})
		]);
	}
}
