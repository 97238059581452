import { Alert } from '@mui/material';
import { selectors } from 'store/home';
import { useSelector } from 'react-redux';

export const RequireLeadCompletionBanner = () => {
	const showBanner = useSelector(selectors.getShowRequireLeadCompletion);
	if (showBanner) {
		return (
			<Alert
				sx={{
					mb: 1
				}}
				severity='warning'>
				You have appointments from a previous day which require your attention before accepting new leads.
			</Alert>
		);
	}
	return null;
};
