import { AxiosResponse } from 'axios';
import { get } from '../axiosprovider';
import { IListValue } from 'models';

const getByValueType = async (valueType: string): Promise<AxiosResponse<Array<IListValue>>> => {
	return await get<Array<IListValue>>(`/api/lov/${valueType}`);
};

const getByValueTypeCompositeKey = async (valueType: string, compositeKey: string): Promise<AxiosResponse<Array<IListValue>>> => {
	return await get<Array<IListValue>>(`/api/lov/${valueType}/${compositeKey}`);
};

const getDisplayByValueType = async (valueType: string): Promise<AxiosResponse<Array<string>>> => {
	return await get<Array<string>>(`/api/lov/display/${valueType}`);
};

const getValueByValueType = async (valueType: string): Promise<AxiosResponse<Array<string>>> => {
	return await get<Array<string>>(`/api/lov/value/${valueType}`);
};

export const lovController = {
	getByValueType,
	getByValueTypeCompositeKey,
	getDisplayByValueType,
	getValueByValueType
};
