import { IAppointment, IAppointmentSearchResult } from 'models';

export const MASK_CUSTOMER_FIRST_NAME = 'Customer';
export const MASK_CUSTOMER_LAST_NAME = 'Name';
export const MASK_CUSTOMER_NAME = `${MASK_CUSTOMER_FIRST_NAME} ${MASK_CUSTOMER_LAST_NAME}`;
export const MASK_PHONE = '(555) 555-5555';
export const MASK_EMAIL = 'email@email.com';
export const MASK_ACTIVITY_ID = '1-123456789';
export const MASK_APPOINTMENT_TYPE = 'xxxxx xxxxxxx';
export const MASK_ADDRESS_LINE1 = '333 Northwest Ave.';
export const MASK_ADDRESS_LINE2 = 'Unit 1';
export const MASK_STREET_ADDRESS = `${MASK_ADDRESS_LINE1} ${MASK_ADDRESS_LINE2}`;
export const MASK_ADDRESS = `${MASK_STREET_ADDRESS} Northlake, IL 60164`;
export const MASK_LATITUDE = 41.91084; // Northlake office
export const MASK_LONGITUDE = -87.91753; // Northlake office

export const maskAppointment = (appointment: IAppointment) => {
	const { isMasked, isPhoneHidden } = appointment;
	return {
		...appointment,
		activityId: isMasked ? MASK_ACTIVITY_ID : appointment.activityId,
		type: isMasked ? MASK_APPOINTMENT_TYPE : appointment.type,
		addressLine1: isMasked ? MASK_ADDRESS_LINE1 : appointment.addressLine1,
		addressLine2: isMasked ? MASK_ADDRESS_LINE2 : appointment.addressLine2,
		displayAddress: isMasked ? MASK_ADDRESS : appointment.displayAddress,
		displayStreetAddress: isMasked ? MASK_STREET_ADDRESS : appointment.displayAddress,
		primaryContactAlternatePhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.primaryContactAlternatePhoneNumber,
		primaryContactBusinessPhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.primaryContactBusinessPhoneNumber,
		primaryContactEmailAddress: isMasked ? MASK_EMAIL : appointment.primaryContactEmailAddress,
		primaryContactFirstName: isMasked ? MASK_CUSTOMER_FIRST_NAME : appointment.primaryContactFirstName,
		primaryContactLastName: isMasked ? MASK_CUSTOMER_LAST_NAME : appointment.primaryContactLastName,
		primaryContactPrimaryPhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.primaryContactPrimaryPhoneNumber,
		secondaryContactAlternatePhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.secondaryContactAlternatePhoneNumber,
		secondaryContactBusinessPhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.secondaryContactBusinessPhoneNumber,
		secondaryContactEmailAddress: isMasked ? MASK_EMAIL : appointment.secondaryContactEmailAddress,
		secondaryContactFirstName: isMasked ? MASK_CUSTOMER_FIRST_NAME : appointment.secondaryContactFirstName,
		secondaryContactLastName: isMasked ? MASK_CUSTOMER_LAST_NAME : appointment.secondaryContactLastName,
		secondaryContactPrimaryPhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.secondaryContactPrimaryPhoneNumber,
		latitude: isMasked ? MASK_LATITUDE : appointment.latitude,
		longitude: isMasked ? MASK_LONGITUDE : appointment.longitude
	};
};

export const maskAppointmentSearchResult = (appointment: IAppointmentSearchResult) => {
	const { isMasked, isPhoneHidden } = appointment;
	return {
		...appointment,
		activityId: isMasked ? MASK_ACTIVITY_ID : appointment.activityId,
		type: isMasked ? MASK_APPOINTMENT_TYPE : appointment.type,
		addressLine1: isMasked ? MASK_ADDRESS_LINE1 : appointment.addressLine1,
		addressLine2: isMasked ? MASK_ADDRESS_LINE2 : appointment.addressLine2,
		displayAddress: isMasked ? MASK_ADDRESS : appointment.displayAddress,
		displayStreetAddress: isMasked ? MASK_STREET_ADDRESS : appointment.displayAddress,
		firstName: isMasked ? MASK_CUSTOMER_FIRST_NAME : appointment.firstName,
		lastName: isMasked ? MASK_CUSTOMER_LAST_NAME : appointment.lastName,
		primaryPhoneNumber: isMasked || isPhoneHidden ? MASK_PHONE : appointment.primaryPhoneNumber
	};
};

export const maskingService = {
	maskAppointment,
	maskAppointmentSearchResult
};
