import { AxiosResponse } from 'axios';
import { IAnnouncement, IAnnouncementUpdateRequest } from 'models';
import { get, post } from '../axiosprovider';

const getLegacyAnnouncementsAsync = async (sinceDate: string): Promise<AxiosResponse<IAnnouncement[]>> => {
	return await get<IAnnouncement[]>(`/api/notifications/legacy?sinceDate=${sinceDate}`);
};

const updateLegacyAnnouncementAsync = async (request: IAnnouncementUpdateRequest): Promise<AxiosResponse<IAnnouncement>> => {
	return await post<IAnnouncement>(`/api/notifications/legacy/${request.id}/${request.read}`);
};

const updateLegacyAnnouncementsAsync = async (request: IAnnouncementUpdateRequest[]): Promise<AxiosResponse<IAnnouncement[]>> => {
	return await post<IAnnouncement[]>('/api/notifications/legacy', request);
};

export const notificationsController = {
	getLegacyAnnouncementsAsync,
	updateLegacyAnnouncementAsync,
	updateLegacyAnnouncementsAsync
};
