import { IApiError } from 'api/common';
import { getAccessToken } from 'auth/service';
import axios, { AxiosInstance, AxiosResponse } from 'axios';

const createInstance = async (): Promise<AxiosInstance> => {
	const accessToken = await getAccessToken();
	return axios.create({
		headers: {
			Authorization: `Bearer ${accessToken}`
		}
	});
};

export const get = async <T>(url: string): Promise<AxiosResponse<T>> => {
	const instance = await createInstance();
	return new Promise(async (resolve, reject) => {
		await instance
			.get(url)
			.then(response => resolve(response.data))
			.catch(error => {
				if (error.response) {
					reject({
						message: error.message,
						stack: error.message,
						statusCode: error.response.status,
						statusText: error.response.statusText,
						data: error.response.data
					} as IApiError);
				} else {
					reject({
						message: error.message,
						stack: error.stack,
						statusCode: null,
						statusText: null,
						data: null
					} as IApiError);
				}
			});
	});
};

export const post = async <T>(url: string, payload?: any): Promise<AxiosResponse<T>> => {
	const instance = await createInstance();
	return new Promise(async (resolve, reject) => {
		await instance
			.post(url, payload)
			.then(response => resolve(response.data))
			.catch(error => {
				if (error.response) {
					reject({
						message: error.message,
						stack: error.message,
						statusCode: error.response.status,
						statusText: error.response.statusText,
						data: error.response.data
					} as IApiError);
				} else {
					reject({
						message: error.message,
						stack: error.stack,
						statusCode: null,
						statusText: null,
						data: null
					} as IApiError);
				}
			});
	});
};

export const del = async <T>(url: string): Promise<AxiosResponse<T>> => {
	const instance = await createInstance();
	return new Promise(async (resolve, reject) => {
		await instance
			.delete(url)
			.then(response => resolve(response.data))
			.catch(error => {
				if (error.response) {
					reject({
						message: error.message,
						stack: error.message,
						statusCode: error.response.status,
						statusText: error.response.statusText,
						data: error.response.data
					} as IApiError);
				} else {
					reject({
						message: error.message,
						stack: error.stack,
						statusCode: null,
						statusText: null,
						data: null
					} as IApiError);
				}
			});
	});
};

export const put = async <T>(url: string, payload?: any): Promise<AxiosResponse<T>> => {
	const instance = await createInstance();
	return new Promise(async (resolve, reject) => {
		await instance
			.put(url, payload)
			.then(response => resolve(response.data))
			.catch(error => {
				if (error.response) {
					reject({
						message: error.message,
						stack: error.message,
						statusCode: error.response.status,
						statusText: error.response.statusText,
						data: error.response.data
					} as IApiError);
				} else {
					reject({
						message: error.message,
						stack: error.stack,
						statusCode: null,
						statusText: null,
						data: null
					} as IApiError);
				}
			});
	});
};
