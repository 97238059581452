import { ERole } from 'auth';
import { LoaderBackdrop, Page } from 'components';
import { Suspense, lazy } from 'react';
import { Outlet, Route } from 'react-router';

const NotificationsImport = lazy(() => import('./viewer/main'));

const Notifications = <Page title='Notifications' component={NotificationsImport} allowedRoles={[ERole.ROLE_OSE]} />;

export const NotificationsRoutes = (
	<Route path='notifications' element={<Outlet />}>
		<Route index element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Notifications}</Suspense>} />
	</Route>
);
