import { Avatar, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material';
import logo from 'assets/images/logo_40x40_w.png';
import { Link as RouterLink } from 'react-router-dom';
import { actionCreator } from 'store/application';
import { useDispatch } from 'react-redux';
import { alpha } from '@mui/material/styles';

export const DrawerHeader = () => {
	const dispatch = useDispatch();
	const onNavigate = () => {
		dispatch(actionCreator.SET_DRAWER_OPEN(false));
	};
	return (
		<ListItemButton
			component={RouterLink}
			to='/'
			onClick={onNavigate}
			alignItems='center'
			sx={{
				flex: 0,
				p: 2
			}}>
			<ListItemAvatar
				sx={{
					minWidth: 40
				}}>
				<Avatar
					sx={{
						height: 40,
						width: 40,
						ml: 1
					}}
					alt='Precision Sales'
					src={logo}
				/>
			</ListItemAvatar>
			<ListItemText
				sx={{ my: 0, px: 1 }}
				primary='Precision Sales'
				primaryTypographyProps={{
					fontSize: 16,
					fontWeight: 'medium',
					letterSpacing: 0,
					color: theme => alpha(theme.palette.common.white, 0.8)
				}}
			/>
		</ListItemButton>
	);
};
