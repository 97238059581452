import { Alert } from '@mui/material';
import { IAppointment } from 'models';
import { pacsService } from 'services';

export const PendingResolutionBanner = (props: { appointment: IAppointment }) => {
	const { appointment } = props;
	if (pacsService.ResolutionEnabled && appointment.locked) {
		return <Alert severity='warning'>Pending Resolution</Alert>;
	}
	return null;
};
