import { EActionState } from 'models';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/home';

export const Provider = (props: { children: any }) => {
	const mountedRef = useRef(false);
	const dispatch = useDispatch();
	const initAction = useSelector(selectors.getInitAction);
	const loadAction = useSelector(selectors.getLoadAction);
	useEffect(() => {
		if (initAction === EActionState.COMPLETED && loadAction !== EActionState.RUNNING) {
			if (!mountedRef.current) {
				dispatch(actionCreator.LOAD_START());
				mountedRef.current = true;
			}
		}
	}, []);
	return props.children;
};
