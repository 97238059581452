import { Alert, AlertTitle, Button, Container, Link, Stack, Typography } from '@mui/material';
import { logoutAccount } from 'auth';
import { useSelector } from 'react-redux';
import { selectors } from 'store/auth';
import { EAuthError } from './constants';

export const DefaultAuthErrorView = () => {
	const authError = useSelector(selectors.getAuthError);
	const handleLogOutClick = async () => {
		await logoutAccount();
	};
	return (
		<Container
			maxWidth='md'
			sx={{
				p: 1
			}}>
			<Alert
				severity='error'
				sx={{
					'& .MuiAlert-message': {
						flex: 1
					}
				}}>
				<AlertTitle>Critical Application Error</AlertTitle>
				{authError === EAuthError.MSAL_FAILURE && (
					<Stack spacing={1}>
						<Typography variant='body2'>You are not authorized to use this application.</Typography>
						<Typography variant='body2'>
							If you believe this is an error, please contact{' '}
							<Link href='mailto: pqsupport@empire-today.com' underline='hover'>
								PQ Support
							</Link>
						</Typography>
						<Button onClick={handleLogOutClick} fullWidth>
							Log Out
						</Button>
					</Stack>
				)}
				{authError === EAuthError.SAGA_FAILURE && (
					<Stack spacing={1}>
						<Typography variant='body2'>The application failed to load due to a server error or connection issue</Typography>
						<Typography variant='body2'>
							Please check your connection or contact{' '}
							<Link href='mailto: pqsupport@empire-today.com' underline='hover'>
								PQ Support
							</Link>
						</Typography>
					</Stack>
				)}
			</Alert>
		</Container>
	);
};
