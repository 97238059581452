import { EActionState } from './actionstate';

export enum ESearchDateRange {
	TODAY = 'Today',
	YESTERDAY = 'Yesterday',
	LAST_7_DAYS = 'Last 7 Days',
	LAST_30_DAYS = 'Last 30 Days'
}

export enum EMobileStatus {
	ACCEPTED = 'Accepted',
	COMPLETE = 'Complete',
	COMPLETED = 'Completed',
	CUSTOMERCANCELLED = 'CustomerCancelled',
	CUSTOMERRESCHEDULED = 'CustomerRescheduled',
	DISPOSITIONED = 'Dispositioned',
	NA = 'NA',
	NO_PRODUCTS_PRESENTED = 'No Products Presented',
	NOTATHOME = 'NotAtHome',
	PENDING = 'Pending',
	RESCHEDULED = 'Rescheduled'
}

export enum EAppointmentStatus {
	SOLD = 'Sold',
	CANCELLED = 'Cancelled'
}

export interface IAppointmentSearchResult {
	activityId: string;
	addressLine1: string;
	addressLine2: string;
	businessName: string;
	businessUnit: string;
	city: string;
	company: string;
	contractNumber: string;
	displayAddress: string;
	displayCityStateZip: string;
	displayLeadDate: string;
	displayLeadStartTime: string;
	displayStreetAddress: string;
	endTime: string;
	firstName: string;
	isMasked: boolean;
	isPhoneHidden: boolean;
	lastName: string;
	leadDate: string;
	leadStartTimeUTC: string;
	locked: boolean;
	mobileStatus: EMobileStatus;
	name: string;
	primaryPhoneNumber: string;
	products: string;
	rowId: string;
	serviceRegion: string;
	source: string;
	startTime: string;
	state: string;
	status: string;
	timeZone: string;
	type: string;
	zipCode: string;
}

export interface IAppointment {
	accountName: string;
	accountRowId: string;
	activityId: string;
	addressLine1: string;
	addressLine2: string;
	businessName: string;
	businessUnit: string;
	city: string;
	comments: string;
	company: string;
	contractNumber: string;
	customerAddress: string;
	displayAddress: string;
	displayCityStateZip: string;
	displayLeadDate: string;
	displayLeadStartTime: string;
	displayStreetAddress: string;
	endTime: string;
	houseBuilt: string;
	intersection: string;
	isMasked: boolean;
	isPhoneHidden: boolean;
	isCodeRequired: boolean;
	latitude: number | null;
	leadDate: string;
	leadEndTimeUtc: string;
	leadStartTimeUtc: string;
	locked: boolean;
	longitude: number | null;
	mobileStatus: EMobileStatus;
	name: string;
	opportunityRowId: string;
	ownerFirstName: string;
	ownerId: string;
	ownerLastName: string;
	ownerRowId: string;
	pre1978: string;
	primaryContactAlternatePhoneNumber: string;
	primaryContactBusinessPhoneNumber: string;
	primaryContactEmailAddress: string;
	primaryContactFirstName: string;
	primaryContactLastName: string;
	primaryContactPrimaryPhoneNumber: string;
	primaryProduct: string;
	products: string;
	productRooms: IProductRoom[];
	promo: string;
	propertyType: string;
	rooms: string;
	rowId: string;
	secondaryContactAlternatePhoneNumber: string;
	secondaryContactBusinessPhoneNumber: string;
	secondaryContactEmailAddress: string;
	secondaryContactFirstName: string;
	secondaryContactLastName: string;
	secondaryContactPrimaryPhoneNumber: string;
	serviceRegion: string;
	source: string;
	specialInstructions: string;
	startTime: string;
	state: string;
	status: string;
	storeNumber: string;
	subStatus: string;
	subType: string;
	timestamp: string;
	timeZone: string;
	type: string;
	unlockedBy: string;
	unlockedDateTime: string;
	unlockSource: string;
	zipCode: string;
	zipLatitude: number | null;
	zipLongitude: number | null;
	unresolvedCount: number;
}

export interface IProductRoom {
	productLine: string;
	areas: string;
	isPrimary: boolean;
}

export interface IAppointmentState {
	load: EActionState;
	accept: EActionState;
	arrivalNotice: EActionState;
	resolve: EActionState;

	acceptEnabled: boolean;
	arrivalNoticeEnabled: boolean;
	resolveEnabled: boolean;
}

export const DefaultAppointmentState: IAppointmentState = {
	load: EActionState.PENDING,
	accept: EActionState.PENDING,
	arrivalNotice: EActionState.PENDING,
	resolve: EActionState.PENDING,

	acceptEnabled: false,
	arrivalNoticeEnabled: false,
	resolveEnabled: false
};

export interface IAppointmentCombinedState extends IAppointment, IAppointmentState {}
