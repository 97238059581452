import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { Alert, AlertTitle, Container, Paper, Stack, Typography } from '@mui/material';
import { getDeviceValues } from 'coreutil';
import { Component, ErrorInfo, ReactNode } from 'react';
import { useDeviceSelectors } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { selectors as appSelectors } from 'store/application';
import { appInsights } from './appinsightsprovider';
import { DataLine } from './dataline';

interface IApplicationBoundsProps {
	children: ReactNode;
}

interface IApplicationBoundsState {
	hasError: boolean;
	error: Error | null;
}

const ApplicationErrorPage = (props: { error: Error }) => {
	const [DeviceDetectors] = useDeviceSelectors(window.navigator.userAgent);
	const deviceValues = getDeviceValues(DeviceDetectors);
	const buildVersion = useSelector(appSelectors.getBuildVersion);
	return (
		<Container
			sx={{
				p: 1
			}}>
			<Stack spacing={1}>
				<Alert severity='error'>
					<AlertTitle>{props.error.message}</AlertTitle>
					{props.error.stack.split(/\r?\n|\r|\n/g).map(line => (
						<Typography key={line} variant='caption' component='p'>
							{line}
						</Typography>
					))}
				</Alert>
				<Paper square>
					<Stack
						spacing={1}
						sx={{
							p: 1
						}}>
						<Typography variant='subtitle1'>Diagnostic Information</Typography>
						<Stack spacing={1}>
							<DataLine left='Build Version' right={buildVersion} />
							{deviceValues.map(dv => (
								<DataLine key={dv.key} left={dv.key} right={dv.value} />
							))}
						</Stack>
					</Stack>
				</Paper>
			</Stack>
			<br />
		</Container>
	);
};

export class ApplicationErrorBoundary extends Component<IApplicationBoundsProps, IApplicationBoundsState> {
	public state: IApplicationBoundsState = {
		hasError: false,
		error: null
	};

	public static getDerivedStateFromError(error: Error): IApplicationBoundsState {
		return { hasError: true, error: error };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		console.error(error, errorInfo);
		if (appInsights !== null) {
			appInsights.trackException({
				exception: error,
				severityLevel: SeverityLevel.Error,
				properties: {
					...errorInfo
				}
			});
		}
	}

	public render() {
		if (this.state.hasError) {
			return <ApplicationErrorPage error={this.state.error as Error} />;
		}

		return this.props.children;
	}
}
