export enum ERole {
	ROLE_ADMIN = 'ROLE_ADMIN',
	ROLE_OSE = 'ROLE_OSE',
	ROLE_SUPPORT = 'ROLE_SUPPORT',
	ROLE_USER = 'ROLE_USER'
}

export enum EAuthError {
	MSAL_FAILURE = 'MSAL_FAILURE',
	SAGA_FAILURE = 'SAGA_FAILURE'
}

export const RoleDefinitions = {
	[ERole.ROLE_ADMIN]: 'Administrator Role',
	[ERole.ROLE_OSE]: 'Outside Sales Employees Role',
	[ERole.ROLE_SUPPORT]: 'User Support Role',
	[ERole.ROLE_USER]: 'Default User Role'
};
