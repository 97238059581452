import { useDeviceSelectors } from 'react-device-detect';

export enum EDeviceType {
	ANDROID = 'ANDROID',
	IOS = 'IOS',
	DEFAULT = 'DEFAULT'
}

export const useDevice = (): EDeviceType => {
	const [data] = useDeviceSelectors(window.navigator.userAgent);
	if (data.isAndroid) return EDeviceType.ANDROID;
	if (data.isIOS) return EDeviceType.IOS;
	return EDeviceType.DEFAULT;
};
