import { ERole } from 'auth';
import { LoaderBackdrop, Page } from 'components';
import { lazy, Suspense } from 'react';
import { Outlet, Route } from 'react-router';

const HomeImport = lazy(() => import('./home/main'));

const Home = <Page title='Account' component={HomeImport} allowedRoles={[ERole.ROLE_OSE]} />;

export const AccountRoutes = (
	<Route path='account' element={<Outlet />}>
		<Route index element={<Suspense fallback={<LoaderBackdrop text='Loading' />}>{Home}</Suspense>} />
	</Route>
);
