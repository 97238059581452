/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EAuthError, ERole } from 'auth';
import { ISiebelSalesRep } from 'models';
import { IIdToken } from 'models/token';
import { initialState } from './state';

const slice = createSlice({
	name: '@@auth',
	initialState,
	reducers: {
		AUTH_START: (state, action: PayloadAction<{ redirectUrl: string; roles: ERole[]; idToken: IIdToken }>) => {
			state.error = null;
			state.isAuthenticated = false;
			state.salesRep = null;
			state.roles = action.payload.roles;
			state.idToken = action.payload.idToken;
		},
		AUTH_COMPLETED: (state, action: PayloadAction<{ salesRep: ISiebelSalesRep | null }>) => {
			state.error = null;
			state.salesRep = action.payload.salesRep;
			state.isAuthenticated = true;
		},
		AUTH_FAILED: (state, action: PayloadAction<{ error: EAuthError }>) => {
			state.error = action.payload.error;
			state.isAuthenticated = false;
			state.salesRep = null;
			state.roles = [];
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
