import { api } from 'api/api';
import { ESearchDateRange, IAppointmentSearchResult } from 'models';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { dateService } from 'services';
import { handleSagaError } from 'store/common';
import { selectors } from './selectors';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.LOAD_START, onLoadStart);
	yield takeLatest(actionCreator.SET_FILTER_APPLIED, onSetFilterApplied);
}

function* onInitStart(): any {
	try {
		yield all([put(actionCreator.INIT_COMPLETED()), put(actionCreator.LOAD_START())]);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onLoadStart(): any {
	try {
		const searchTerm: string = yield select(selectors.getSearchTerm);
		const dateRange: ESearchDateRange = yield select(selectors.getDateRange);
		const interval = dateService.switchSearchDateRange(dateRange);
		const results: IAppointmentSearchResult[] = yield api.appointments.postSearch({
			searchTerm,
			startDate: interval.start,
			endDate: interval.end
		});
		yield put(
			actionCreator.LOAD_COMPLETED({
				appointments: results
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_FAILED()]);
	}
}

function* onSetFilterApplied(): any {
	yield put(actionCreator.LOAD_START());
}
