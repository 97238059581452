import { EActionState, ECustomerSearchDateRange, ECustomerSearchResultSortField, ESortDirection, ICustomerSearchResult } from 'models';

export interface IState {
	init: EActionState;
	load: EActionState;
	dateRange: ECustomerSearchDateRange;
	searchFilter: string;
	sortField: ECustomerSearchResultSortField;
	sortDirection: ESortDirection;
	results: ICustomerSearchResult[];
	sortedResults: ICustomerSearchResult[];
	filterModal: {
		open: boolean;
		searchFilter: string;
		dateRange: ECustomerSearchDateRange;
		sortField: ECustomerSearchResultSortField;
		sortDirection: ESortDirection;
	};
}

export const initialState: IState = {
	init: EActionState.PENDING,
	load: EActionState.PENDING,
	dateRange: ECustomerSearchDateRange.LAST_30,
	searchFilter: '',
	sortField: ECustomerSearchResultSortField.CUSTOMER_NAME,
	sortDirection: ESortDirection.ASC,
	results: [],
	sortedResults: [],
	filterModal: {
		open: false,
		searchFilter: '',
		dateRange: ECustomerSearchDateRange.LAST_30,
		sortField: ECustomerSearchResultSortField.CUSTOMER_NAME,
		sortDirection: ESortDirection.ASC
	}
};
