import { api } from 'api/api';
import { ICustomerSearchResult } from 'models';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import { dateService } from 'services';
import { handleSagaError } from 'store/common';
import { selectors } from './selectors';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.LOAD_START, onLoadStart);
	yield takeLatest(actionCreator.SET_FILTERS, onSetFilters);
}

function* onInitStart(): any {
	try {
		yield all([put(actionCreator.INIT_COMPLETED()), put(actionCreator.LOAD_START())]);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onLoadStart(): any {
	try {
		const searchTerm = yield select(selectors.getSearchFilter);
		const { start, end } = dateService.getLastDays(30);
		const results: ICustomerSearchResult[] = yield api.customers.postCustomerSearch({
			searchTerm,
			startDate: start,
			endDate: end
		});
		yield put(
			actionCreator.LOAD_COMPLETED({
				results
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_FAILED()]);
	}
}

function* onSetFilters(): any {
	yield put(actionCreator.LOAD_START());
}
