import { AxiosResponse } from 'axios';
import { get } from '../axiosprovider';
import { IPACSValue } from 'models';

const getValues = async (): Promise<AxiosResponse<Array<IPACSValue>>> => {
	return await get<Array<IPACSValue>>('/api/pacs');
};

export const pacsController = {
	getValues
};
