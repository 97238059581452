import { Alert } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectors } from 'store/home';

export const FutureLockBanner = () => {
	const showBanner = useSelector(selectors.getShowFutureLock);
	if (showBanner) {
		return (
			<Alert
				sx={{
					mb: 1
				}}
				severity='warning'>
				You are viewing appointments for a future date. These can not be accepted until the day of the appointment.
			</Alert>
		);
	}

	return null;
};
