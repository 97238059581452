import { IState } from 'store/notifications';
import { IAnnouncement, ENotificationViewType } from 'models';
import _ from 'lodash';
import { parseISO, isPast } from 'date-fns';

const setUnreadCount = (state: IState) => {
	state.unreadCount = _.filter(state.all, n => !n.read).length;
};

const setLastSync = (state: IState) => {
	state.lastSync = new Date().toISOString();
};

const processUpdates = (state: IState, updates: IAnnouncement[]) => {
	_.each(updates, u => {
		const m_i = _.findIndex(state.all, a => a.id === u.id);
		if (m_i != -1) {
			state.all[m_i] = {
				...u,
				selected: state.all[m_i].selected
			};
		} else {
			state.all.push({ ...u, selected: false });
		}
	});
	state.all = _.reject(state.all, a => isPast(parseISO(a.expirationDate)));
};

const setSelected = (state: IState, id: number, selected: boolean) => {
	const n_i = _.findIndex(state.all, a => a.id === id);
	if (n_i != -1) {
		state.all[n_i].selected = selected;
	}
};

const resetSelected = (state: IState) => {
	_.each(state.all, a => {
		a.selected = false;
	});
};

const setViewData = (state: IState) => {
	switch (state.viewType) {
		case ENotificationViewType.ALL: {
			state.viewData = _.orderBy(state.all, ['effectiveDate'], ['desc']);
			break;
		}
		case ENotificationViewType.UNREAD: {
			state.viewData = _.orderBy(
				_.filter(state.all, a => !a.read),
				['effectiveDate'],
				['desc']
			);
			break;
		}
		case ENotificationViewType.READ: {
			state.viewData = _.orderBy(
				_.filter(state.all, a => a.read),
				['effectiveDate'],
				['desc']
			);
			break;
		}
	}
};

export const notificationService = { setUnreadCount, setLastSync, processUpdates, setSelected, setViewData, resetSelected };
