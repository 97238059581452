import { Box, Tooltip } from '@mui/material';

export const Masked = (props: { children: string | JSX.Element | JSX.Element[]; title?: string; intensity?: number }) => {
	const { children, title, intensity } = props;
	return (
		<Tooltip title={title ? title : 'Details hidden'}>
			<Box sx={{ display: 'inline', filter: `blur(${(intensity ?? 1) * 3}px)`, userSelect: 'none' }}>{children}</Box>
		</Tooltip>
	);
};
