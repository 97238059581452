import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	Grid,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	TextField
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/referralprospect';
import { selectors as detailsSelectors } from 'store/appointments/details';
import { ChangeEvent } from 'react';
import { EActionState } from 'models';

export const ReferralDialog = () => {
	const dispatch = useDispatch();
	const open = useSelector(selectors.form.getOpen);
	const submit = useSelector(selectors.form.submit.get);

	const handleCancel = () => {
		dispatch(actionCreator.SET_FORM_CLOSE());
	};

	const handleSubmit = () => {
		dispatch(actionCreator.SUBMIT_REFERRAL_START());
	};

	return (
		<Dialog open={open}>
			<DialogTitle>
				<Grid container>
					<Grid item xs={6} sx={{ marginTop: '3px' }}>
						Create Referral
					</Grid>
				</Grid>
			</DialogTitle>
			<DialogContent>
				<Grid container spacing={1} paddingTop={1}>
					<Grid item xs={12} md={6}>
						<FirstNameField />
					</Grid>
					<Grid item xs={12} md={6}>
						<LastNameField />
					</Grid>
					<Grid item xs={12} md={6}>
						<PhoneNumberField />
					</Grid>
					<Grid item xs={12} md={6}>
						<EmailAddressField />
					</Grid>
					<Grid item xs={12} md={6}>
						<StateField />
					</Grid>
					<Grid item xs={12}>
						<NotesField />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} disabled={submit.actionState === EActionState.RUNNING}>
					Cancel
				</Button>
				{submit.actionState === EActionState.RUNNING && <CircularProgress size={24} sx={{ marginRight: 2 }} />}
				{submit.actionState !== EActionState.RUNNING && (
					<Button onClick={handleSubmit} disabled={!submit.enabled}>
						Submit
					</Button>
				)}
			</DialogActions>
		</Dialog>
	);
};

const FirstNameField = () => {
	const dispatch = useDispatch();
	const formValue = useSelector(selectors.form.formData.getFirstName);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			actionCreator.SET_PROPERTY({
				prop: 'firstName',
				value: event.target.value ?? ''
			})
		);
	};
	return <TextField label='First Name' variant='outlined' fullWidth required error={formValue.error} onChange={handleChange} value={formValue.newValue} />;
};

const LastNameField = () => {
	const dispatch = useDispatch();
	const formValue = useSelector(selectors.form.formData.getLastName);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			actionCreator.SET_PROPERTY({
				prop: 'lastName',
				value: event.target.value ?? ''
			})
		);
	};
	return <TextField label='Last Name' variant='outlined' fullWidth required error={formValue.error} onChange={handleChange} value={formValue.newValue} />;
};

const PhoneNumberField = () => {
	const dispatch = useDispatch();
	const formValue = useSelector(selectors.form.formData.getPhone);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			actionCreator.SET_PROPERTY({
				prop: 'phone',
				value: event.target.value ?? ''
			})
		);
	};
	return (
		<TextField
			label='Phone Number'
			variant='outlined'
			fullWidth
			error={formValue.error}
			onChange={handleChange}
			value={formValue.newValue}
			inputProps={{
				maxLength: 10
			}}
		/>
	);
};

const EmailAddressField = () => {
	const dispatch = useDispatch();
	const formValue = useSelector(selectors.form.formData.getEmailAddress);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			actionCreator.SET_PROPERTY({
				prop: 'emailAddress',
				value: event.target.value ?? ''
			})
		);
	};
	return <TextField label='Email Address' variant='outlined' fullWidth error={formValue.error} onChange={handleChange} value={formValue.newValue} />;
};

const StateField = () => {
	const dispatch = useDispatch();
	const states = useSelector(detailsSelectors.getStates);
	const formValue = useSelector(selectors.form.formData.getLocation);
	const handleChange = (event: SelectChangeEvent) => {
		dispatch(
			actionCreator.SET_PROPERTY({
				prop: 'location',
				value: event.target.value ?? ''
			})
		);
	};
	return (
		<FormControl fullWidth required>
			<InputLabel id='state-label'>State</InputLabel>
			<Select labelId='state-label' id='state' label='State' required error={formValue.error} onChange={handleChange} value={formValue.newValue}>
				{states.map((value, index) => (
					<MenuItem key={index} value={value.value}>
						{value.value}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

const NotesField = () => {
	const dispatch = useDispatch();
	const formValue = useSelector(selectors.form.formData.getNotes);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(
			actionCreator.SET_PROPERTY({
				prop: 'notes',
				value: event.target.value ?? ''
			})
		);
	};
	return (
		<TextField
			fullWidth
			label='Notes'
			multiline
			rows={4}
			helperText={`${formValue.newValue.length} / 250`}
			value={formValue.newValue}
			onChange={handleChange}
			inputProps={{ maxLength: 250 }}
			placeholder='Enter notes here (optional)'
		/>
	);
};
