import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';
import 'app.css';
import { ApplicationErrorBoundary, ApplicationInsightsProvider } from 'components';
import { DialogManager, MessageManager, ProgressManager } from 'messaging';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { HistoryRouter as Router } from 'redux-first-history/rr6';
import { buildStore } from 'store/configure';
import { theme } from 'theme';
import { RootLayout } from 'views/root/layout';
import { StartupProvider } from 'views/startup/provider';
import { version } from 'workers';
import { NotificationProvider } from 'views/notifications/provider';
import { AppointmentProvider } from 'views/appointments/provider/appointmentprovider';
import { ArrivalNoticeDialog, ResolveDialog } from 'views/dialogs';
import { SnackbarProvider } from 'notistack';
import { ReferralDialog } from 'views/dialogs/referralprospect';

if (performance.getEntriesByType('navigation')[0]?.entryType === 'back_forward') {
	window.location.reload();
}

const container = document.getElementById('root');
if (container) {
	const root = createRoot(container);
	const { store, history } = buildStore();
	root.render(
		<StrictMode>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<CssBaseline />
						<Provider store={store}>
							<ApplicationErrorBoundary>
								<ApplicationInsightsProvider>
									<SnackbarProvider>
										<StartupProvider>
											<Router history={history}>
												<version.VersionCheckManager />
												<MessageManager />
												<DialogManager />
												<ProgressManager />
												<RootLayout />
												<NotificationProvider />
												<AppointmentProvider />
												<ArrivalNoticeDialog />
												<ResolveDialog />
												<ReferralDialog />
											</Router>
										</StartupProvider>
									</SnackbarProvider>
								</ApplicationInsightsProvider>
							</ApplicationErrorBoundary>
						</Provider>
					</LocalizationProvider>
				</ThemeProvider>
			</StyledEngineProvider>
		</StrictMode>
	);
}
