import { Menu as MenuIcon } from '@mui/icons-material';
import { AppBar, Drawer, IconButton, Toolbar, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/application';
import { DrawerContainer } from './drawer';
import { NotificationButton } from './notificationbutton';
import { AppRoutes } from './routes';
import { useScreenType } from 'hooks';
import { MainBreadcrumbs } from 'components';

const DRAWER_WIDTH = 280;

export const RootLayout = () => {
	const dispatch = useDispatch();
	const drawerOpen = useSelector(selectors.getDrawerOpen);
	const screenType = useScreenType();
	const handleDrawerToggle = () => {
		dispatch(actionCreator.SET_DRAWER_OPEN(!drawerOpen));
	};

	const drawer = useMemo(() => <DrawerContainer />, []);
	return (
		<Box
			sx={{
				display: 'flex',
				flex: 1
			}}>
			{screenType.isPhone && (
				<AppBar
					position='fixed'
					sx={{
						width: { sm: `calc(100% - ${DRAWER_WIDTH}px)` },
						ml: { sm: `${DRAWER_WIDTH}px` },
						background: theme => theme.palette.background.paper,
						color: 'black'
					}}>
					<Toolbar>
						<IconButton color='inherit' edge='start' onClick={handleDrawerToggle} sx={{ mr: 2, display: { sm: 'none' } }}>
							<MenuIcon />
						</IconButton>
						<Typography
							variant='h6'
							noWrap
							component='div'
							color='inherit'
							sx={{
								flex: 1
							}}>
							Precision Sales
						</Typography>
						<NotificationButton />
					</Toolbar>
				</AppBar>
			)}
			<Box component='nav' sx={{ width: { sm: DRAWER_WIDTH }, flexShrink: { sm: 0 } }}>
				<Drawer
					variant='temporary'
					open={drawerOpen}
					onClose={handleDrawerToggle}
					ModalProps={{ keepMounted: true }}
					sx={{
						display: { xs: 'block', sm: 'none' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, background: theme => theme.palette.primary.main }
					}}>
					{drawer}
				</Drawer>
				<Drawer
					variant='permanent'
					sx={{
						display: { xs: 'none', sm: 'block' },
						'& .MuiDrawer-paper': { boxSizing: 'border-box', width: DRAWER_WIDTH, background: theme => theme.palette.primary.main }
					}}
					open>
					{drawer}
				</Drawer>
			</Box>
			<Box
				component='main'
				sx={{
					flex: 1,
					display: 'flex',
					flexDirection: 'column',
					width: {
						sm: `calc(100vw - ${DRAWER_WIDTH}px)`
					},
					overflowX: 'hidden'
				}}>
				{screenType.isPhone && <Toolbar />}
				<MainBreadcrumbs />
				<AppRoutes />
			</Box>
		</Box>
	);
};
