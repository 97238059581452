import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	AppBar,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	IconButton,
	LinearProgress,
	Stack,
	TextField,
	Toolbar,
	Typography
} from '@mui/material';
import { DialogTransition } from 'components';
import { useScreenType } from 'hooks';
import { EActionState } from 'models';
import { ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/resolution';
import { theme } from 'theme';

export const ResolveDialog = () => {
	const dispatch = useDispatch();
	const loading = useSelector(selectors.getInitAction);
	const open = useSelector(selectors.getOpen);
	const screenType = useScreenType();
	const handleCancel = () => {
		dispatch(actionCreator.INIT_FLUSH());
	};

	return (
		<Dialog
			open={open}
			fullScreen={screenType.isPhone}
			fullWidth={true}
			maxWidth={'sm'}
			TransitionComponent={DialogTransition}
			transitionDuration={{
				appear: theme.transitions.duration.enteringScreen,
				enter: theme.transitions.duration.enteringScreen,
				exit: 0
			}}>
			{screenType.isPhone && <TitleBar title='Resolution' onClose={handleCancel} />}
			{screenType.isDesktop && <DialogTitle>Resolution</DialogTitle>}
			<DialogContent sx={{ p: 0 }}>
				{loading === EActionState.RUNNING && <LinearProgress variant='indeterminate' />}
				{loading === EActionState.COMPLETED && <ResolutionContent />}
			</DialogContent>
			<DialogActions>
				<ResolveButton />
				<Button color='neutral' variant='outlined' onClick={handleCancel} sx={{ flex: screenType.isPhone ? '1 1 0px' : 'inherit' }}>
					Cancel
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const ResolutionContent = () => {
	const dispatch = useDispatch();
	const appointment = useSelector(selectors.getAppointment);
	const formValue = useSelector(selectors.getCode);
	const message = useSelector(selectors.getMessage);
	const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
		dispatch(actionCreator.SET_CODE(event.target.value ?? ''));
	};

	return (
		<Stack spacing={2} sx={{ p: 2, pt: 1, mt: { xs: 2, md: 0 } }}>
			{appointment.isCodeRequired && <Alert severity='warning'>A code is required to resolve this appointment</Alert>}
			{!appointment.isCodeRequired && <Alert severity='success'>A code is not required to resolve this appointment</Alert>}
			{appointment.isCodeRequired && (
				<TextField
					label='Resolution Code'
					onChange={handleChange}
					placeholder='Required'
					error={formValue.error && formValue.enabled}
					required
					helperText={formValue.message}
					value={formValue.newValue}
					disabled={!formValue.enabled}
					fullWidth
				/>
			)}
			{message && <Alert severity='error'>{message}</Alert>}
		</Stack>
	);
};

const ResolveButton = () => {
	const dispatch = useDispatch();
	const resolveEnabled = useSelector(selectors.getResolveEnabled);
	const resolvingAction = useSelector(selectors.getResolveAction);
	const screenType = useScreenType();
	const handleClick = () => {
		dispatch(actionCreator.RESOLVE_START());
	};
	return (
		<LoadingButton
			color='primary'
			variant='contained'
			onClick={handleClick}
			disabled={!resolveEnabled}
			loading={resolvingAction === EActionState.RUNNING}
			sx={{ flex: screenType.isPhone ? '1 1 0px' : 'inherit' }}>
			Resolve
		</LoadingButton>
	);
};

const TitleBar = (props: { title: string; onClose: () => void }) => {
	const { title, onClose } = props;
	return (
		<AppBar position='static' color='inherit'>
			<Toolbar>
				<IconButton color='inherit' onClick={onClose}>
					<ArrowBackIcon />
				</IconButton>
				<Typography variant='h6' component='div' sx={{ flexGrow: 1 }}>
					{title}
				</Typography>
			</Toolbar>
		</AppBar>
	);
};
