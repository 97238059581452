import { AxiosResponse } from 'axios';
import { ISiebelSalesRep } from 'models';
import { get } from '../axiosprovider';

const getProfile = async (): Promise<AxiosResponse<ISiebelSalesRep>> => {
	return await get<ISiebelSalesRep>('/api/profile');
};

export const profileController = {
	getProfile
};
