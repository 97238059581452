import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EActionState, IAppointmentSearchResult, ESearchDateRange } from 'models';
import { initialState } from './state';

const slice = createSlice({
	name: '@@appointments/search',
	initialState,
	reducers: {
		INIT_START: state => {
			state.init = EActionState.RUNNING;
		},
		INIT_COMPLETED: state => {
			state.init = EActionState.COMPLETED;
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
		},
		LOAD_START: state => {
			state.load = EActionState.RUNNING;
		},
		LOAD_COMPLETED: (
			state,
			action: PayloadAction<{
				appointments: IAppointmentSearchResult[];
			}>
		) => {
			state.load = EActionState.COMPLETED;
			state.results = action.payload.appointments;
		},
		LOAD_FAILED: state => {
			state.load = EActionState.FAILED;
		},
		SET_FILTER_OPEN: state => {
			state.filters.dateRange = state.dateRange;
			state.filters.searchTerm = state.searchTerm;
			state.filters.open = true;
		},
		SET_FILTER_APPLIED: state => {
			state.searchTerm = state.filters.searchTerm;
			state.dateRange = state.filters.dateRange;
			state.filters.open = false;
		},
		SET_FILTER_CLOSED: state => {
			state.filters.open = false;
		},
		SET_FILTER_SEARCH_TERM: (state, action: PayloadAction<string>) => {
			state.filters.searchTerm = action.payload;
		},
		SET_FILTER_DATE_RANGE: (state, action: PayloadAction<ESearchDateRange>) => {
			state.filters.dateRange = action.payload;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
