import { Box, Card, CardActions, CardContent, Stack, Typography, Tooltip, Divider, Grid, CardHeader, ButtonBase } from '@mui/material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { selectors } from 'store/home';
import { AcceptButton } from './acceptbutton';
import { PendingAcceptBanner } from './pendingacceptbanner';
import { ArrivalNoticeButton } from './arrivalnoticebutton';
import { phoneUtil } from 'coreutil';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { PendingResolutionBanner } from './pendingresolutionbanner';
import { ResolveButton } from './resolvebutton';
import { push } from 'redux-first-history';
import { appointmentService } from 'services';
import { Masked } from 'components';
import { useScreenType } from 'hooks';
import { EAppointmentStatus } from 'models';

export const AppointmentContainer = (props: { rowId: string; showDate?: boolean }) => {
	const dispatch = useDispatch();
	const screenType = useScreenType();
	const appointment = useSelector(selectors.getAppointment(props.rowId));
	const isAccepted = appointmentService.isAccepted(appointment.mobileStatus);
	const isCancelled = appointment.status === EAppointmentStatus.CANCELLED;
	const handleDetailsButtonClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
		dispatch(push(`/appointments/details/${props.rowId}`));
	};

	if (!appointment) return null;

	return (
		<Card
			key={appointment.rowId}
			variant='elevation'
			elevation={2}
			sx={{
				flexGrow: 1,
				borderRadius: 2,
				display: 'flex',
				flexDirection: 'column'
			}}>
			<CardHeader
				titleTypographyProps={{
					color: isCancelled ? 'error' : 'inherit'
				}}
				title={
					<Box>
						{props.showDate === true ? appointment.displayLeadDate : appointment.displayLeadStartTime}{' '}
						{isAccepted && (
							<Box sx={{ color: theme => theme.palette.success.light, display: 'inline', '& svg': { width: 18, height: 18 } }}>
								<Tooltip title='Accepted'>
									<CheckCircleIcon />
								</Tooltip>
							</Box>
						)}
					</Box>
				}
				component={ButtonBase}
				onClick={handleDetailsButtonClick}
				subheader={appointment.isMasked ? <Masked>1-000000000</Masked> : appointment.activityId}
				subheaderTypographyProps={{
					color: isCancelled ? 'error' : 'inherit'
				}}
				action={
					<Stack>
						<Box sx={{ height: 10 }} />
						<Typography variant='body2' component='div' sx={{ flex: 1, textAlign: 'right' }} color={isCancelled ? 'error' : 'inherit'}>
							{appointment.displayCityStateZip}
						</Typography>
						<Typography variant='body2' component='div' sx={{ flex: 1, textAlign: 'right' }} color={isCancelled ? 'error' : 'inherit'}>
							{appointment.isMasked && <Masked>xxxxx xxxxxxx - </Masked>}
							{!appointment.isMasked && appointment.type + ' - '}
							{appointment.status}
						</Typography>
					</Stack>
				}
				sx={{
					pb: 2,
					'& .MuiCardHeader-content': {
						textAlign: 'left'
					},
					'& .MuiCardHeader-title': {
						fontSize: 20
					},
					'& .MuiCardHeader-subheader': {
						fontSize: 14
					}
				}}></CardHeader>
			<CardContent sx={{ flexGrow: 1, pt: 0 }}>
				<Grid container sx={{ pb: 1 }}>
					<Grid item xs={6}>
						<Stack sx={{ mb: 1 }}>
							<Typography variant='subtitle2'>Location:</Typography>
							<Typography variant='body2' component='div' sx={{ flex: 1 }}>
								{appointment.isMasked ? <Masked>xxxx street rd.</Masked> : appointment.displayStreetAddress}
							</Typography>
							<Typography variant='body2' component='div' sx={{ flex: 1 }}>
								{appointment.displayCityStateZip}
							</Typography>
						</Stack>

						<Stack>
							<Typography variant='subtitle2'>Customer:</Typography>
							<Typography variant='body2' component='div' noWrap>
								{appointment.isMasked ? (
									<Masked>Xxxxx, Xxxxxxx</Masked>
								) : (
									`${appointment.primaryContactLastName}, ${appointment.primaryContactFirstName}`
								)}
							</Typography>
							<Typography variant='body2' component='div' noWrap>
								{appointment.isMasked ? (
									<Masked>(xxx) xxx-xxxx</Masked>
								) : (
									phoneUtil.formatPhoneNumberLib(appointment.primaryContactPrimaryPhoneNumber)
								)}
							</Typography>
						</Stack>
					</Grid>
					<Grid item xs={6}>
						<Stack sx={{ mb: 1 }}>
							<Typography variant='subtitle2'>Products:</Typography>
							<Typography variant='body2' noWrap sx={{ whiteSpace: 'unset' }}>
								{appointment.products}
							</Typography>
						</Stack>

						{screenType.isDesktop && (
							<Stack>
								<Typography variant='subtitle2'>Special Instructions:</Typography>
								<Typography variant='body2' noWrap>
									{appointment.specialInstructions ? appointment.specialInstructions : <i>none</i>}
								</Typography>
							</Stack>
						)}

						<Box sx={{ mt: 2 }}>
							<Link to={`/appointments/details/${props.rowId}`}>See all details</Link>
						</Box>
					</Grid>

					{screenType.isPhone && (
						<Grid item xs={12} sx={{ mt: 1 }}>
							<Stack>
								<Typography variant='subtitle2'>Special Instructions:</Typography>
								<Typography variant='body2'>{appointment.specialInstructions ? appointment.specialInstructions : <i>none</i>}</Typography>
							</Stack>
						</Grid>
					)}
				</Grid>

				<Stack spacing={1} sx={{ mt: 1 }}>
					<PendingAcceptBanner appointment={appointment} />
					<PendingResolutionBanner appointment={appointment} />
				</Stack>
			</CardContent>
			<CardActions sx={{ p: 0 }}>
				<Stack sx={{ flex: 1 }}>
					<Divider />
					<Stack direction='row' sx={{ '& > :not(:first-of-type)': { borderLeft: theme => `1px solid ${theme.palette.divider}` } }}>
						{appointment.acceptEnabled && <AcceptButton appointment={appointment} />}
						{appointment.arrivalNoticeEnabled && <ArrivalNoticeButton appointment={appointment} />}
						{appointment.resolveEnabled && <ResolveButton appointment={appointment} />}
					</Stack>
				</Stack>
			</CardActions>
		</Card>
	);
};
