import { LoaderBackdrop } from 'components';
import { isNullOrWhiteSpace } from 'coreutil';
import { EActionState } from 'models';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useParams } from 'react-router';
import { actionCreator, selectors } from 'store/appointments/details';

export const Provider = () => {
	const dispatch = useDispatch();
	const action = useSelector(selectors.getInitAction);
	const params = useParams();
	const rowId = params['rowId'] ?? '';

	useEffect(() => {
		if (action === EActionState.PENDING && !isNullOrWhiteSpace(rowId)) {
			dispatch(
				actionCreator.INIT_START({
					rowId
				})
			);
		}
		return () => {
			if (action === EActionState.COMPLETED) {
				dispatch(actionCreator.INIT_FLUSH());
			}
		};
	}, [dispatch, action]);

	if (action === EActionState.COMPLETED) {
		return <Outlet />;
	}

	return <LoaderBackdrop />;
};
