import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EActionState, IAppInsightsClientConfiguration, IKnobs, IMap, IUrls } from 'models';
import { initialState } from './state';

const slice = createSlice({
	name: '@@application',
	initialState,
	reducers: {
		SET_PAGE_TITLE: (state, action: PayloadAction<string>) => {
			state.pageTitle = action.payload;
		},
		SET_DRAWER_OPEN: (state, action: PayloadAction<boolean>) => {
			state.drawer.open = action.payload;
		},
		INIT_START: state => {
			state.connectionString = initialState.connectionString;
			state.init = EActionState.RUNNING;
		},
		INIT_COMPLETED: (
			state,
			action: PayloadAction<{
				connectionString: string;
				appInsightsClientConfiguration: IAppInsightsClientConfiguration;
				buildVersion: string;
				versionCheckInterval: number;
				knobs: IKnobs;
				map: IMap;
				urls: IUrls;
			}>
		) => {
			state.init = EActionState.COMPLETED;
			state.connectionString = action.payload.connectionString;
			state.appInsightsClientConfiguration = action.payload.appInsightsClientConfiguration;
			state.knobs = action.payload.knobs;
			state.map = action.payload.map;
			state.urls = action.payload.urls;
			state.buildVersion = action.payload.buildVersion;
			state.versionCheckInterval = action.payload.versionCheckInterval;
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
			state.connectionString = initialState.connectionString;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
