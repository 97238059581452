import { isNullOrWhiteSpace } from './stringutil';

export const getDeviceValues = (data: any): Array<{ key: string; value: string }> => {
	const keys = Object.keys(data);
	const keyValues = keys.map((k, i) => {
		return {
			key: k,
			value: Object.values(data)[i]?.toString() ?? ''
		};
	});
	return keyValues.filter(k => {
		return !isNullOrWhiteSpace(k.value) && k.value.toLowerCase() !== 'false' && k.value.toLowerCase() !== 'none';
	});
};
