import { Breadcrumbs, Link, LinkProps, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

interface LinkRouterProps extends LinkProps {
	to: string;
	replace?: boolean;
}

const LinkRouter = (props: LinkRouterProps) => <Link {...props} component={RouterLink as any} underline='hover' />;

const customRoutes = [{ path: '/selfgen', breadcrumb: 'Self Gen' }];

export const MainBreadcrumbs = () => {
	const breadcrumbs = useBreadcrumbs(customRoutes);
	return (
		<Breadcrumbs
			sx={{
				ml: 3,
				mt: 2
			}}>
			{breadcrumbs.map((value, index) => {
				const last = index === breadcrumbs.length - 1;
				const color = last ? 'textPrimary' : 'inherit';
				return (
					<LinkRouter color={color} key={value.key} to={value.key}>
						<Typography variant='caption'>{value.breadcrumb}</Typography>
					</LinkRouter>
				);
			})}
		</Breadcrumbs>
	);
};
