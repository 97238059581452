import { IAppointmentSearchResult, EActionState, ESearchDateRange } from 'models';

export interface IState {
	init: EActionState;
	load: EActionState;
	dateRange: ESearchDateRange;
	searchTerm: string;
	results: IAppointmentSearchResult[];
	filters: {
		open: boolean;
		searchTerm: string;
		dateRange: ESearchDateRange;
	};
}

export const initialState: IState = {
	init: EActionState.PENDING,
	load: EActionState.PENDING,
	dateRange: ESearchDateRange.TODAY,
	searchTerm: '',
	results: [],
	filters: {
		open: false,
		searchTerm: '',
		dateRange: ESearchDateRange.TODAY
	}
};
