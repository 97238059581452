import { IGlobalState } from 'store';

export const selectors = {
	form: {
		get: (state: IGlobalState) => state.referralProspect.form,
		getOpen: (state: IGlobalState) => state.referralProspect.form.open,
		submit: {
			get: (state: IGlobalState) => state.referralProspect.form.submit,
			getEnabled: (state: IGlobalState) => state.referralProspect.form.submit.enabled,
			getActionState: (state: IGlobalState) => state.referralProspect.form.submit.actionState
		},
		formData: {
			get: (state: IGlobalState) => state.referralProspect.form.formData,
			getAccountId: (state: IGlobalState) => state.referralProspect.form.formData.accountId,
			getEmailAddress: (state: IGlobalState) => state.referralProspect.form.formData.emailAddress,
			getFirstName: (state: IGlobalState) => state.referralProspect.form.formData.firstName,
			getLastName: (state: IGlobalState) => state.referralProspect.form.formData.lastName,
			getLocation: (state: IGlobalState) => state.referralProspect.form.formData.location,
			getNotes: (state: IGlobalState) => state.referralProspect.form.formData.notes,
			getOpportunityId: (state: IGlobalState) => state.referralProspect.form.formData.opportunityId,
			getPhone: (state: IGlobalState) => state.referralProspect.form.formData.phone
		}
	}
};
