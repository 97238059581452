import _ from 'lodash';
import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.home.init,
	getLoadAction: (state: IGlobalState) => state.home.load,
	getTodayAppointmentIds: (state: IGlobalState) => state.home.todayAppointmentIds,
	getFutureAppointmentIds: (state: IGlobalState) => state.home.futureAppointmentIds,
	getAppointment: (rowId: string) => (state: IGlobalState) => _.find(state.home.appointments, a => a.rowId === rowId) ?? null,
	getAppointments: (state: IGlobalState) => state.home.appointments,
	getShowFutureLock: (state: IGlobalState) => state.home.showFutureLock,
	getShowRequireLeadCompletion: (state: IGlobalState) => state.home.showRequireLeadCompletion
};
