import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.arrivalNotice.init,
	getOpen: (state: IGlobalState) => state.arrivalNotice.open,
	getAppointment: (state: IGlobalState) => state.arrivalNotice.appointment,
	getMethod: (state: IGlobalState) => state.arrivalNotice.method,
	getQuantityEnabled: (state: IGlobalState) => state.arrivalNotice.quantityEnabled,
	getTimeEnabled: (state: IGlobalState) => state.arrivalNotice.timeEnabled,
	getQuantities: (state: IGlobalState) => state.arrivalNotice.quantities,
	getSelectedQuantity: (state: IGlobalState) => state.arrivalNotice.selectedQuantity,
	getTimes: (state: IGlobalState) => state.arrivalNotice.times,
	getSelectedTime: (state: IGlobalState) => state.arrivalNotice.selectedTime,
	getSendAction: (state: IGlobalState) => state.arrivalNotice.send,
	getSendEnabled: (state: IGlobalState) => state.arrivalNotice.sendEnabled,
	getResultMessage: (state: IGlobalState) => state.arrivalNotice.resultMessage
};
