import { createTheme, darken, lighten } from '@mui/material';
import { purple } from '@mui/material/colors';

const primary = '#136798';
const secondary = '#a5bfcf';
const neutral = '#64748B';

export const theme = createTheme({
	palette: {
		mode: 'light',
		primary: {
			main: primary
		},
		secondary: {
			main: secondary
		},
		neutral: {
			main: neutral,
			contrastText: '#fff',
			light: lighten(neutral, 0.5),
			dark: darken(neutral, 0.5)
		},
		purple: {
			main: purple[500],
			contrastText: '#fff',
			light: purple[300],
			dark: purple[700]
		}
	},
	components: {
		MuiChip: {
			styleOverrides: {
				root: {
					lineHeight: '100%'
				}
			}
		},
		MuiButton: {
			styleOverrides: {
				root: {
					minHeight: 44
				}
			}
		}
	}
});
export const darkTheme = createTheme({
	palette: {
		mode: 'dark',
		primary: {
			main: primary
		},
		secondary: {
			main: secondary
		},
		neutral: {
			main: neutral,
			contrastText: '#fff',
			light: lighten(neutral, 0.25),
			dark: darken(neutral, 0.25)
		},
		purple: {
			main: purple[500],
			contrastText: '#fff',
			light: purple[300],
			dark: purple[700]
		}
	}
});
