import { Button } from '@mui/material';
import { IAppointmentCombinedState } from 'models';
import { useDispatch } from 'react-redux';
import { actionCreator } from 'store/resolution';

export const ResolveButton = (props: { appointment: IAppointmentCombinedState }) => {
	const { appointment } = props;
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(
			actionCreator.INIT_START({
				activityId: appointment.activityId
			})
		);
	};
	return (
		<Button
			variant='text'
			disabled={!appointment.resolveEnabled}
			onClick={handleClick}
			sx={{
				minWidth: 150,
				flex: '1 1 0px',
				borderRadius: '0px 0px 0px 0px',
				minHeight: 50
			}}>
			Resolve
		</Button>
	);
};
