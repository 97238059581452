import { AxiosResponse } from 'axios';
import { IOpportunityNote } from 'models';
import { get, post } from '../axiosprovider';

const getOpportunityNotes = async (rowId: string): Promise<AxiosResponse<IGetOpportunityNotesResponse>> => {
	return await get<IGetOpportunityNotesResponse>(`/api/notes/${rowId}`);
};

const upsertOpportunityNote = async (request: IUpsertOpportunityNoteRequest): Promise<AxiosResponse<IUpsertOpportunityNoteResponse>> => {
	return await post<IUpsertOpportunityNoteResponse>('/api/notes', request);
};

export const notesController = {
	getOpportunityNotes,
	upsertOpportunityNote
};

export interface IGetOpportunityNotesResponse {
	message: string;
	success: boolean;
	notes: IOpportunityNote[];
}

export interface IUpsertOpportunityNoteRequest {
	company: string;
	opportunityRowId: string;
	note: string;
}

export interface IUpsertOpportunityNoteResponse {
	message: string;
	success: boolean;
}
