import { useTrail } from '@react-spring/web';

export const getStaggeredSelectButtonTrail = (count: number) => {
	return useTrail(count, {
		config: { mass: 5, tension: 3500, friction: 200 },
		opacity: open ? 1 : 0,
		x: open ? 0 : 20,
		height: open ? 110 : 0,
		from: { opacity: 0, x: 20, height: 0 }
	});
};

export const animationService = {
	getStaggeredSelectButtonTrail
};
