import { api } from 'api/api';
import { dateService } from 'services';
import { IAnnouncement } from 'models';
import { put, takeLatest, select } from 'redux-saga/effects';
import { handleSagaError } from 'store/common';
import { actionCreator } from './slice';
import { selectors } from './selectors';
import { selectors as appSelectors } from 'store/application';
import _ from 'lodash';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.LOAD_START, onLoadStart);
	yield takeLatest(actionCreator.UPDATE_START, onUpdateStart);
}

function* onInitStart(): any {
	try {
		const buffer: number = yield select(appSelectors.knobs.getNotificationSyncBuffer);
		let lastSync: string | null = yield select(selectors.getLastSync);
		if (!lastSync) {
			lastSync = dateService.getSyncDateUTC(7);
		}
		lastSync = dateService.applyBuffer(lastSync, buffer);
		const data: IAnnouncement[] = yield api.notifications.getLegacyAnnouncementsAsync(lastSync);
		yield put(
			actionCreator.INIT_COMPLETED({
				all: data
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onLoadStart(): any {
	try {
		const buffer: number = yield select(appSelectors.knobs.getNotificationSyncBuffer);
		let lastSync: string | null = yield select(selectors.getLastSync);
		if (!lastSync) {
			lastSync = dateService.getSyncDateUTC(7);
		}
		lastSync = dateService.applyBuffer(lastSync, buffer);
		const data: IAnnouncement[] = yield api.notifications.getLegacyAnnouncementsAsync(lastSync);
		yield put(
			actionCreator.LOAD_COMPLETED({
				updated: data
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_FAILED()]);
	}
}

function* onUpdateStart(): any {
	try {
		const data: IAnnouncement[] = yield select(selectors.getViewData);
		const results: IAnnouncement[] = yield api.notifications.updateLegacyAnnouncementsAsync(
			_.filter(data, d => d.selected).map(d => ({
				id: d.id,
				read: true
			}))
		);
		yield put(
			actionCreator.UPDATE_COMPLETED({
				updated: results
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.UPDATE_FAILED()]);
	}
}
