import { IOrder } from './order';

export enum EProjectStatus {
	CancelPending = 'Cancel Pending',
	Cancelled = 'Cancelled',
	Completed = 'Completed',
	Deleted = 'Deleted',
	Open = 'Open'
}

export interface IProject {
	d365Id: string;
	id: string;
	status: EProjectStatus;
	currentTotal: number;
	orders: IOrder[];
	productLine: string;
}
