import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EActionState, IAppointmentCombinedState, IHeader, IListValue, IOpportunityNote, IQuoteOption } from 'models';
import { resetState } from 'store/common/slice';
import { initialState } from './state';
import { isNullOrWhiteSpace } from 'coreutil';

const slice = createSlice({
	name: '@@appointments/details',
	initialState,
	reducers: {
		INIT_START: (
			state,
			action: PayloadAction<{
				rowId: string;
			}>
		) => {
			state.init = EActionState.RUNNING;
			state.rowId = action.payload.rowId;
			state.states = initialState.states;
		},
		INIT_COMPLETED: (state, action: PayloadAction<{ states: IListValue[] }>) => {
			state.states = action.payload.states;
			state.init = EActionState.COMPLETED;
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
			state.states = initialState.states;
		},
		INIT_FLUSH: state => {
			resetState(state, initialState);
		},
		LOAD_ALL_START: state => {
			state.load = EActionState.RUNNING;
		},
		LOAD_ALL_COMPLETED: state => {
			state.load = EActionState.COMPLETED;
		},
		LOAD_ALL_FAILED: state => {
			state.load = EActionState.FAILED;
		},
		REFRESH_ALL_START: state => {
			state.refresh = EActionState.RUNNING;
		},
		REFRESH_ALL_COMPLETED: state => {
			state.refresh = EActionState.COMPLETED;
		},
		REFRESH_ALL_FAILED: state => {
			state.refresh = EActionState.FAILED;
		},
		LOAD_APPOINTMENT_START: state => {
			state.appointment.load = EActionState.RUNNING;
		},
		LOAD_APPOINTMENT_COMPLETED: (
			state,
			action: PayloadAction<{
				data: IAppointmentCombinedState;
			}>
		) => {
			state.appointment.load = EActionState.COMPLETED;
			state.appointment.data = action.payload.data;
		},
		LOAD_APPOINTMENT_FAILED: state => {
			state.appointment.load = EActionState.FAILED;
		},
		REFRESH_APPOINTMENT_START: state => {
			state.appointment.refresh = EActionState.RUNNING;
		},
		REFRESH_APPOINTMENT_COMPLETED: (
			state,
			action: PayloadAction<{
				data: IAppointmentCombinedState;
			}>
		) => {
			state.appointment.refresh = EActionState.COMPLETED;
			state.appointment.data = action.payload.data;
		},
		REFRESH_APPOINTMENT_FAILED: state => {
			state.appointment.refresh = EActionState.FAILED;
		},
		//NOTES
		LOAD_NOTES_START: state => {
			state.notes.load = EActionState.RUNNING;
		},
		LOAD_NOTES_COMPLETED: (
			state,
			action: PayloadAction<{
				notes: IOpportunityNote[];
			}>
		) => {
			state.notes.load = EActionState.COMPLETED;
			state.notes.data = action.payload.notes;
		},
		LOAD_NOTES_FAILED: state => {
			state.notes.load = EActionState.FAILED;
		},
		REFRESH_NOTES_START: state => {
			state.notes.refresh = EActionState.RUNNING;
		},
		REFRESH_NOTES_COMPLETED: (
			state,
			action: PayloadAction<{
				notes: IOpportunityNote[];
			}>
		) => {
			state.notes.refresh = EActionState.COMPLETED;
			state.notes.data = action.payload.notes;
		},
		REFRESH_NOTES_FAILED: state => {
			state.notes.refresh = EActionState.FAILED;
		},
		CREATE_NOTE_OPEN: state => {
			state.notes.dialog.open = true;
		},
		CREATE_NOTE_CLOSE: state => {
			resetState(state.notes.dialog, initialState.notes.dialog);
		},
		CREATE_NOTE_SET_NOTE: (state, action: PayloadAction<string>) => {
			state.notes.dialog.note = action.payload;
			state.notes.dialog.saveEnabled = !isNullOrWhiteSpace(state.notes.dialog.note);
		},
		CREATE_NOTE_SAVE_START: state => {
			state.notes.dialog.save = EActionState.RUNNING;
		},
		CREATE_NOTE_SAVE_COMPLETED: (state, action: PayloadAction<{ message: string }>) => {
			state.notes.dialog.message = action.payload.message;
		},
		CREATE_NOTE_SAVE_FAILED: state => {
			state.notes.dialog.save = EActionState.FAILED;
		},
		//QUOTES
		LOAD_QUOTES_START: state => {
			state.quotes.load = EActionState.RUNNING;
		},
		LOAD_QUOTES_COMPLETED: (
			state,
			action: PayloadAction<{
				quotes: IQuoteOption[];
			}>
		) => {
			state.quotes.load = EActionState.COMPLETED;
			state.quotes.data = action.payload.quotes;
		},
		LOAD_QUOTES_FAILED: state => {
			state.quotes.load = EActionState.FAILED;
		},
		REFRESH_QUOTES_START: state => {
			state.quotes.refresh = EActionState.RUNNING;
		},
		REFRESH_QUOTES_COMPLETED: (
			state,
			action: PayloadAction<{
				quotes: IQuoteOption[];
			}>
		) => {
			state.quotes.refresh = EActionState.COMPLETED;
			state.quotes.data = action.payload.quotes;
		},
		REFRESH_QUOTES_FAILED: state => {
			state.quotes.refresh = EActionState.FAILED;
		},
		//CONTRACT
		LOAD_CONTRACT_START: state => {
			state.contract.load = EActionState.RUNNING;
		},
		LOAD_CONTRACT_COMPLETED: (
			state,
			action: PayloadAction<{
				header: IHeader;
			}>
		) => {
			state.contract.load = EActionState.COMPLETED;
			state.contract.data = action.payload.header;
		},
		LOAD_CONTRACT_FAILED: state => {
			state.contract.load = EActionState.FAILED;
		},
		REFRESH_CONTRACT_START: state => {
			state.contract.refresh = EActionState.RUNNING;
		},
		REFRESH_CONTRACT_COMPLETED: (
			state,
			action: PayloadAction<{
				header: IHeader;
			}>
		) => {
			state.contract.refresh = EActionState.COMPLETED;
			state.contract.data = action.payload.header;
		},
		REFRESH_CONTRACT_FAILED: state => {
			state.contract.refresh = EActionState.FAILED;
		},
		//CHANGE_ORDERS
		LOAD_CHANGE_ORDERS_START: state => {
			state.changeOrders.load = EActionState.RUNNING;
		},
		LOAD_CHANGE_ORDERS_COMPLETED: state => {
			state.changeOrders.load = EActionState.COMPLETED;
		},
		LOAD_CHANGE_ORDERS_FAILED: state => {
			state.changeOrders.load = EActionState.FAILED;
		},
		REFRESH_CHANGE_ORDERS_START: state => {
			state.changeOrders.refresh = EActionState.RUNNING;
		},
		REFRESH_CHANGE_ORDERS_COMPLETED: state => {
			state.changeOrders.refresh = EActionState.COMPLETED;
		},
		REFRESH_CHANGE_ORDERS_FAILED: state => {
			state.changeOrders.refresh = EActionState.FAILED;
		},
		//ACCEPT
		ACCEPT_START: state => {
			state.appointment.data.accept = EActionState.RUNNING;
		},
		ACCEPT_COMPLETED: (state, action: PayloadAction<{ appointment: IAppointmentCombinedState }>) => {
			state.appointment.data = action.payload.appointment;
		},
		ACCEPT_FAILED: state => {
			state.appointment.data.accept = EActionState.PENDING;
		},
		//NOTIFY
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		NOTIFY_APPOINTMENT_CHANGED: (state, action: PayloadAction<{ rowId: string }>) => {}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
