import { AccountInfo } from '@azure/msal-browser';
import { getAllAccounts, getCurrentAccount } from 'auth/service';
import { ERole } from './constants';

export const useAuth = (): { currentAccount: AccountInfo | null; otherAccounts: AccountInfo[]; roles: ERole[] } => {
	try {
		const allAccounts = getAllAccounts();
		const currentAccount = getCurrentAccount();
		const roles = (currentAccount?.idTokenClaims?.roles as ERole[]) ?? [];

		return {
			currentAccount: getCurrentAccount(),
			otherAccounts: allAccounts.filter(acct => acct.username !== currentAccount?.username),
			roles: roles
		};
	} catch (ex) {
		console.error(ex);
		return {
			currentAccount: null,
			otherAccounts: [],
			roles: []
		};
	}
};
