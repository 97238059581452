import { ISelfGenRequest } from 'api/selfgen/controller';
import { emailUtil, isNullOrWhiteSpace, phoneUtil, isLettersAndNumbersOnly } from 'coreutil';
import _ from 'lodash';
import { ISiebelSalesRep, EActionState, IFormValue, IListValue, ISelfGenFormState } from 'models';
import { v4 } from 'uuid';

const zipValid = (value: string): boolean => {
	return new RegExp('^[0-9]{5}').test(value);
};

const setZipCodeErrorState = (state: IFormValue<string>) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue) || state.newValue.length < 5) {
		state.error = true;
		state.message = 'Required';
		return;
	}
	if (!zipValid(state.newValue)) {
		state.error = true;
		state.message = 'Invalid Zip Code';
		return;
	}
};

const setRegionInfoErrorState = (state: ISelfGenFormState) => {
	if (state.activity.loadRegion === EActionState.COMPLETED) {
		state.formData.zipCode.message = '';
		if (!state.regionInfo.validZip) {
			state.formData.zipCode.message = 'Zip Code Not Found';
			return;
		}
		if (!state.regionInfo.serviced) {
			state.formData.zipCode.message = 'Unserviced Zip Code';
			return;
		}
	}
};

const setOptionalStringErrorstate = (state: IFormValue<string>, alphanumeric: boolean) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		return;
	}
	if (alphanumeric && !isLettersAndNumbersOnly(state.newValue)) {
		state.error = true;
		state.message = 'Alphanumeric Only';
		return;
	}
};

const setRequiredStringErrorState = (state: IFormValue<string>, alphanumeric: boolean) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		state.error = true;
		state.message = 'Required';
		return;
	}
	if (alphanumeric && !isLettersAndNumbersOnly(state.newValue)) {
		state.error = true;
		state.message = 'Alphanumeric Only';
		return;
	}
};

const setMainPhoneErrorState = (state: IFormValue<string>) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue) || state.newValue.length < 10) {
		state.error = true;
		state.message = 'Required';
		return;
	}
	if (state.newValue.length > 10) {
		state.error = true;
		state.message = 'Invalid Phone Number';
		return;
	}
	if (!phoneUtil.isValidPhoneNumber(state.newValue)) {
		state.error = true;
		state.message = 'Invalid Phone Number';
		return;
	}
};

const setAlternatePhoneErrorState = (state: IFormValue<string>) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		return;
	}
	if (state.newValue.length > 10) {
		state.error = true;
		state.message = 'Invalid Phone Number';
		return;
	}
	if (!phoneUtil.isValidPhoneNumber(state.newValue)) {
		state.error = true;
		state.message = 'Invalid Phone Number';
		return;
	}
};

const setEmailAddressErrorState = (state: IFormValue<string>) => {
	state.error = false;
	state.message = '';
	if (isNullOrWhiteSpace(state.newValue)) {
		return;
	}
	if (!emailUtil.isValidEmailAddress(state.newValue)) {
		state.error = true;
		state.message = 'Invalid Email Address';
		return;
	}
};

const resetErrorState = (state: IFormValue<any>) => {
	state.error = false;
	state.message = '';
};

const setStep1ErrorState = (state: ISelfGenFormState) => {
	resetErrorState(state.formData.firstName);
	resetErrorState(state.formData.lastName);
	resetErrorState(state.formData.mainPhone);
	resetErrorState(state.formData.alternatePhone);
	resetErrorState(state.formData.addressLine1);
	resetErrorState(state.formData.addressLine2);
	resetErrorState(state.formData.city);
	resetErrorState(state.formData.state);
	resetErrorState(state.formData.emailAddress);
	resetErrorState(state.formData.pre1978);
	resetErrorState(state.formData.buildingType);
	resetErrorState(state.formData.rentOwn);

	setZipCodeErrorState(state.formData.zipCode);
	setRegionInfoErrorState(state);

	state.formData.step1.error = state.formData.zipCode.error || !state.regionInfo.serviced || !state.regionInfo.validZip;

	if (!state.regionInfo.serviced) return;

	setRequiredStringErrorState(state.formData.firstName, true);
	setRequiredStringErrorState(state.formData.lastName, true);
	setMainPhoneErrorState(state.formData.mainPhone);
	setAlternatePhoneErrorState(state.formData.alternatePhone);
	setRequiredStringErrorState(state.formData.addressLine1, false);
	setOptionalStringErrorstate(state.formData.addressLine2, false);
	setRequiredStringErrorState(state.formData.city, false);
	setRequiredStringErrorState(state.formData.state, false);
	setRequiredStringErrorState(state.formData.pre1978, false);
	setRequiredStringErrorState(state.formData.buildingType, false);
	setRequiredStringErrorState(state.formData.rentOwn, false);
	setEmailAddressErrorState(state.formData.emailAddress);
	setOptionalStringErrorstate(state.formData.businessName, false);

	state.formData.step1.error =
		state.formData.firstName.error ||
		state.formData.lastName.error ||
		state.formData.mainPhone.error ||
		state.formData.alternatePhone.error ||
		state.formData.addressLine1.error ||
		state.formData.addressLine2.error ||
		state.formData.city.error ||
		state.formData.state.error ||
		state.formData.pre1978.error ||
		state.formData.buildingType.error ||
		state.formData.rentOwn.error ||
		state.formData.emailAddress.error ||
		state.formData.businessName.error;
};

const setStep2ErrorState = (state: ISelfGenFormState) => {
	resetErrorState(state.formData.date);
	resetErrorState(state.formData.time);

	setRequiredStringErrorState(state.formData.date, false);
	setRequiredStringErrorState(state.formData.time, false);

	state.formData.step2.error = state.formData.date.error || state.formData.time.error;
};

const setStep3ErrorState = (state: ISelfGenFormState) => {
	state.formData.step3.error = !_.some(state.formData.products, p => p.checked) || !_.some(state.formData.areas, a => a.checked);
};

const setErrorState = (state: ISelfGenFormState) => {
	setStep1ErrorState(state);
	setStep2ErrorState(state);
	setStep3ErrorState(state);
	state.formData.error = state.formData.step1.error || state.formData.step2.error || state.formData.step3.error;
};

const setNextEnabled = (state: ISelfGenFormState) => {
	state.activity.step1.nextEnabled = !state.formData.step1.error;
	state.activity.step2.nextEnabled = !state.formData.step2.error;
	state.activity.step3.nextEnabled = !state.formData.step3.error;
	state.activity.step4.submitEnabled = !state.formData.error;
};

const setEnabledState = (state: ISelfGenFormState) => {
	setStep1EnabledState(state);
	setStep2EnabledState(state);
	setNextEnabled(state);
};

const setStep1EnabledState = (state: ISelfGenFormState) => {
	state.formData.firstName.enabled = state.regionInfo.serviced;
	state.formData.lastName.enabled = state.regionInfo.serviced;
	state.formData.mainPhone.enabled = state.regionInfo.serviced;
	state.formData.alternatePhone.enabled = state.regionInfo.serviced;
	state.formData.addressLine1.enabled = state.regionInfo.serviced;
	state.formData.addressLine2.enabled = state.regionInfo.serviced;
	state.formData.city.enabled = state.regionInfo.serviced;
	state.formData.state.enabled = state.regionInfo.serviced;

	state.formData.pre1978.enabled = state.regionInfo.serviced;
	state.formData.buildingType.enabled = state.regionInfo.serviced;
	state.formData.rentOwn.enabled = state.regionInfo.serviced;
	state.formData.emailAddress.enabled = state.regionInfo.serviced;
	state.formData.businessName.enabled = state.regionInfo.serviced;
};

const setStep2EnabledState = (state: ISelfGenFormState) => {
	state.formData.date.enabled = state.regionInfo.serviced;
	state.formData.time.enabled = state.regionInfo.serviced;
};

const setStateFromCity = (state: ISelfGenFormState) => {
	const zc = _.find(state.regionInfo.cities, c => c.city === state.formData.city.newValue) ?? null;
	state.formData.state.newValue = zc?.state ?? '';
};

const setDefaultCity = (state: ISelfGenFormState) => {
	const zc = _.find(state.regionInfo.cities, c => c.isPrimary) ?? null;
	state.formData.city.newValue = zc?.city ?? '';
	state.formData.state.newValue = zc?.state ?? '';
};

const setBusinessUnitFromBuildingType = (state: ISelfGenFormState, buildingTypes: IListValue[]) => {
	const lov_i = _.findIndex(buildingTypes, b => b.displayText === state.formData.buildingType.newValue);
	if (lov_i !== -1) {
		const lov = buildingTypes[lov_i];
		state.formData.businessUnit.newValue = lov.attribute1;
	}
};

const setProducts = (state: ISelfGenFormState) => {
	state.formData.products = _.map(state.regionInfo.products, p => ({
		product: p,
		checked: false
	}));
};

const setAreas = (state: ISelfGenFormState, rooms: IListValue[]) => {
	state.formData.areas = _.map(rooms, r => ({
		area: r,
		checked: false
	}));
};

const createRequest = (state: ISelfGenFormState, company: string, profile: ISiebelSalesRep): ISelfGenRequest => {
	return {
		address1: state.formData.addressLine1.newValue,
		address2: state.formData.addressLine2.newValue,
		altPhone: state.formData.alternatePhone.newValue,
		apptDate: state.formData.date.newValue,
		apptTime: state.formData.time.newValue,
		buildingType: state.formData.buildingType.newValue,
		businessUnit: state.formData.businessUnit.newValue,
		city: state.formData.city.newValue,
		email: state.formData.emailAddress.newValue,
		firstName: state.formData.firstName.newValue,
		integrationId: v4(),
		intersection: '',
		lastName: state.formData.lastName.newValue,
		mainPhone: state.formData.mainPhone.newValue,
		notes: '',
		organization: company,
		pre1978: state.formData.pre1978.newValue,
		rentOwn: state.formData.rentOwn.newValue,
		salesRepId: profile.repId ?? '',
		salesRepRowId: profile.rowId ?? '',
		serviceRegion: state.regionInfo.serviceRegion,
		state: state.formData.state.newValue,
		zipCode: state.formData.zipCode.newValue,
		products: _.filter(state.formData.products, p => p.checked).map(p => p.product),
		rooms: _.filter(state.formData.areas, a => a.checked).map(a => a.area.value),
		businessName: state.formData.businessName.newValue,
		promotionCode: state.formData.promotionCode,
		referringAccountNumber: state.formData.referringAccountNumber
	};
};

export const selfGenService = {
	createRequest,
	setErrorState,
	setEnabledState,
	zipValid,
	setNextEnabled,
	setStateFromCity,
	setDefaultCity,
	setProducts,
	setAreas,
	setBusinessUnitFromBuildingType
};
