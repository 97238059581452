import { api } from 'api/api';
import { IAppointment, IFormValue } from 'models';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { handleSagaError } from 'store/common';
import { actionCreator } from './slice';
import { messagingService } from 'services';
import { selectors } from './selectors';
import { IResolveAppointmentRequest, IResolveAppointmentResponse } from 'api/appointments/controller';
import { actionCreator as homeActionCreator } from 'store/home';
import { actionCreator as detailsActionCreator } from 'store/appointments/details';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.RESOLVE_START, onResolveStart);
}

function* onInitStart(action: ReturnType<typeof actionCreator.INIT_START>): any {
	try {
		const activityId: string = action.payload.activityId;
		const appointment: IAppointment = yield api.appointments.getByActivityId(activityId);

		yield put(
			actionCreator.INIT_COMPLETED({
				appointment
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onResolveStart(): any {
	try {
		const code: IFormValue<string> = yield select(selectors.getCode);
		const appointment: IAppointment = yield select(selectors.getAppointment);

		const request: IResolveAppointmentRequest = {
			activityId: appointment.activityId,
			code: code.newValue
		};

		const response: IResolveAppointmentResponse = yield api.appointments.postResolve(request);
		if (response.success) {
			yield all([
				put(actionCreator.INIT_FLUSH()),
				put(homeActionCreator.LOAD_START()),
				put(
					detailsActionCreator.NOTIFY_APPOINTMENT_CHANGED({
						rowId: appointment.rowId
					})
				)
			]);
			messagingService.popSnackbar('Appointment Resolved', 'success');
		} else {
			yield put(
				actionCreator.RESOLVE_COMPLETED({
					message: response.message
				})
			);
		}
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}
