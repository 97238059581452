import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.resolution.init,
	getOpen: (state: IGlobalState) => state.resolution.open,
	getAppointment: (state: IGlobalState) => state.resolution.appointment,
	getCode: (state: IGlobalState) => state.resolution.code,
	getResolveEnabled: (state: IGlobalState) => state.resolution.resolveEnabled,
	getResolveAction: (state: IGlobalState) => state.resolution.resolve,
	getMessage: (state: IGlobalState) => state.resolution.message
};
