import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.notifications.init,
	getUnreadCount: (state: IGlobalState) => state.notifications.unreadCount,
	getLastSync: (state: IGlobalState) => state.notifications.lastSync,
	getViewType: (state: IGlobalState) => state.notifications.viewType,
	getViewData: (state: IGlobalState) => state.notifications.viewData,
	getUpdateAction: (state: IGlobalState) => state.notifications.update
};
