import { EActionState, IAppointment, IFormValue, DefaultStringValue } from 'models';

export interface IState {
	open: boolean;
	init: EActionState;
	appointment: IAppointment | null;
	code: IFormValue<string>;
	resolveEnabled: boolean;
	resolve: EActionState;
	message: string;
}

export const initialState: IState = {
	open: false,
	init: EActionState.PENDING,
	appointment: null,
	code: DefaultStringValue,
	resolveEnabled: false,
	resolve: EActionState.PENDING,
	message: ''
};
