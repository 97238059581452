import { AxiosResponse } from 'axios';
import { ICustomerSearchResult } from 'models/customersearch';
import { post } from '../axiosprovider';

const postCustomerSearch = async (request: ICustomerSearchRequest): Promise<AxiosResponse<ICustomerSearchResult[]>> => {
	return await post<ICustomerSearchResult[]>('/api/cust/search', request);
};

export const customersController = {
	postCustomerSearch
};

export interface ICustomerSearchRequest {
	startDate: string;
	endDate: string;
	searchTerm: string;
}
