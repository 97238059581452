import { EActionState, IAnnouncement, ENotificationViewType } from 'models';

export interface IState {
	init: EActionState;
	load: EActionState;
	all: IAnnouncement[];
	unreadCount: number;
	lastSync: string | null;
	viewType: ENotificationViewType;
	viewData: IAnnouncement[];
	update: EActionState;
}

export const initialState: IState = {
	init: EActionState.PENDING,
	load: EActionState.PENDING,
	all: [],
	unreadCount: 0,
	lastSync: null,
	viewType: ENotificationViewType.ALL,
	viewData: [],
	update: EActionState.PENDING
};
