import { Alert, Box } from '@mui/material';
import { useLocation } from 'react-router';

export const UnauthorizedView = () => {
	const location = useLocation();
	return (
		<Box
			component='section'
			sx={{
				flex: 1,
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}>
			<Alert severity='error'>{`Unauthorized: ${location.pathname}`}</Alert>
		</Box>
	);
};
