export interface IAppInsightsClientConfiguration {
	disableAjaxTracking: boolean;
	disableExceptionTracking: boolean;
	disableFetchTracking: boolean;
	disableTelemetry: boolean;
	enableAutoRouteTracking: boolean;
}

export const DefaultAppInsightsClientConfiguration: IAppInsightsClientConfiguration = {
	disableAjaxTracking: false,
	disableExceptionTracking: false,
	disableFetchTracking: false,
	disableTelemetry: false,
	enableAutoRouteTracking: true
};
