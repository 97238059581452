import { AccountInfo } from '@azure/msal-browser';
import { LoaderBackdrop } from 'components';
import * as React from 'react';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/auth';
import { DefaultAuthErrorView } from './autherrorview';
import { EAuthError } from './constants';
import { authService, getAccount, getTokens, loginAccount, msalInstance } from './service';

interface IAuthProviderProps {
	children: React.ReactElement;
}

export const AuthProvider = (props: IAuthProviderProps): React.ReactElement | null => {
	const mountedRef = useRef(false);
	const dispatch = useDispatch();
	const isAuthenticated = useSelector(selectors.getIsAuthenticated);
	const authError = useSelector(selectors.getAuthError);

	useEffect(() => {
		msalInstance
			.handleRedirectPromise()
			.then(async response => {
				let account: AccountInfo | null;
				let redirectUrl: string | undefined = '';
				if (response) {
					account = response.account;
					redirectUrl = response.state;
				} else {
					account = getAccount();
				}

				if (account) {
					if (!mountedRef.current) {
						const tokens = await getTokens(account);
						if (!tokens) return;
						dispatch(
							actionCreator.AUTH_START({
								redirectUrl: redirectUrl ?? '',
								roles: authService.getRolesFromAccount(account),
								idToken: authService.parseToken(tokens.idToken)
							})
						);
						mountedRef.current = true;
					}
				} else {
					await loginAccount();
				}
			})
			.catch(error => {
				console.error(error);
				dispatch(
					actionCreator.AUTH_FAILED({
						error: EAuthError.MSAL_FAILURE
					})
				);
			});
	}, []);

	if (authError) {
		return <DefaultAuthErrorView />;
	}

	return isAuthenticated ? props.children : <LoaderBackdrop text='Logging In...' disableDrawerInset />;
};
