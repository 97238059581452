import { takeEvery } from 'redux-saga/effects';
import { messagingService } from 'services';
import { actionCreator } from './slice';

export function* saga(): any {
	yield takeEvery(actionCreator.CREATE_SNACKBAR, onCreateSnackbar);
}

function* onCreateSnackbar(action: ReturnType<typeof actionCreator.CREATE_SNACKBAR>): any {
	messagingService.popSnackbar(action.payload.message, action.payload.variant);
}
