import { LoaderBackdrop, Page } from 'components';
import { Suspense } from 'react';
import { Route, Routes } from 'react-router';
import { AccountRoutes } from 'views/account/routes';
import { SelfGenRoutes } from 'views/selfgen/routes';
import { CustomersRoutes } from 'views/customers/routes';
import { NotificationsRoutes } from 'views/notifications/routes';
import { AppointmentRoutes } from 'views/appointments/routes';
import Home from 'views/home/main';

const HomeView = <Page title='Home' component={Home} />;

export const AppRoutes = () => {
	return (
		<Suspense fallback={<LoaderBackdrop text={'Loading Application...'} />}>
			<Routes>
				<Route index element={HomeView} />
				{AccountRoutes}
				{SelfGenRoutes}
				{CustomersRoutes}
				{NotificationsRoutes}
				{AppointmentRoutes}
			</Routes>
		</Suspense>
	);
};
