export interface IFormValue<T> {
	value: T;
	newValue: T;
	dirty: boolean;
	error: boolean;
	message: string;
	enabled: boolean;
}

export const DefaultStringValue: IFormValue<string> = {
	dirty: false,
	error: false,
	value: '',
	newValue: '',
	message: '',
	enabled: true
};

export const DefaultNumberValue: IFormValue<number> = {
	dirty: false,
	error: false,
	value: 0,
	newValue: 0,
	message: '',
	enabled: true
};
