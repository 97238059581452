import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { EActionState, IAnnouncement, ENotificationViewType } from 'models';
import { initialState } from './state';
import { notificationService } from 'services';

const slice = createSlice({
	name: '@@notifications',
	initialState,
	reducers: {
		INIT_START: state => {
			state.init = EActionState.RUNNING;
			state.all = [];
			notificationService.setUnreadCount(state);
		},
		INIT_COMPLETED: (
			state,
			action: PayloadAction<{
				all: IAnnouncement[];
			}>
		) => {
			state.init = EActionState.COMPLETED;
			notificationService.processUpdates(state, action.payload.all);
			notificationService.setViewData(state);
			notificationService.setUnreadCount(state);
			notificationService.setLastSync(state);
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
			state.all = [];
			notificationService.setUnreadCount(state);
			notificationService.setLastSync(state);
		},
		LOAD_START: state => {
			state.load = EActionState.RUNNING;
		},
		LOAD_COMPLETED: (
			state,
			action: PayloadAction<{
				updated: IAnnouncement[];
			}>
		) => {
			state.load = EActionState.COMPLETED;
			notificationService.processUpdates(state, action.payload.updated);
			notificationService.setViewData(state);
			notificationService.setUnreadCount(state);
			notificationService.setLastSync(state);
		},
		LOAD_FAILED: state => {
			state.load = EActionState.FAILED;
		},
		SET_SELECTED: (
			state,
			action: PayloadAction<{
				id: number;
				selected: boolean;
			}>
		) => {
			notificationService.setSelected(state, action.payload.id, action.payload.selected);
			notificationService.setViewData(state);
		},
		SET_VIEW_TYPE: (state, action: PayloadAction<ENotificationViewType>) => {
			state.viewType = action.payload;
			notificationService.resetSelected(state);
			notificationService.setViewData(state);
		},
		UPDATE_START: state => {
			state.update = EActionState.RUNNING;
		},
		UPDATE_COMPLETED: (
			state,
			action: PayloadAction<{
				updated: IAnnouncement[];
			}>
		) => {
			state.update = EActionState.COMPLETED;
			notificationService.processUpdates(state, action.payload.updated);
			notificationService.setViewData(state);
			notificationService.setUnreadCount(state);
			notificationService.setLastSync(state);
		},
		UPDATE_FAILED: state => {
			state.update = EActionState.FAILED;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
