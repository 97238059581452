import { AuthProvider } from 'auth';
import { SplashScreen } from 'components';
import { EActionState } from 'models';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/application';

export const StartupProvider = (props: { children: JSX.Element }) => {
	return (
		<AuthProvider>
			<InitProvider>{props.children}</InitProvider>
		</AuthProvider>
	);
};

const InitProvider = (props: { children: JSX.Element }) => {
	const initAction = useSelector(selectors.getInitAction);
	const dispatch = useDispatch();
	useEffect(() => {
		if (initAction === EActionState.PENDING) {
			dispatch(actionCreator.INIT_START());
		}
	}, [dispatch, initAction]);
	if (initAction === EActionState.COMPLETED) {
		return props.children;
	}
	return <SplashScreen />;
};
