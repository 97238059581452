import { api } from 'api/api';
import { EMobileStatus, IAppointment, IListValue } from 'models';
import { all, put, takeLatest, select } from 'redux-saga/effects';
import { handleSagaError } from 'store/common';
import { actionCreator } from './slice';
import { selectors } from './selectors';
import { appointmentService, messagingService } from 'services';
import { IGetOpportunityNotesResponse, IUpsertOpportunityNoteRequest, IUpsertOpportunityNoteResponse } from 'api/notes/controller';
import { IGetOpportunityContractResponse } from 'api/contracts/controller';
import { IGetOpportunityQuoteResponse } from 'api/quotes/controller';

export function* saga(): any {
	yield takeLatest(actionCreator.INIT_START, onInitStart);
	yield takeLatest(actionCreator.LOAD_ALL_START, onLoadAllStart);
	yield takeLatest(actionCreator.REFRESH_ALL_START, onRefreshAllStart);
	yield takeLatest(actionCreator.LOAD_APPOINTMENT_START, onLoadAppointmentStart);
	yield takeLatest(actionCreator.LOAD_NOTES_START, onLoadNotesStart);
	yield takeLatest(actionCreator.LOAD_QUOTES_START, onLoadQuotesStart);
	yield takeLatest(actionCreator.LOAD_CONTRACT_START, onLoadContractStart);
	yield takeLatest(actionCreator.LOAD_CHANGE_ORDERS_START, onLoadChangeOrdersStart);
	yield takeLatest(actionCreator.REFRESH_APPOINTMENT_START, onRefreshAppointmentStart);
	yield takeLatest(actionCreator.REFRESH_NOTES_START, onRefreshNotesStart);
	yield takeLatest(actionCreator.REFRESH_QUOTES_START, onRefreshQuotesStart);
	yield takeLatest(actionCreator.REFRESH_CONTRACT_START, onRefreshContractStart);
	yield takeLatest(actionCreator.REFRESH_CHANGE_ORDERS_START, onRefreshChangeOrdersStart);
	yield takeLatest(actionCreator.ACCEPT_START, onAcceptStart);
	yield takeLatest(actionCreator.CREATE_NOTE_SAVE_START, onCreateNoteSaveStart);

	yield takeLatest(actionCreator.NOTIFY_APPOINTMENT_CHANGED, onNotifyAppointmentChanged);
}

function* onNotifyAppointmentChanged(action: ReturnType<typeof actionCreator.NOTIFY_APPOINTMENT_CHANGED>): any {
	const rowId: string = yield select(selectors.getRowId);
	if (rowId === action.payload.rowId) {
		yield put(actionCreator.REFRESH_ALL_START());
	}
}

function* onInitStart(): any {
	try {
		const states: IListValue[] = yield api.lov.getByValueType('State');
		yield all([put(actionCreator.INIT_COMPLETED({ states: states })), put(actionCreator.LOAD_ALL_START())]);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.INIT_FAILED()]);
	}
}

function* onLoadAllStart(): any {
	yield all([
		put(actionCreator.LOAD_APPOINTMENT_START()),
		put(actionCreator.LOAD_NOTES_START()),
		put(actionCreator.LOAD_QUOTES_START()),
		put(actionCreator.LOAD_CONTRACT_START()),
		put(actionCreator.LOAD_CHANGE_ORDERS_START())
	]);
	yield put(actionCreator.LOAD_ALL_COMPLETED());
}

function* onRefreshAllStart(): any {
	yield all([
		put(actionCreator.REFRESH_APPOINTMENT_START()),
		put(actionCreator.REFRESH_NOTES_START()),
		put(actionCreator.REFRESH_QUOTES_START()),
		put(actionCreator.REFRESH_CONTRACT_START()),
		put(actionCreator.REFRESH_CHANGE_ORDERS_START())
	]);
	yield put(actionCreator.REFRESH_ALL_COMPLETED());
}

function* onLoadAppointmentStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const appointment: IAppointment = yield api.appointments.getByRowId(rowId);
		const appointmentState = appointmentService.buildStateModel(appointment);
		yield put(
			actionCreator.LOAD_APPOINTMENT_COMPLETED({
				data: appointmentState
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_APPOINTMENT_FAILED()]);
	}
}

function* onLoadNotesStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const notes: IGetOpportunityNotesResponse = yield api.notes.getOpportunityNotes(rowId);
		yield put(
			actionCreator.LOAD_NOTES_COMPLETED({
				notes: notes.notes
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_NOTES_FAILED()]);
	}
}

function* onLoadQuotesStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const response: IGetOpportunityQuoteResponse = yield api.quotes.getOpportunityQuote(rowId);
		yield put(
			actionCreator.LOAD_QUOTES_COMPLETED({
				quotes: response.quoteDisposition?.quoteOptions ?? []
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_QUOTES_FAILED()]);
	}
}

function* onLoadContractStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const contract: IGetOpportunityContractResponse = yield api.contracts.getOpportunityContract(rowId);
		yield put(
			actionCreator.LOAD_CONTRACT_COMPLETED({
				header: contract.header
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_CONTRACT_FAILED()]);
	}
}

function* onLoadChangeOrdersStart(): any {
	try {
		yield put(actionCreator.LOAD_CHANGE_ORDERS_COMPLETED());
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.LOAD_CHANGE_ORDERS_FAILED()]);
	}
}

function* onRefreshAppointmentStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const appointment: IAppointment = yield api.appointments.getByRowId(rowId);
		const appointmentState = appointmentService.buildStateModel(appointment);
		yield put(
			actionCreator.REFRESH_APPOINTMENT_COMPLETED({
				data: appointmentState
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.REFRESH_APPOINTMENT_FAILED()]);
	}
}

function* onRefreshNotesStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const notes: IGetOpportunityNotesResponse = yield api.notes.getOpportunityNotes(rowId);
		yield put(
			actionCreator.REFRESH_NOTES_COMPLETED({
				notes: notes.notes
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.REFRESH_NOTES_FAILED()]);
	}
}

function* onRefreshQuotesStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const response: IGetOpportunityQuoteResponse = yield api.quotes.getOpportunityQuote(rowId);
		yield put(
			actionCreator.REFRESH_QUOTES_COMPLETED({
				quotes: response.quoteDisposition?.quoteOptions ?? []
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.REFRESH_QUOTES_FAILED()]);
	}
}

function* onRefreshContractStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const contract: IGetOpportunityContractResponse = yield api.contracts.getOpportunityContract(rowId);
		yield put(
			actionCreator.REFRESH_CONTRACT_COMPLETED({
				header: contract.header
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.REFRESH_CONTRACT_FAILED()]);
	}
}

function* onRefreshChangeOrdersStart(): any {
	try {
		yield put(actionCreator.REFRESH_CHANGE_ORDERS_COMPLETED());
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.REFRESH_CHANGE_ORDERS_FAILED()]);
	}
}

function* onAcceptStart(): any {
	try {
		const rowId: string = yield select(selectors.getRowId);
		const appointment: IAppointment = yield api.appointments.updateMobileStatus({
			rowId: rowId,
			mobileStatus: EMobileStatus.ACCEPTED
		});
		const appointmentState = appointmentService.buildStateModel(appointment);
		yield put(
			actionCreator.ACCEPT_COMPLETED({
				appointment: appointmentState
			})
		);
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.ACCEPT_FAILED()]);
	}
}

function* onCreateNoteSaveStart(): any {
	try {
		const appointment: IAppointment = yield select(selectors.appointment.getAppointment);
		const note: string = yield select(selectors.notes.dialog.getNote);
		const request: IUpsertOpportunityNoteRequest = {
			company: appointment.company,
			opportunityRowId: appointment.opportunityRowId,
			note: note
		};
		const response: IUpsertOpportunityNoteResponse = yield api.notes.upsertOpportunityNote(request);
		if (response.success) {
			messagingService.popSnackbar('Note Saved', 'success');
			yield all([put(actionCreator.CREATE_NOTE_CLOSE()), put(actionCreator.REFRESH_NOTES_START())]);
		} else {
			yield put(
				actionCreator.CREATE_NOTE_SAVE_COMPLETED({
					message: response.message
				})
			);
		}
	} catch (error: any) {
		yield handleSagaError(error, [actionCreator.CREATE_NOTE_SAVE_FAILED()]);
	}
}
