import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { EActionState, IAppointmentCombinedState } from 'models';
import { appointmentService } from 'services';
import { initialState } from './state';

const slice = createSlice({
	name: '@@home',
	initialState,
	reducers: {
		INIT_START: state => {
			state.init = EActionState.RUNNING;
		},
		INIT_COMPLETED: state => {
			state.init = EActionState.COMPLETED;
		},
		INIT_FAILED: state => {
			state.init = EActionState.FAILED;
		},
		LOAD_START: state => {
			state.load = EActionState.RUNNING;
		},
		LOAD_COMPLETED: (
			state,
			action: PayloadAction<{
				appointments: IAppointmentCombinedState[];
			}>
		) => {
			state.load = EActionState.COMPLETED;
			const { today, future } = appointmentService.getRowIds(action.payload.appointments);
			state.todayAppointmentIds = today;
			state.futureAppointmentIds = future;
			state.appointments = action.payload.appointments;

			state.showFutureLock = appointmentService.getFutureLock(state.appointments);
			state.showRequireLeadCompletion = appointmentService.getRequireLeadCompletion(state.appointments);
		},
		LOAD_FAILED: state => {
			state.load = EActionState.FAILED;
		},
		ACCEPT_START: (state, action: PayloadAction<{ rowId: string }>) => {
			const a_i = _.findIndex(state.appointments, a => a.rowId === action.payload.rowId);
			if (a_i != -1) {
				state.appointments[a_i].accept = EActionState.RUNNING;
			}
		},
		ACCEPT_COMPLETED: (state, action: PayloadAction<{ rowId: string; appointment: IAppointmentCombinedState }>) => {
			const a_i = _.findIndex(state.appointments, a => a.rowId === action.payload.rowId);
			if (a_i != -1) {
				state.appointments[a_i] = action.payload.appointment;
			}
		},
		ACCEPT_FAILED: (state, action: PayloadAction<{ rowId: string }>) => {
			const a_i = _.findIndex(state.appointments, a => a.rowId === action.payload.rowId);
			if (a_i != -1) {
				state.appointments[a_i].accept = EActionState.PENDING;
			}
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
