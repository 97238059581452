//import { api } from 'api/api';

import { IStartupResponse } from 'api/startup/controller';
import axios, { AxiosResponse } from 'axios';

const ctx: Worker = self as any; // eslint-disable-line no-restricted-globals

export const COMMANDS = {
	START: 'START_VERSION_CHECK_WORKER',
	STOP: 'STOP_VERSION_CHECK_WORKER',
	MESSAGE: 'VERSION_CHECK_WORKER_MESSAGE'
};

export interface IMessagePayload {
	type: string;
	buildVersion: string;
	versionCheckInterval: number;
}

ctx.addEventListener('message', (e: MessageEvent<IMessagePayload>) => {
	switch (e.data.type) {
		case COMMANDS.START: {
			startWorker(e.data.versionCheckInterval);
			break;
		}

		case COMMANDS.STOP: {
			stopWorker();
			break;
		}
	}
});

const interval: number | NodeJS.Timeout | null = null;

const startWorker = (interval: number | NodeJS.Timeout) => {
	onTick();
	setInterval(onTick, interval as number);
};

const stopWorker = () => {
	if (interval) {
		clearInterval(interval);
	}
};

const onTick = async () => {
	try {
		const response: AxiosResponse<IStartupResponse> = await axios.get('/api/startup');
		if (response.status === 200) {
			ctx.postMessage({
				type: COMMANDS.MESSAGE,
				success: true,
				data: response.data.buildVersion
			});
		} else {
			ctx.postMessage({
				type: COMMANDS.MESSAGE,
				success: false,
				data: null
			});
		}
	} catch (error) {
		console.error(error);
	}
};
