import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EActionState, IAppointment } from 'models';
import { initialState } from './state';
import { referralProspectService } from 'services/referralprospectservice';

const slice = createSlice({
	name: '@@referralProspect',
	initialState,
	reducers: {
		SET_FORM_OPEN: (state, action: PayloadAction<IAppointment>) => {
			state.form.open = true;
			state.form.formData.accountId = action.payload.accountName;
			state.form.formData.opportunityId = action.payload.name;
			referralProspectService.setErrorState(state.form);
			referralProspectService.setEnabledState(state.form);
		},
		SET_FORM_CLOSE: state => {
			state.form = initialState.form;
		},
		SET_PROPERTY: (state, action: PayloadAction<{ prop: string; value: string }>) => {
			state.form.formData[action.payload.prop].newValue = action.payload.value;
			referralProspectService.setErrorState(state.form);
			referralProspectService.setEnabledState(state.form);
		},
		SUBMIT_REFERRAL_START: state => {
			state.form.submit.actionState = EActionState.RUNNING;
		},
		SUBMIT_REFERRAL_COMPLETED: state => {
			state.form.submit.actionState = EActionState.COMPLETED;
		},
		SUBMIT_REFERRAL_FAILED: state => {
			state.form.submit.actionState = EActionState.FAILED;
		},
		CREATE_SELF_GEN_REFERRAL: (state, action: PayloadAction<string>) => {
			state.form.formData.accountId = action.payload;
		}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
