import { EAuthError, ERole } from 'auth';
import { ISiebelSalesRep } from 'models';
import { IIdToken } from 'models/token';

export interface IState {
	isAuthenticated: boolean;
	error: EAuthError | null;
	salesRep: ISiebelSalesRep | null;
	roles: ERole[];
	idToken: IIdToken | null;
}

export const initialState: IState = {
	isAuthenticated: false,
	error: null,
	salesRep: null,
	roles: [],
	idToken: null
};
