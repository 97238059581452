import { AxiosResponse } from 'axios';
import { get, post } from '../axiosprovider';
import { IZipCodeCity } from 'models';

const getRegionInfo = async (zipCode: string): Promise<AxiosResponse<IRegionInfoResponse>> => {
	return await get<IRegionInfoResponse>(`/api/selfgen/region/${zipCode}`);
};

const postSelfGen = async (request: ISelfGenRequest): Promise<AxiosResponse> => {
	return await post('/api/selfgen', request);
};

export const selfGenController = {
	getRegionInfo,
	postSelfGen
};

export interface IRegionInfoResponse {
	businessUnit: string;
	cities: IZipCodeCity[];
	company: string;
	products: string[];
	serviced: boolean;
	serviceRegion: string;
	validZip: boolean;
}

export interface ISelfGenRequest {
	address1: string;
	address2: string;
	altPhone: string;
	apptDate: string;
	apptTime: string;
	buildingType: string;
	businessName: string;
	businessUnit: string;
	city: string;
	email: string;
	firstName: string;
	integrationId: string;
	intersection: string;
	lastName: string;
	mainPhone: string;
	notes: string;
	organization: string;
	pre1978: string;
	products: string[];
	promotionCode: string;
	referringAccountNumber: string;
	rentOwn: string;
	rooms: string[];
	salesRepId: string;
	salesRepRowId: string;
	serviceRegion: string;
	state: string;
	zipCode: string;
}
