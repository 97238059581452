const formatCurrency = (amount: number): string => {
	try {
		const formatter = new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD'
		});
		return formatter.format(amount);
	} catch (error) {
		return '';
	}
};

export const currencyUtil = {
	formatCurrency
};
