import { AxiosResponse } from 'axios';
import { IHeader } from 'models';
import { get } from '../axiosprovider';

const getOpportunityContract = async (rowId: string): Promise<AxiosResponse<IGetOpportunityContractResponse>> => {
	return await get<IGetOpportunityContractResponse>(`/api/contracts/${rowId}`);
};

export const contractsController = {
	getOpportunityContract
};

export interface IGetOpportunityContractResponse {
	header: IHeader;
}
