import {
	mdiAsterisk,
	mdiBellRingOutline,
	mdiBookOpenPageVariantOutline,
	mdiChartBoxOutline,
	mdiClockTimeFourOutline,
	mdiCogOutline,
	mdiHelp,
	mdiHomeOutline,
	mdiPencilOutline
} from '@mdi/js';
import { Icon } from '@mdi/react';
import { Box, Chip, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { ERole } from 'auth';
import { PermWrapper } from 'components';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { actionCreator } from 'store/application';
import { selectors } from 'store/notifications';
import { selectors as appSelectors } from 'store/application';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import { AccountFooter } from './accountfooter';
import { DrawerHeader } from './drawerheader';

const MainNav = styled(List)<{ component?: React.ElementType }>({
	flex: 1,
	display: 'flex',
	flexDirection: 'column',

	'& .MuiListItemButton-root': {
		paddingLeft: 8,
		paddingRight: 8
	},
	'& .MuiListItemIcon-root': {
		minWidth: 0,
		marginRight: 8
	},
	'& .MuiSvgIcon-root': {
		fontSize: 20
	}
});

export const DrawerContainer = () => {
	return (
		<>
			<MainNav component='nav' disablePadding>
				<DrawerHeader />
				<Box sx={{ height: '14px' }} />
				<InternalLinks />
				<ExternalLinks />
				<Box sx={{ flex: 1 }} />
				<Divider />
				<AccountFooter />
			</MainNav>
		</>
	);
};

const InternalLinks = () => {
	const count = useSelector(selectors.getUnreadCount);
	return (
		<Box
			sx={{
				width: '100%'
			}}>
			<PermWrapper allowedRoles={[ERole.ROLE_OSE]}>
				<NavigationListItemLink label='Home' to='/' exact icon={mdiHomeOutline} />
			</PermWrapper>
			<PermWrapper allowedRoles={[ERole.ROLE_OSE]}>
				<NavigationListItemLink label='Create Self Gen' to='/selfgen/create' icon={mdiAsterisk} />
			</PermWrapper>
			<PermWrapper allowedRoles={[ERole.ROLE_OSE]}>
				<NavigationListItemLink label='Notifications' to='/notifications' icon={mdiBellRingOutline} note={count > 0 ? count : undefined} />
			</PermWrapper>
			<PermWrapper allowedRoles={[ERole.ROLE_OSE]}>
				<NavigationListItemLink label='Appointments' to='/appointments' icon={mdiClockTimeFourOutline} />
			</PermWrapper>
		</Box>
	);
};

const ExternalLinks = () => {
	const pcof = useSelector(appSelectors.urls.getPCOF);
	const prpt = useSelector(appSelectors.urls.getPRPT);
	const help = useSelector(appSelectors.urls.getHelp);
	const bridge = useSelector(appSelectors.urls.getBridge);
	return (
		<Box
			sx={{
				width: '100%',
				marginTop: 2
			}}>
			<SectionTitle title='Other Resources' subtitle='' />
			<NavigationListItemLink label='Precision Reporting' to={prpt} icon={mdiChartBoxOutline} external />
			<NavigationListItemLink label='Precision COF' to={pcof} icon={mdiPencilOutline} external />
			<NavigationListItemLink label='Empire Help' to={help} icon={mdiHelp} external />
			<NavigationListItemLink label='Bridge' to={bridge} icon={mdiBookOpenPageVariantOutline} external />
		</Box>
	);
};

const SectionTitle = (props: { title: string; subtitle: string }) => {
	const { title, subtitle } = props;
	return (
		<ListItemText
			primary={title}
			primaryTypographyProps={{
				fontSize: 14,
				fontWeight: 'medium',
				lineHeight: '20px',
				mb: '12px',
				ml: 3,
				mt: '12px',
				color: theme => alpha(theme.palette.common.white, 0.8)
			}}
			secondary={subtitle}
			secondaryTypographyProps={{
				noWrap: true,
				fontSize: 12,
				color: theme => theme.palette.grey[500]
			}}
			sx={{ my: 0 }}
		/>
	);
};

const NavigationListItemLink = (props: { to: string; label: string; icon: string; exact?: boolean; note?: number | string; external?: boolean }) => {
	const { to, label, icon, exact, note, external } = props;
	const location = useLocation();
	const dispatch = useDispatch();
	const breadcrumbs = useBreadcrumbs();
	const exists = _.some(breadcrumbs, bc => bc.key === to);
	const selected = location.pathname === to || (!exact && exists);
	const onNavigate = () => {
		dispatch(actionCreator.SET_DRAWER_OPEN(false));
	};

	return (
		<ListItem
			key={label}
			secondaryAction={
				note && (
					<Chip label={note} sx={{ color: theme => theme.palette.common.white, backgroundColor: theme => alpha(theme.palette.primary.light, 0.4) }} />
				)
			}
			disablePadding>
			<ListItemButton
				selected={selected}
				component={RouterLink}
				to={to}
				target={external ? '_blank' : undefined}
				onClick={onNavigate}
				sx={{
					py: 0,
					minHeight: 40,
					margin: 1,
					marginBottom: 0,
					marginTop: 0,
					borderRadius: 2,
					'&.MuiListItemButton-root': {
						paddingLeft: 2
					},
					color: theme => alpha(theme.palette.common.white, 0.8),
					'&.Mui-selected': {
						color: theme => theme.palette.common.white,
						backgroundColor: theme => theme.palette.primary.light,
						'&:hover': {
							color: theme => theme.palette.common.white,
							backgroundColor: theme => alpha(theme.palette.primary.light, 0.9)
						}
					}
				}}>
				<ListItemIcon sx={{ color: 'inherit' }}>
					<Icon size={1} path={icon} />
				</ListItemIcon>
				<ListItemText
					primary={label}
					primaryTypographyProps={{
						fontSize: 14,
						fontWeight: 'medium',
						marginLeft: 1
					}}
				/>
			</ListItemButton>
		</ListItem>
	);
};
