import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { actionCreator, selectors } from 'store/messaging';

export const MessageManager = () => {
	const dispatch = useDispatch();
	const message = useSelector(selectors.getNextMessage);
	if (message === null || message === undefined) return null;
	const handleClose = () => {
		dispatch(actionCreator.DISMISS_MESSAGE(message.id));
	};
	return (
		<Dialog fullWidth maxWidth={'md'} onClose={handleClose} open={true}>
			<DialogTitle>{message.title}</DialogTitle>
			<DialogContent>
				<DialogContentText
					sx={{
						display: 'flex',
						alignItems: 'center',
						fontSize: message.largeText ? '3em' : 'unset'
					}}>
					{message.message}
				</DialogContentText>
				{message.data && (
					<DialogContentText
						sx={{
							display: 'flex',
							alignItems: 'center'
						}}>
						{message.data}
					</DialogContentText>
				)}
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary' variant='contained'>
					Ok
				</Button>
			</DialogActions>
		</Dialog>
	);
};
