import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';
import createSagaMiddleware from 'redux-saga';
import { InitialState, reducers, sagas, actionCreators } from 'store';

export const buildStore = (): { store; history } => {
	const { createReduxHistory, routerMiddleware, routerReducer } = createReduxHistoryContext({ history: createBrowserHistory() });
	const sagaMiddleware = createSagaMiddleware();

	const store = configureStore({
		reducer: combineReducers({
			...reducers,
			router: routerReducer
		}),
		preloadedState: InitialState,
		devTools:
			process.env.NODE_ENV == 'production'
				? false
				: {
						name: 'Precision Sales',
						actionCreators: {
							...actionCreators.application,
							...actionCreators.appointments.details,
							...actionCreators.appointments.search,
							...actionCreators.arrivalNotice,
							...actionCreators.auth,
							...actionCreators.customers.search,
							...actionCreators.home,
							...actionCreators.messaging,
							...actionCreators.notifications,
							...actionCreators.referralProspect,
							...actionCreators.resolution,
							...actionCreators.selfGen
						}
				  },
		middleware: [routerMiddleware, sagaMiddleware]
	});

	sagaMiddleware.run(sagas.application);
	sagaMiddleware.run(sagas.appointmentDetails);
	sagaMiddleware.run(sagas.appointmentSearch);
	sagaMiddleware.run(sagas.arrivalNotice);
	sagaMiddleware.run(sagas.auth);
	sagaMiddleware.run(sagas.customerSearch);
	sagaMiddleware.run(sagas.home);
	sagaMiddleware.run(sagas.messaging);
	sagaMiddleware.run(sagas.notifications);
	sagaMiddleware.run(sagas.referralProspect);
	sagaMiddleware.run(sagas.resolution);
	sagaMiddleware.run(sagas.selfGen);

	const history = createReduxHistory(store);

	return { store, history };
};
