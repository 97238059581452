import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import { IDialog, IMessage, IProgress, ISnackBar } from './models';
import { initialState } from './state';

const slice = createSlice({
	name: '@@messaging',
	initialState,
	reducers: {
		CREATE_DIALOG: (state, action: PayloadAction<IDialog>) => {
			state.dialogs.push(action.payload);
		},
		CREATE_MESSAGE: (state, action: PayloadAction<IMessage>) => {
			state.messages.push(action.payload);
		},
		CREATE_PROGRESS: (state, action: PayloadAction<IProgress>) => {
			state.progress = action.payload;
		},
		DISMISS_DIALOG: (state, action: PayloadAction<string>) => {
			_.remove(state.dialogs, { id: action.payload });
		},
		DISMISS_MESSAGE: (state, action: PayloadAction<string>) => {
			_.remove(state.messages, { id: action.payload });
		},
		DISMISS_PROGRESS: state => {
			state.progress = null;
		},
		UPDATE_PROGRESS: (state, action: PayloadAction<IProgress>) => {
			if (state.progress) {
				state.progress.value = action.payload.value;
			}
		},
		UPDATE_PROGRESS_MESSAGE: (state, action: PayloadAction<string>) => {
			if (state.progress) {
				state.progress.title = action.payload;
			}
		},
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		CREATE_SNACKBAR: (state, action: PayloadAction<ISnackBar>) => {}
	}
});

export const { reducer } = slice;
export const actionCreator = slice.actions;
