import { EActionState, IKnobs, IMap, IUrls } from 'models';
import { IAppInsightsClientConfiguration, DefaultAppInsightsClientConfiguration } from 'models';

export interface IState {
	init: EActionState;
	pageTitle: string;
	drawer: {
		open: boolean;
	};
	connectionString: string;
	appInsightsClientConfiguration: IAppInsightsClientConfiguration;
	buildVersion: string;
	versionCheckInterval: number;
	knobs: IKnobs;
	map: IMap;
	urls: IUrls;
}

export const initialState: IState = {
	init: EActionState.PENDING,
	pageTitle: '',
	drawer: {
		open: false
	},
	connectionString: '',
	appInsightsClientConfiguration: DefaultAppInsightsClientConfiguration,
	buildVersion: '',
	versionCheckInterval: 600000,
	knobs: {
		notificationSyncInterval: 30,
		notificationSyncBuffer: 5,
		appointmentSyncInterval: 30,
		appointmentSyncBuffer: 5,
		appointmentSyncTimer: false
	},
	map: {
		key: ''
	},
	urls: {
		prpt: '',
		pcof: '',
		help: '',
		bridge: ''
	}
};
