import { IAppointment } from 'models';
import { EDeviceType } from 'hooks';

const buildMapUrl = (appointment: IAppointment, deviceType: EDeviceType): string => {
	const encodedAddress = encodeURIComponent(appointment.displayAddress).replaceAll('%20', '+');
	switch (deviceType) {
		case EDeviceType.ANDROID:
			return `geo:0,0?q=${encodedAddress}`;
		case EDeviceType.IOS:
			return `http://maps.apple.com/?daddr=${encodedAddress}`;
		default:
			return `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;
	}
};

const buildSMSUrl = (phoneNumber: string): string => {
	return `sms:${phoneNumber}`;
};

const buildEmailUrl = (email: string): string => {
	return `mailto:${email}`;
};

const buildCallUrl = (phoneNumber: string): string => {
	return `tel:${phoneNumber}`;
};

export const urlService = {
	buildMapUrl,
	buildSMSUrl,
	buildEmailUrl,
	buildCallUrl
};
