import { Notifications as NotificationsIcon } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, IconButton } from '@mui/material';
import { selectors } from 'store/notifications';
import { push } from 'redux-first-history';

export const NotificationButton = () => {
	const dispatch = useDispatch();
	const count = useSelector(selectors.getUnreadCount);
	const handleNotificationClick = () => {
		dispatch(push('/notifications'));
	};
	return (
		<IconButton color='inherit' edge='end' onClick={handleNotificationClick} sx={{ ml: 2, mr: 1, display: { sm: 'none' } }}>
			<Badge badgeContent={count} color='primary'>
				<NotificationsIcon color='action' />
			</Badge>
		</IconButton>
	);
};
