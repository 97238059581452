import { IGlobalState } from 'store';

export const selectors = {
	getInitAction: (state: IGlobalState) => state.selfGen.init,
	getCompany: (state: IGlobalState) => state.selfGen.company,
	getBuildingTypes: (state: IGlobalState) => state.selfGen.buildingTypes,
	getTimeWindows: (state: IGlobalState) => state.selfGen.timeWindows,
	getRooms: (state: IGlobalState) => state.selfGen.rooms,
	getRentOwnOptions: (state: IGlobalState) => state.selfGen.rentOwnOptions,
	getPre1978Options: (state: IGlobalState) => state.selfGen.pre1978Options,
	form: {
		get: (state: IGlobalState) => state.selfGen.form,
		regionInfo: {
			get: (state: IGlobalState) => state.selfGen.form.regionInfo,
			getBusinessUnit: (state: IGlobalState) => state.selfGen.form.regionInfo.businessUnit,
			getServiceRegion: (state: IGlobalState) => state.selfGen.form.regionInfo.serviceRegion,
			getProducts: (state: IGlobalState) => state.selfGen.form.regionInfo.products,
			getServiced: (state: IGlobalState) => state.selfGen.form.regionInfo.serviced,
			getZipValid: (state: IGlobalState) => state.selfGen.form.regionInfo.validZip,
			getCities: (state: IGlobalState) => state.selfGen.form.regionInfo.cities
		},
		wizard: {
			getStep: (state: IGlobalState) => state.selfGen.form.wizard.step,
			getPreviousEnabled: (state: IGlobalState) => state.selfGen.form.wizard.previousEnabled,
			getNextEnabled: (state: IGlobalState) => state.selfGen.form.wizard.nextEnabled
		},
		activity: {
			step1: {
				getNextEnabled: (state: IGlobalState) => state.selfGen.form.activity.step1.nextEnabled,
				getNextAction: (state: IGlobalState) => state.selfGen.form.activity.step1.next
			},
			step2: {
				getNextEnabled: (state: IGlobalState) => state.selfGen.form.activity.step2.nextEnabled,
				getNextAction: (state: IGlobalState) => state.selfGen.form.activity.step2.next
			},
			step3: {
				getNextEnabled: (state: IGlobalState) => state.selfGen.form.activity.step3.nextEnabled,
				getNextAction: (state: IGlobalState) => state.selfGen.form.activity.step3.next
			},
			step4: {
				getSubmitEnabled: (state: IGlobalState) => state.selfGen.form.activity.step4.submitEnabled,
				getSubmitAction: (state: IGlobalState) => state.selfGen.form.activity.step4.submit
			},
			getLoadRegionEnabled: (state: IGlobalState) => state.selfGen.form.activity.loadRegionEnabled,
			getLoadRegionAction: (state: IGlobalState) => state.selfGen.form.activity.loadRegion
		},
		formData: {
			get: (state: IGlobalState) => state.selfGen.form.formData,
			getFirstName: (state: IGlobalState) => state.selfGen.form.formData.firstName,
			getLastName: (state: IGlobalState) => state.selfGen.form.formData.lastName,
			getMainPhone: (state: IGlobalState) => state.selfGen.form.formData.mainPhone,
			getAlternatePhone: (state: IGlobalState) => state.selfGen.form.formData.alternatePhone,
			getAddressLine1: (state: IGlobalState) => state.selfGen.form.formData.addressLine1,
			getAddressLine2: (state: IGlobalState) => state.selfGen.form.formData.addressLine2,
			getCity: (state: IGlobalState) => state.selfGen.form.formData.city,
			getState: (state: IGlobalState) => state.selfGen.form.formData.state,
			getZipCode: (state: IGlobalState) => state.selfGen.form.formData.zipCode,
			getEmailAddress: (state: IGlobalState) => state.selfGen.form.formData.emailAddress,
			getPre1978: (state: IGlobalState) => state.selfGen.form.formData.pre1978,
			getBuildingType: (state: IGlobalState) => state.selfGen.form.formData.buildingType,
			getRentOwn: (state: IGlobalState) => state.selfGen.form.formData.rentOwn,
			getDate: (state: IGlobalState) => state.selfGen.form.formData.date,
			getTime: (state: IGlobalState) => state.selfGen.form.formData.time,
			getProducts: (state: IGlobalState) => state.selfGen.form.formData.products,
			getAreas: (state: IGlobalState) => state.selfGen.form.formData.areas,
			getBusinessName: (state: IGlobalState) => state.selfGen.form.formData.businessName,
			step1: {
				getError: (state: IGlobalState) => state.selfGen.form.formData.step1.error
			},
			step2: {
				getError: (state: IGlobalState) => state.selfGen.form.formData.step2.error
			},
			step3: {
				getError: (state: IGlobalState) => state.selfGen.form.formData.step3.error
			}
		}
	}
};
