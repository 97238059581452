import { combineReducers } from 'redux';
import * as Application from './application';
import * as AppointmentSearch from './appointments/search';
import * as AppointmentDetails from './appointments/details';
import * as ArrivalNotice from './arrivalnotice';
import * as Auth from './auth';
import * as CustomerSearch from './customers/search';
import * as Home from './home';
import * as Messaging from './messaging';
import * as Notifications from './notifications';
import * as ReferralProspect from './referralprospect';
import * as Resolution from './resolution';
import * as SelfGen from './selfgen';

export interface IGlobalState {
	application: Application.IState;
	appointments: {
		search: AppointmentSearch.IState;
		details: AppointmentDetails.IState;
	};
	arrivalNotice: ArrivalNotice.IState;
	auth: Auth.IState;
	customers: { search: CustomerSearch.IState };
	home: Home.IState;
	messaging: Messaging.IState;
	notifications: Notifications.IState;
	referralProspect: ReferralProspect.IState;
	resolution: Resolution.IState;
	selfGen: SelfGen.IState;
}

export const InitialState: IGlobalState = {
	application: Application.initialState,
	appointments: {
		search: AppointmentSearch.initialState,
		details: AppointmentDetails.initialState
	},
	arrivalNotice: ArrivalNotice.initialState,
	auth: Auth.initialState,
	customers: { search: CustomerSearch.initialState },
	home: Home.initialState,
	messaging: Messaging.initialState,
	notifications: Notifications.initialState,
	referralProspect: ReferralProspect.initialState,
	resolution: Resolution.initialState,
	selfGen: SelfGen.initialState
};

export const reducers = {
	application: Application.reducer,
	appointments: combineReducers({
		search: AppointmentSearch.reducer,
		details: AppointmentDetails.reducer
	}),
	arrivalNotice: ArrivalNotice.reducer,
	auth: Auth.reducer,
	customers: combineReducers({ search: CustomerSearch.reducer }),
	home: Home.reducer,
	messaging: Messaging.reducer,
	notifications: Notifications.reducer,
	referralProspect: ReferralProspect.reducer,
	resolution: Resolution.reducer,
	selfGen: SelfGen.reducer
};

export const sagas = {
	application: Application.saga,
	appointmentSearch: AppointmentSearch.saga,
	appointmentDetails: AppointmentDetails.saga,
	arrivalNotice: ArrivalNotice.saga,
	auth: Auth.saga,
	customerSearch: CustomerSearch.saga,
	home: Home.saga,
	messaging: Messaging.saga,
	notifications: Notifications.saga,
	referralProspect: ReferralProspect.saga,
	resolution: Resolution.saga,
	selfGen: SelfGen.saga
};

export const actionCreators = {
	application: Application.actionCreator,
	appointments: {
		search: AppointmentSearch.actionCreator,
		details: AppointmentDetails.actionCreator
	},
	arrivalNotice: ArrivalNotice.actionCreator,
	auth: Auth.actionCreator,
	customers: { search: CustomerSearch.actionCreator },
	home: Home.actionCreator,
	messaging: Messaging.actionCreator,
	notifications: Notifications.actionCreator,
	referralProspect: ReferralProspect.actionCreator,
	resolution: Resolution.actionCreator,
	selfGen: SelfGen.actionCreator
};
