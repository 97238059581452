export enum EArrivalNoticeMethod {
	QUANTITY = 'QUANTITY',
	TIME = 'TIME'
}

export interface IArrivalNoticeQuantity {
	minutes: number;
	display: string;
}

export interface IArrivalNoticeTime {
	value: string;
	display: string;
}
