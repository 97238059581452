import { appointmentsController } from './appointments/controller';
import { customersController } from './customers/controller';
import { lovController } from './lov/controller';
import { notificationsController } from './notifications/controller';
import { pacsController } from './pacs/controller';
import { profileController } from './profile/controller';
import { selfGenController } from './selfgen/controller';
import { startupController } from './startup/controller';
import { notesController } from './notes/controller';
import { contractsController } from './contracts/controller';
import { quotesController } from './quotes/controller';
import { referralProspectController } from './referralprospect/controller';

export const api = {
	appointments: appointmentsController,
	customers: customersController,
	lov: lovController,
	notifications: notificationsController,
	pacs: pacsController,
	profile: profileController,
	selfGen: selfGenController,
	startup: startupController,
	notes: notesController,
	contracts: contractsController,
	quotes: quotesController,
	referralProspect: referralProspectController
};
