import { LoadingButton } from '@mui/lab';
import { EActionState, IAppointmentCombinedState } from 'models';
import { useDispatch } from 'react-redux';
import { actionCreator } from 'store/home';

export const AcceptButton = (props: { appointment: IAppointmentCombinedState }) => {
	const { appointment } = props;
	const dispatch = useDispatch();
	const handleClick = () => {
		dispatch(
			actionCreator.ACCEPT_START({
				rowId: appointment.rowId
			})
		);
	};
	return (
		<LoadingButton
			variant='text'
			disabled={!appointment.acceptEnabled}
			loading={appointment.accept === EActionState.RUNNING}
			onClick={handleClick}
			sx={{
				flex: '1 1 0px',
				borderRadius: '0px 0px 0px 0px',
				minHeight: 50
			}}>
			Accept
		</LoadingButton>
	);
};
