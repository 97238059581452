import { EActionState, IAppointmentCombinedState, IHeader, IListValue, IOpportunityNote, IQuoteOption } from 'models';

export interface IState {
	init: EActionState;
	load: EActionState;
	refresh: EActionState;
	rowId: string;
	appointment: {
		load: EActionState;
		refresh: EActionState;
		data: IAppointmentCombinedState | null;
	};
	quotes: {
		load: EActionState;
		refresh: EActionState;
		data: IQuoteOption[];
	};
	contract: {
		load: EActionState;
		refresh: EActionState;
		data: IHeader;
	};
	notes: {
		load: EActionState;
		refresh: EActionState;
		data: IOpportunityNote[];
		dialog: {
			open: boolean;
			save: EActionState;
			saveEnabled: boolean;
			note: string;
			message: string;
		};
	};
	changeOrders: {
		load: EActionState;
		refresh: EActionState;
		data: any;
	};
	states: IListValue[];
}

export const initialState: IState = {
	init: EActionState.PENDING,
	load: EActionState.PENDING,
	refresh: EActionState.PENDING,
	rowId: '',
	appointment: {
		load: EActionState.PENDING,
		refresh: EActionState.PENDING,
		data: null
	},
	quotes: {
		load: EActionState.PENDING,
		refresh: EActionState.PENDING,
		data: []
	},
	contract: {
		load: EActionState.PENDING,
		refresh: EActionState.PENDING,
		data: null
	},
	notes: {
		load: EActionState.PENDING,
		refresh: EActionState.PENDING,
		data: [],
		dialog: {
			open: false,
			save: EActionState.PENDING,
			saveEnabled: false,
			note: '',
			message: ''
		}
	},
	changeOrders: {
		load: EActionState.PENDING,
		refresh: EActionState.PENDING,
		data: null
	},
	states: []
};
